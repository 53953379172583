import { FC } from 'react';
import { ExportIcon } from 'assets/img/icons';
import { IFilters } from './types';

export const Filter: FC<IFilters> = ({ exportTitle, onExport }) => {
	return (
		<button type="button" className="transaction-export-btn" onClick={onExport}>
			{exportTitle}
			<span className="transaction-export-btn__icon">
				<ExportIcon />
			</span>
		</button>
	);
};
