const TableHeader = () => {
	return (
		<div className="tr">
			<div className="td">
				<div className="td-name">
					<p>ID</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Account</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Operator ID</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Operation type</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>User ID</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Date</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Actual Rate</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Requested Rate</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>From</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>To</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Amount From</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Amount To</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Transfered</p>
				</div>
			</div>
			<div className="td td--right">
				<div className="td-name">
					<p>Action</p>
				</div>
			</div>
		</div>
	);
};

export default TableHeader;
