/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import Loader from 'ui/Loader';
import { getHistory, getHistoryLoader } from 'redux/reducers/cashdesk/selectors';
import { IHistoryManagementItem } from 'redux/reducers/cashdesk/types';
import { HistoryTableHeader } from './HistoryTableHeader';
import HistoryTableItem from './HistoryTableItem';

export const History: FC = () => {
	const history = useSelector(getHistory);
	const historyIsLoad = useSelector(getHistoryLoader);

	const [openWhitelisting, setOpenWhitelisting] = useState(false);

	return (
		<>
			{historyIsLoad ? (
				<>
					{' '}
					<div className="list-loader">
						<Loader />
					</div>
				</>
			) : (
				<div className="table-wrapper">
					<div className="table table--cashdesk-wallet-history">
						<HistoryTableHeader />
						<div className="table-body">
							{history?.data.length &&
								history?.data.map((item: IHistoryManagementItem) => (
									<HistoryTableItem item={item} key={item.id} />
								))}
							{history?.data.length === 0 ? (
								<p style={{ padding: '20px' }}>Wallets is empty</p>
							) : null}
						</div>
					</div>
				</div>
			)}
			{openWhitelisting && (
				<div className="popup-window" onClick={() => setOpenWhitelisting(false)} />
			)}
		</>
	);
};
