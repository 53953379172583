import { FC, useEffect } from 'react';
import Dashboard from 'layouts/Dashboard';
import { useDispatch } from 'react-redux';
import { getConvertSettingsRequest } from 'redux/reducers/settings/reducer';
import ConvertSettings from 'components/ConvertSettings';

// ================================================:
const ConvertSettingsPage: FC = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getConvertSettingsRequest());
	}, [dispatch]);
	return (
		<Dashboard title="Convert Settings">
			<ConvertSettings />
		</Dashboard>
	);
};

export default ConvertSettingsPage;
