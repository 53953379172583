import { isAuth, isGuest } from 'routes/authMiddlewares';
import Home from 'pages/Dashboard/Home';
import AdminManagementPage from 'pages/Dashboard/AdminManagementPage';
import DisputePage from 'pages/Dashboard/Dispute';
import UserManagementPage from 'pages/Dashboard/UserManagementPage';
import Settings from 'pages/Dashboard/Settings';
import Login from 'pages/Auth/Login';
import SelectUserPage from 'pages/Dashboard/SelectUserPage';
import ConvetSettings from 'pages/Dashboard/ConvertSettings';
import CreateAdminPage from 'pages/Dashboard/CreateAdminPage';
import EditAdminPage from 'pages/Dashboard/EditAdminPage';
import TwoFAPage from 'pages/Dashboard/TwoFAPage';
import NotificationPage from 'pages/Dashboard/NotificationPage';
import FeeManagementPage from 'pages/Dashboard/FeeManagementPage';
import FeeManagementFiatPage from 'pages/Dashboard/FeeManagementFiatPage';
import WithdrawalsPage from 'pages/Dashboard/Withdrawals';
import UnidentifiedDeposits from 'pages/Dashboard/UnidentifiedDeposits';
import OrdersPage from 'pages/Dashboard/Orders';
import TradesPage from 'pages/Dashboard/Trades';
import TradingPairsPage from 'pages/Dashboard/TradingPairsPage';
import { WalletManagementPage } from 'pages/Dashboard/WalletManagementPage';
import DashboardPage from 'pages/Dashboard/DashboardPage';
import MarketMakerRobotPage from 'pages/Dashboard/MarketMakerRobotPage';
import LimitationsPage from 'pages/Dashboard/Limitations';
import TransactionsPage from 'pages/Dashboard/Transactions';
import CurrenciesPage from 'pages/Dashboard/Currencies';
import DisputeTubPage from 'pages/Dashboard/DisputeTubPage';
import TokenManagementPage from 'pages/Dashboard/TokenManagementPage';
import BankAccountPage from 'pages/Dashboard/BankAccountPage';
import ExternalMakerRobotPage from 'pages/Dashboard/ExternalMakerRobotPage';
import Translations from 'pages/Dashboard/Translations';
import ReferralPage from 'pages/Dashboard/Referral';
import SelectReferralPage from 'pages/Dashboard/SelectReferralPage';
import StakingPage from 'pages/Dashboard/Staking';
import MarketBotPage from 'pages/Dashboard/MarketBot';
import Email2FAPage from 'pages/Dashboard/Email2FA';
import Sms2FAPage from 'pages/Dashboard/Sms2FA';
import PendingDeposits from 'pages/Dashboard/PendingDepositsPage';
import CashdeskPermissions from 'pages/Dashboard/CashdeskOperatorsPage';
import CashdeskPermissionsEdit from 'pages/Dashboard/CashdeskOperatorEditPage';
import CashdeskPermissionsNew from 'pages/Dashboard/CashdeskOperatorCreatePage';
import CashdeskWalletManagementPage from 'pages/Dashboard/CashdeskWalletManagementPage';
import CashdeskConfigurations from 'pages/Dashboard/CashDeskConfigurationsPage';
import CashdeskOperationPage from 'pages/Dashboard/CashDescOperationPage';
import CashDeskRequestedRates from 'pages/Dashboard/CashDescRequestedRatesPage';
import PermissionGate from 'ui/PermissionGate';
import { permissions } from 'services/constants/permissions';
import { IRouteItem } from './types';

export type RouteComponentProps = {
	path: string;
	permissions?: string[];
	permissionsEdit?: string[];
	isAuth?: boolean;
	render: () => JSX.Element;
};

// Типизация маршрутов
type RoutesPermissions = {
	[key: string]: RouteComponentProps;
};

export const routesPermissions: RoutesPermissions = {
	home: {
		path: '/',
		permissions: [permissions.VIEW_ADMIN_SYSTEM],
		isAuth: true,
		render: () => <Home />,
	},
	adminManagementPage: {
		path: '/admin-management',
		permissions: [permissions.MANAGE_ADMINS],
		isAuth: true,
		render: () => <AdminManagementPage />,
	},
	disputePage: {
		path: '/dispute',
		permissions: [permissions.MANAGE_DISPUTES],
		isAuth: true,
		render: () => <DisputePage />,
	},
	createAdmin: {
		path: '/admin-management/new-admin',
		permissions: [permissions.MANAGE_ADMINS],
		permissionsEdit: [permissions.MANAGE_ADMINS],
		isAuth: true,
		render: () => <CreateAdminPage />,
	},
	editAdmin: {
		path: '/admin-management/edit/:id',
		permissions: [permissions.MANAGE_ADMINS],
		permissionsEdit: [permissions.MANAGE_ADMINS],
		isAuth: true,
		render: () => <EditAdminPage />,
	},
	userManagementPage: {
		path: '/user-management',
		permissions: [permissions.MANAGE_USERS],
		isAuth: true,
		render: () => <UserManagementPage />,
	},
	selectUser: {
		path: '/user-management/:id',
		permissions: [permissions.MANAGE_USERS],
		isAuth: true,
		render: () => <SelectUserPage />,
	},
	withdrawals: {
		path: '/pending-withdrawals',
		permissions: [permissions.MANAGE_WITHDRAWALS],
		isAuth: true,
		render: () => <WithdrawalsPage />,
	},
	pendingDeposits: {
		path: '/pending-deposits',
		permissions: [permissions.MANAGE_WITHDRAWALS],
		isAuth: true,
		render: () => <PendingDeposits />,
	},
	unidentifiedDeposits: {
		path: '/unidentified-deposits',
		permissions: [permissions.MANAGE_UNIDENTIFIED_DEPOSITS],
		isAuth: true,
		render: () => <UnidentifiedDeposits />,
	},
	orders: {
		path: '/orders',
		permissions: [permissions.VIEW_ORDERS],
		isAuth: true,
		render: () => <OrdersPage />,
	},
	trades: {
		path: '/trades',
		permissions: [permissions.VIEW_TRADES],
		isAuth: true,
		render: () => <TradesPage />,
	},
	twoFAPage: {
		path: '/2FA',
		permissions: [permissions.MANAGE_2FA_RESETS],
		isAuth: true,
		render: () => <TwoFAPage />,
	},
	email2FAPage: {
		path: '/email-2fa',
		permissions: [permissions.MANAGE_2FA_RESETS],
		isAuth: true,
		render: () => <Email2FAPage />,
	},
	sms2FAPage: {
		path: '/sms-2fa',
		permissions: [permissions.MANAGE_2FA_RESETS],
		isAuth: true,
		render: () => <Sms2FAPage />,
	},
	tradingPairsPage: {
		path: '/trading-pairs',
		permissions: [permissions.MANAGE_ASSET_PAIRS],
		isAuth: true,
		render: () => <TradingPairsPage />,
	},
	notificationPage: {
		path: '/notification',
		permissions: [permissions.MANAGE_NOTIFICATIONS],
		isAuth: true,
		render: () => <NotificationPage />,
	},
	feeManagmentPage: {
		path: '/fee-management',
		permissions: [permissions.SET_FEES],
		isAuth: true,
		render: () => <FeeManagementPage />,
	},
	feeManagmentFiatPage: {
		path: '/fee-management-fiat',
		permissions: [permissions.SET_FEES],
		isAuth: true,
		render: () => <FeeManagementFiatPage />,
	},
	walletManagementPage: {
		path: '/wallet-management',
		permissions: [permissions.MANAGE_WALLETS],
		isAuth: true,
		render: () => <WalletManagementPage />,
	},
	cashDeskDashboard: {
		path: '/cash-desk/dashboard',
		isAuth: true,
		permissions: [permissions.CASHDESK_DASHBOARD],
		render: () => <DashboardPage />,
	},
	cashDeskPermissions: {
		path: '/cash-desk/operators',
		isAuth: true,
		permissions: [permissions.CASHDESK_PERMISSION_MANAGEMENT],
		render: () => <CashdeskPermissions />,
	},
	cashDeskPermissionsEdit: {
		path: '/cash-desk/operators/edit/:id',
		isAuth: true,
		permissions: [permissions.CASHDESK_PERMISSION_MANAGEMENT],
		permissionsEdit: [permissions.CASHDESK_PERMISSION_MANAGEMENT],
		render: () => <CashdeskPermissionsEdit />,
	},
	cashDeskPermissionsNew: {
		path: '/cash-desk/operators/new',
		isAuth: true,
		permissions: [permissions.CASHDESK_PERMISSION_MANAGEMENT],
		permissionsEdit: [permissions.CASHDESK_PERMISSION_MANAGEMENT],
		render: () => <CashdeskPermissionsNew />,
	},
	cashDeskWalletManagement: {
		path: '/cash-desk/wallet-management',
		isAuth: true,
		permissions: [permissions.CASHDESK_WALLET_MANAGEMENT],
		render: () => <CashdeskWalletManagementPage />,
	},
	cashConfigurations: {
		path: '/cash-desk/configurations',
		isAuth: true,
		permissions: [permissions.CASHDESK_CONFIGURATIONS],
		render: () => <CashdeskConfigurations />,
	},
	cashDeskOperation: {
		path: '/cash-desk/operation',
		isAuth: true,
		permissions: [permissions.CASHDESK_OPERATION],
		render: () => <CashdeskOperationPage />,
	},
	cashDeskRequestedRates: {
		path: '/cash-desk/requested-rates',
		isAuth: true,
		permissions: [permissions.CASHDESK_REQUESTED_RATES],
		render: () => <CashDeskRequestedRates />,
	},
	settings: {
		path: '/settings',
		isAuth: true,
		render: () => <Settings />,
	},
	convertSettings: {
		path: '/convert-settings',
		permissions: [permissions.MANAGE_CONVERT_SETTINGS],
		isAuth: true,
		render: () => <ConvetSettings />,
	},
	login: {
		path: '/login',
		render: () => <Login />,
	},
	internal: {
		path: '/internal',
		permissions: [permissions.MANAGE_MARKET_MAKER, permissions.MANAGE_USERS],
		isAuth: true,
		render: () => <MarketMakerRobotPage />,
	},
	external: {
		path: '/external',
		permissions: [permissions.MANAGE_EXTERNAL_SERVICES],
		isAuth: true,
		render: () => <ExternalMakerRobotPage />,
	},
	limitations: {
		path: '/limitations',
		permissions: [permissions.SET_LIMITS],
		isAuth: true,
		render: () => <LimitationsPage />,
	},
	transactions: {
		path: '/transactions',
		permissions: [permissions.MANAGE_CRYPTO_TRANSFERS],
		isAuth: true,
		render: () => <TransactionsPage />,
	},
	currencies: {
		path: '/currencies',
		permissions: [permissions.MANAGE_CURRENCIES],
		isAuth: true,
		render: () => <CurrenciesPage />,
	},
	disputeTub: {
		path: '/dispute-info/:disputeId',
		permissions: [permissions.MANAGE_DISPUTES],
		isAuth: true,
		render: () => <DisputeTubPage />,
	},
	tokenManagement: {
		path: '/token-management',
		permissions: [permissions.MANAGE_DISPUTES],
		isAuth: true,
		render: () => <TokenManagementPage />,
	},
	bankAccount: {
		path: '/bank-account',
		permissions: [permissions.MANAGE_BANK_ACCOUNTS],
		isAuth: true,
		render: () => <BankAccountPage />,
	},
	translations: {
		path: '/translations',
		permissions: [permissions.MANAGE_TRANSLATIONS],
		isAuth: true,
		render: () => <Translations />,
	},
	referral: {
		path: '/referral',
		permissions: [permissions.MANAGE_REFERRALS],
		isAuth: true,
		render: () => <ReferralPage />,
	},
	referralSelect: {
		path: '/referral/:id',
		isAuth: true,
		render: () => <SelectReferralPage />,
	},
	staking: {
		path: '/staking',
		permissions: [permissions.MANAGE_STAKING],
		isAuth: true,
		render: () => <StakingPage />,
	},
	marketBot: {
		path: '/market-bot',
		permissions: [permissions.MANAGE_MARKET_MAKER],
		isAuth: true,
		render: () => <MarketBotPage />,
	},
};

const navList: any = Object.keys(routesPermissions).reduce((acc, routeName: string) => {
	const {
		path,
		permissions: p,
		permissionsEdit,
		render,
		isAuth: needsAuth,
	} = routesPermissions[routeName];
	const component = () =>
		routeName === 'home' ? (
			render()
		) : (
			<PermissionGate permissions={p} permissionsEdit={permissionsEdit}>
				{render()}
			</PermissionGate>
		);
	return {
		...acc,
		[routeName]: {
			path,
			component: needsAuth ? isAuth(component) : isGuest(component),
		},
	};
}, {});

// ==========================================:
const routesList: Array<IRouteItem> = [];

Object.keys(navList).forEach((item) => routesList.push(navList[item as keyof typeof navList]));

export { navList, routesList };
