/* eslint-disable no-nested-ternary */
import { FC } from 'react';
import { TRANSACTION_TYPES } from 'redux/reducers/walletManagement/constants';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

import { ITransactionsItemParams } from './types';

export const TransactionsItem: FC<ITransactionsItemParams> = ({ data }) => {
	const date = new Date(data.created_at);
	const dateCreated = new Date(data.created_at).toLocaleString('en-GB', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: 'numeric',
		minute: '2-digit',
	});

	const type =
		!!data.type &&
		(TRANSACTION_TYPES[data.type] || capitalizeFirstLetter(String(data.type.replaceAll('_', ' '))));

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">ID</p>
				<p>{data.id}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Date</p>
				<p style={{ whiteSpace: 'nowrap' }}>{dateCreated}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Coin</p>
				<p>
					{`${data.code.toUpperCase()}
					${data?.network ? ` (${data?.network.toUpperCase()})` : ''}`}
				</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Amount</p>
				<p>{data.amount}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Type</p>
				<p>{type}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Comment</p>
				<div className="hint-block">
					<div className="hint-block__text">
						<p>{data.comment}</p>
					</div>
					<div className="hint-item">
						<p>{data.comment}</p>
					</div>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Status</p>
				<p>{data?.status}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">MEMO code</p>
				<p>{data?.memo ? data?.memo : '-'}</p>
			</div>
			<div className="td flex--start">
				<p className="td-hidden-name">Address to</p>
				<p className="transaction-address">{data.address_to}</p>
			</div>
			<div className="td flex--start">
				<p className="td-hidden-name">Hash</p>
				<p className="transaction-hash">{data.tx_hash}</p>
			</div>
			<div className="td flex--start">
				<p className="td-hidden-name">Address from</p>
				<p className="transaction-address">{data.address_from}</p>
			</div>
		</div>
	);
};
