import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import {
	IGenerate2faKeyResponse,
	ISettingsStore,
	IConvertSettingsData,
	IMaintenancData,
	IMaintenanceSettings,
} from './types';

// ==========================================:
const getSettingsState = (state: IStoreState): ISettingsStore => state.settings;
export const getSettings = createSelector(
	[getSettingsState],
	(settings: ISettingsStore) => settings,
);

// ====================================================:
export const getDisplayDisable2faForm = createSelector(
	[getSettings],
	(settings: ISettingsStore): boolean => settings.displayDisable2faForm,
);

// ====================================================:
export const getChangePassLoader = createSelector(
	[getSettings],
	(settings: ISettingsStore): boolean => settings.changePassLoader,
);

// ====================================================:
export const get2faIsLoad = createSelector(
	[getSettings],
	(settings: ISettingsStore): boolean => settings.account2faLoader,
);

// ====================================================:
export const getCheck2faEnablesLoader = createSelector(
	[getSettings],
	(settings: ISettingsStore): boolean => settings.check2faEnablesLoader,
);

// ====================================================:
export const getDisplayDisableBlock = createSelector(
	[getSettings],
	(settings: ISettingsStore): null | string => settings.check2faEnables,
);

// ====================================================:
export const get2faData = createSelector(
	[getSettings],
	(settings: ISettingsStore): null | IGenerate2faKeyResponse => settings.data2fa,
);

// ====================================================:
export const getConvertSettings = createSelector(
	[getSettings],
	(settings: ISettingsStore): null | Array<IConvertSettingsData> => settings.convertSettingList,
);

export const getConvertSettingsloader = createSelector(
	[getSettings],
	(settings: ISettingsStore): boolean => settings.convertSettingLoader,
);

export const getMaintenance = createSelector(
	[getSettings],
	(settings: ISettingsStore): IMaintenancData | null => settings.maintenanceList,
);

export const getMaintenanceloader = createSelector(
	[getSettings],
	(settings: ISettingsStore): boolean => settings.maintenanceLoader,
);

export const getMaintenanceSettings = createSelector(
	[getSettings],
	(settings: ISettingsStore): IMaintenanceSettings | null => settings.maintenanceSettings,
);
