import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import MarketMakerRobot from 'components/MarketMakerRobot';

// ================================================:
const MarketMakerRobotPage: FC = () => {
	return (
		<Dashboard title="Internal">
			<MarketMakerRobot />
		</Dashboard>
	);
};

export default MarketMakerRobotPage;
