import {
	IChangePassPayload,
	ICheck2faEnables,
	IDisable2faPayload,
	IEnable2faPayload,
	IGenerate2faKeyResponse,
	IConvertSettingsItem,
} from 'redux/reducers/settings/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { ISettingsApi } from './types';

// ==========================================:
export const settings: ISettingsApi = {
	changePassword: (payload) =>
		http.put<IChangePassPayload>(endpoint.settings.CHANGE_PASSWORD, payload),

	generate2faKey: () =>
		http
			.get<IGenerate2faKeyResponse>(endpoint.settings.GENERATE_2FA_KEY)
			.then((response) => response.data),

	check2faEnables: () =>
		http
			.get<ICheck2faEnables>(endpoint.settings.CHECK_ENABLED_2FA)
			.then((response) => response.data),

	enable2fa: (payload) => http.post<IEnable2faPayload>(endpoint.settings.ENABLE_2FA, payload),

	disable2fa: (payload) => http.post<IDisable2faPayload>(endpoint.settings.DISABLE_2FA, payload),
	getConvertSettings: () =>
		http
			.get<IConvertSettingsItem[]>(endpoint.settings.GET_CONVERT_SETTINGS)
			.then((response) => response.data),
	putConvertSettings: (payload) =>
		http
			.put<IConvertSettingsItem>(endpoint.settings.PUT_CONVERT_SETTINGS, payload)
			.then((response) => response.data),
	getMaintenance: (params) =>
		http.get(endpoint.settings.GET_MAINTENANCE, { params }).then((response) => response.data),
	getMaintenanceSettings: () =>
		http.get(endpoint.settings.GET_SETTINGS_MAINTENANCE).then((response) => response.data),
	updateMaintenance: (params) =>
		http
			.put(endpoint.settings.UPDATE_SETTINGS_MAINTENANCE, params)
			.then((response) => response.data),
};
