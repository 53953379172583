import { FC } from 'react';

export const HistoryTableHeader: FC = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>Admin ID</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Type</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Cash Desk#</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Operator ID</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Currency</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Initial balance</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Previous Balance</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Balance</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Min alert</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Max alert</p>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Date</p>
					</div>
				</div>
			</div>
		</div>
	);
};
