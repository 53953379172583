/* eslint-disable */
import {
	I2FAResetPayload,
	I2FARejectPayload,
	I2FAResponsePayload,
	ISelectUserResponsePayload,
	IUserBalancesResponsePayload,
	IUsersResponsePayload,
	IWhitelistIpListResponsePayload,
	IWhitelistIpRemoveResponsePayload,
	IWhitelistIpVerifyResponsePayload,
	ILoginHistoryResponsePayload,
	IDepositAndWithdrawHistoryResponsePayload,
	ICryptoDepositRequestData,
	IUserBankAccountResponse,
	IUserCardsResponse,
	TBalancesP2PResponse,
	IBankAccountId,
} from 'redux/reducers/userManagement/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IUsersApi } from './types';

// ==========================================:
export const users: IUsersApi = {
	getUsers: (params) =>
		http
			.get<IUsersResponsePayload>(endpoint.users.USERS, { params })
			.then((response) => response.data),
	getUser: (params) =>
		http
			.get<ISelectUserResponsePayload>(endpoint.users.USER, { params })
			.then((response) => response.data),
	getBalances: ({ id }) =>
		http
			.get<IUserBalancesResponsePayload>(endpoint.users.BALANCES(id))
			.then((response) => response.data),
	getOrdersHistory: (params) =>
		http
			.get<IWhitelistIpListResponsePayload>(endpoint.users.ORDERS_HISTORY(params.id), { params })
			.then((response) => response.data),
	getOpenOrders: (params) => http.get(endpoint.users.OPEN_ORDERS(params.id)).then((response) => response.data),
	getOrdersHistoryMargin: (params) =>
		http
			.get<IWhitelistIpListResponsePayload>(endpoint.users.ORDERS_HISTORY(params.id), { params })
			.then((response) => response.data),
	setUserBlockUnblock: (payload) =>
		http.put(endpoint.users.USER_BLOCK_UNBLOCK(payload.id), payload.blocked),
	setUserUpdateVipStatus: (payload) =>
		http
			.put(endpoint.users.USER_UPDATE_VIP_STATUS(payload.id), payload.is_vip)
			.then((response) => response.data),
	get2FA: (params) =>
		http
			.get<I2FAResponsePayload>(endpoint.users.TWO_FA, { params })
			.then((response) => response.data),
	reset2FA: (payload) => http.put<I2FAResetPayload>(endpoint.users.TWO_FA_RESET, payload),
	reject2FA: (payload) => http.post<I2FARejectPayload>(endpoint.users.TWO_FA_REJECT, payload),
	getWhitelistIp: (params) =>
		http
			.get<IWhitelistIpListResponsePayload>(endpoint.users.WHITELIST_IP(params.id), { params })
			.then((response) => response.data),
	removeWhitelistIp: (payload) =>
		http
			.put<IWhitelistIpRemoveResponsePayload>(endpoint.users.WHITELIST_IP_REMOVE, payload)
			.then((response) => response.data),
	verifyWhitelistIp: (payload) =>
		http
			.put<IWhitelistIpVerifyResponsePayload>(endpoint.users.WHITELIST_IP_VERIFY, {
				verification_id: payload.id,
			})
			.then((response) => response.data),
	getLoginHistory: (allParams) => {
		const { id, ...params } = allParams;
		return http
			.get<ILoginHistoryResponsePayload>(endpoint.users.LOGIN_HISTORY(id), { params })
			.then(({ data }) => data);
	},
	getDepositAndWithdrawHistory: (params) =>
		http
			.get<IDepositAndWithdrawHistoryResponsePayload>(endpoint.users.TRANSACTIONS(params.id), {
				params,
			})
			.then((response) => response.data),
	getBorrowingMarginCross: (params) =>
		http
			.get<IWhitelistIpListResponsePayload>(endpoint.users.BORROW_CROSS(params.id), { params })
			.then((response) => response.data),
	getBorrowingMarginIsolated: (params) =>
		http
			.get<IWhitelistIpListResponsePayload>(endpoint.users.BORROW_ISOLATED(params.id), { params })
			.then((response) => response.data),
	getRepaymentMarginCross: (params) =>
		http
			.get<IWhitelistIpListResponsePayload>(endpoint.users.REPAYMENT_CROSS(params.id), { params })
			.then((response) => response.data),
	getRepaymentMarginIsolated: (params) =>
		http
			.get<IWhitelistIpListResponsePayload>(endpoint.users.REPAYMENT_ISOLATED(params.id), {
				params,
			})
			.then((response) => response.data),
	getInterestMarginCross: (params) =>
		http
			.get<IWhitelistIpListResponsePayload>(endpoint.users.INTEREST_CROSS(params.id), { params })
			.then((response) => response.data),
	getInterestMarginIsolated: (params) =>
		http
			.get<IWhitelistIpListResponsePayload>(endpoint.users.INTEREST_ISOLATED(params.id), {
				params,
			})
			.then((response) => response.data),
	getLiquidationMarginCross: (params) =>
		http
			.get<IWhitelistIpListResponsePayload>(endpoint.users.LIQUIDATION_CROSS(params.id), { params })
			.then((response) => response.data),
	getLiquidationMarginIsolated: (params) =>
		http
			.get<IWhitelistIpListResponsePayload>(endpoint.users.LIQUIDATION_ISOLATED(params.id), {
				params,
			})
			.then((response) => response.data),
	getBalancesMarginCross: (params) =>
		http
			.get<IWhitelistIpListResponsePayload>(endpoint.users.BALANCES_CROSS(params.id), { params })
			.then((response) => response.data),
	getBalancesMarginIsolated: (params) =>
		http
			.get<IWhitelistIpListResponsePayload>(endpoint.users.BALANCES_ISOLATED(params.id), {
				params,
			})
			.then((response) => response.data),
	getMarginCallMarginCross: (params) =>
		http
			.get<IWhitelistIpListResponsePayload>(endpoint.users.MARGIN_CALL_CROSS(params.id), { params })
			.then((response) => response.data),
	getMarginCallMarginIsolated: (params) =>
		http
			.get<IWhitelistIpListResponsePayload>(endpoint.users.MARGIN_CALL_ISOLATED(params.id), {
				params,
			})
			.then((response) => response.data),
	getP2PAdvertisements: (params) =>
		http
			.get<IWhitelistIpListResponsePayload>(endpoint.users.P2P_ADVERTISEMENTS(params.id), {
				params,
			})
			.then((response) => response.data),
	getP2PDispute: (params) =>
		http
			.get<IWhitelistIpListResponsePayload>(endpoint.users.P2P_DISPUTE(params.id), {
				params,
			})
			.then((response) => response.data),
	getP2PTrades: (params) =>
		http
			.get<IWhitelistIpListResponsePayload>(endpoint.users.P2P_TRADES(params.id), {
				params,
			})
			.then((response) => response.data),
	getP2PBalances: (params) =>
		http
			.get<TBalancesP2PResponse>(endpoint.users.P2P_BALANCES(params.id))
			.then((response) => response.data),
	getCryptoDeposit: (params: ICryptoDepositRequestData) =>
		http
			.get<IWhitelistIpListResponsePayload>(
				endpoint.users.P2P_TRANSACTIONS_WITHDRAWS(params.id, params.type),
				{
					params,
				},
			)
			.then((response) => response.data),
	getFiatDeposit: (params: ICryptoDepositRequestData) =>
		http
			.get<IWhitelistIpListResponsePayload>(endpoint.users.FIAT_DEPOSIT(params.id), {
				params,
			})
			.then((response) => response.data),
	getConverts: (params: ICryptoDepositRequestData) =>
		http
			.get<IWhitelistIpListResponsePayload>(endpoint.users.CONVERTS(params.id), {
				params,
			})
			.then((response) => response.data),
	getTransfers: (params) =>
		http
			.get<IWhitelistIpListResponsePayload>(endpoint.users.TRANSFERS(params.id), {
				params,
			})
			.then((response) => response.data),
	getRedemptions: (params) =>
		http
			.get<IWhitelistIpListResponsePayload>(endpoint.users.REDEMPTIONS(params.id), {
				params,
			})
			.then((response) => response.data),
	getUserCards: (params) =>
		http
			.get<IUserCardsResponse>(endpoint.users.GET_CARDS_PAYMENT(params.id))
			.then((response) => response.data),
	getBankAccount: (params) =>
		http
			.get<IUserBankAccountResponse>(endpoint.users.GET_BANK_ACCOUNT_PAYMENT(params.id))
			.then((response) => response.data),
	updateReferral: (params) =>
		http
			.put<any>(endpoint.users.UPDATE_REFERRAL(params.id), { fee: params.fee })
			.then((response) => response.data),
	updateBankAccountStatus: ({ id, ...params }) =>
		http
			.put<any>(endpoint.users.UPDATE_BANK_ACCOUNT_STATUS(id), params)
			.then((response) => response.data),
	getEmail2FA: (params) =>
		http.get(endpoint.users.EMAIL_2FA, { params }).then((response) => response.data),
	getSms2FA: (params) =>
		http.get(endpoint.users.SMS_2FA, { params }).then((response) => response.data),
	changeStatus: async (params) => {
		const aprroveReject = params?.statusName === 'verified'
			? endpoint.users.APPROVE_STATUS(params.id)
			: endpoint.users.REJECT_STATUS(params.id);
		return http
			.post<any>(aprroveReject, { account_id: params.account_id }).then((response) => response.data)
	},
};
