import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import queryString from 'query-string';
import {
	getOperationsRequest,
	createOperationContinueRequest,
} from 'redux/reducers/cashdesk/reducer';
import {
	getOperartionsIsLoad,
	getOperartionsExportLoading,
	getOperations,
} from 'redux/reducers/cashdesk/selectors';
import { IOperationsRequest, IOperation } from 'redux/reducers/cashdesk/types';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { ExportIcon, ClockIcon } from 'assets/img/icons';
import SearchBar from 'components/SearchBar';
import { DateRangePicker } from 'rsuite';
import { useDateTimePicker } from 'services/hooks/useDateTimePicker';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import {
	OPERATIONS_SEARCH_ARRAY,
	OPERATIONS_SEARCH_TEXT_ARRAY,
	OPERATION_TYPE_ARRAY,
	OPERATION_TYPE_TEXT_ARRAY,
	STATUS_ARRAY,
	STATUS_TEXT_ARRAY,
} from './constants';

import { CreateOperation } from './Popups/CreateOperation';
import { ContinueOperation } from './Popups/ContinueOperation';

const CashDeskOperation = () => {
	const dispatch = useDispatch();
	const loading = useSelector(getOperartionsIsLoad);
	const exportLoading = useSelector(getOperartionsExportLoading);
	const operations = useSelector(getOperations);
	const history = useHistory();
	const { search } = useLocation();
	const location = useLocation();
	const { page, text, field } = queryString.parse(search);

	const [searchField, setSearchField] = useState(field ? String(field) : 'full_name');
	const [dropdownArray, setDropdownArray] = useState<{
		dropdownArray: string[];
		dropdownTextArray: string[];
	}>({ dropdownArray: ['all'], dropdownTextArray: ['all'] });
	const [typeSearchDropdown, setTypeSearchDropdown] = useState(false);

	const [term, setTerm] = useState('');
	const [debouncedTerm, setDebouncedTerm] = useState(term);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);
	const [openModal, setOpenModal] = useState(false);
	const [openContinueModal, setOpenContinueModal] = useState(false);
	const [currentOperation, setCurrentOperation] = useState<IOperation | null>(null);
	const [successRequest, setSuccessRequest] = useState(false);

	const { checkEditable } = usePermission();

	const [startDate, setStartDate] = useState<string | null>(null);
	const [endDate, setEndDate] = useState<string | null>(null);

	const { afterToday } = DateRangePicker;

	const onDateSelect = (_startDate: string | null, _endDate: string | null) => {
		setStartDate(_startDate);
		setEndDate(_endDate);
	};

	const { dateRangeValue, timeRangeValue, changeDateRangeHandler, changeTimeRangeHandler } =
		useDateTimePicker({ onDateSelect });

	const now = new Date();
	const prevMonth = new Date(now.setMonth(now.getMonth() - 1));

	useEffect(() => {
		const timer = setTimeout(() => setTerm(debouncedTerm), 800);
		return () => clearTimeout(timer);
	}, [debouncedTerm]);

	const handleClearSearch = () => {
		setDebouncedTerm('');
		setTerm('');
	};

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		const searchParams = text?.length
			? `?page=${String(pageNumber)}&text=${String(text)}&field=${String(field)}`
			: `?page=${String(pageNumber)}`;
		let searchValue = text?.length ? String(text) : undefined;
		if (field === 'pair') {
			searchValue = searchValue?.toLowerCase().replace('/', '_');
		}
		const params = {
			per_page: 15,
			current_page: pageNumber || 1,
			search_value: searchValue,
			search_field: text?.length ? String(field) : undefined,
			start_date: startDate || undefined,
			end_date: endDate || undefined,
		};

		history.push({
			pathname: location.pathname,
			search: searchParams,
		});

		dispatch(getOperationsRequest(params));
	};

	const handleSearch = (txt: string) => {
		let searchValue = txt.length >= 1 ? txt : undefined;
		if (searchField === 'pair') {
			searchValue = searchValue?.toLowerCase().replace('/', '_');
		}
		const params = {
			current_page: 1,
			search_value: searchValue,
			search_field: txt.length >= 1 ? searchField : undefined,
			start_date: startDate || undefined,
			end_date: endDate || undefined,
		};

		dispatch(getOperationsRequest(params));
		history.push({
			pathname: location.pathname,
			search:
				txt.length >= 1 ? `?page=${1}&text=${String(txt)}&field=${searchField}` : `?page=${1}`,
		});
		setCurrentPage(txt.length >= 1 ? Number(page || 1) : 1);
	};

	useEffect(() => {
		handleSearch(term);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [term, searchField, startDate, endDate]);
	useEffect(() => {
		if (openModal || openContinueModal) {
			document.body.classList.add('popup-open');
		} else {
			document.body.classList.remove('popup-open');
		}
	}, [openModal, openContinueModal]);

	const searchFieldHandler = (txt: string) => {
		if (txt === 'operation_type') {
			setDropdownArray({
				dropdownArray: OPERATION_TYPE_ARRAY,
				dropdownTextArray: OPERATION_TYPE_TEXT_ARRAY,
			});
			setSearchField(txt);
			setTypeSearchDropdown(true);
			return;
		}

		if (txt === 'status') {
			setDropdownArray({
				dropdownArray: STATUS_ARRAY,
				dropdownTextArray: STATUS_TEXT_ARRAY,
			});
			setSearchField(txt);
			setTypeSearchDropdown(true);
			return;
		}

		setSearchField(txt);
		setTypeSearchDropdown(false);
	};

	const handleExport = () => {
		let searchValue = text && text.length >= 1 ? String(text) : undefined;
		if (searchField === 'pair') {
			searchValue = searchValue?.toLowerCase().replace('/', '_');
		}
		const params: IOperationsRequest = {
			current_page: currentPage,
			export: 1,
			search_value: searchValue,
			search_field: text && text.length >= 1 ? searchField : undefined,
			start_date: startDate || undefined,
			end_date: endDate || undefined,
		};
		dispatch(getOperationsRequest(params));
	};

	const onChangeOperation = (item: IOperation) => {
		setCurrentOperation(item);
		setOpenContinueModal(true);
	};

	const onConfirm = (id: number | null, data: string) => {
		const payload = {
			params: { operation_id: Number(id), operation_date: String(data) },
			onSuccess: () => {
				setSuccessRequest(true);
			},
		};

		dispatch(createOperationContinueRequest(payload));
	};

	return (
		<>
			<div className="title-block">
				<p className="title">Operation</p>
			</div>
			<div className="count-label count-label--filter-type">
				<SearchBar
					setDebouncedTerm={setDebouncedTerm}
					debouncedTerm={debouncedTerm}
					searchFieldArray={OPERATIONS_SEARCH_ARRAY}
					searchField={searchField}
					setSearchField={searchFieldHandler}
					handleClearSearch={handleClearSearch}
					searchFieldTextArray={OPERATIONS_SEARCH_TEXT_ARRAY}
					typeSearch={typeSearchDropdown ? 'dropdown' : undefined}
					dropdownArray={dropdownArray.dropdownArray}
					dropdownTextArray={dropdownArray.dropdownTextArray}
					inputWidth="165px"
				/>
				<DateRangePicker
					format="dd/MM/yyyy"
					size="lg"
					block
					placement="bottomEnd"
					style={{ width: '308px' }}
					disabledDate={afterToday?.()}
					value={dateRangeValue}
					onChange={changeDateRangeHandler}
					defaultCalendarValue={[prevMonth, now]}
					ranges={[]}
				/>

				<DateRangePicker
					className="time-datepicker"
					format="HH:mm"
					size="lg"
					block
					placement="bottomEnd"
					style={{ width: '200px' }}
					value={timeRangeValue}
					onChange={changeTimeRangeHandler}
					defaultCalendarValue={[prevMonth, now]}
					ranges={[]}
					caretAs={ClockIcon}
				/>
				<button
					type="button"
					disabled={exportLoading}
					className="transaction-export-btn"
					onClick={handleExport}
				>
					Export
					<span className="transaction-export-btn__icon">
						<ExportIcon />
					</span>
				</button>
			</div>
			<div className="content-block ">
				<div className="content-block__inside content-block__inside--type2">
					<div className="content-panel content-panel--content-right">
						{checkEditable(permissions.CASHDESK_OPERATION) && (
							<button
								onClick={() => setOpenModal(true)}
								type="button"
								className="button button--green-type button--width-auto"
							>
								Create Operation
							</button>
						)}
					</div>
					<div className="table-block table-block--mt-24">
						<div className="table-wrapper">
							<div className="table table--cashdesk-operation table--with-scroll">
								<div className="table-header">
									<TableHeader />
								</div>
								{!loading && !operations?.data.length && (
									<div className="user-management-empty-data">
										<p className="user-management-empty__text">No records found...</p>
									</div>
								)}
								<div className="table-body">
									{loading ? (
										<div className="list-loader">
											<Loader />
										</div>
									) : (
										operations?.data.map((item) => (
											<TableRow key={item.id} item={item} onChange={onChangeOperation} />
										))
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="pagination-block">
				{(operations?.last_page ?? 0) > 1 && (
					<Pagination
						pageCount={operations?.last_page ?? 0}
						forcePage={currentPage - 1}
						onPageChange={handlePageChange}
					/>
				)}
			</div>
			<CreateOperation
				openModal={openModal}
				closeModal={() => {
					setOpenModal(false);
				}}
			/>
			<ContinueOperation
				openModal={openContinueModal}
				closeModal={() => {
					setOpenContinueModal(false);
				}}
				onConfirm={onConfirm}
				item={currentOperation}
				successRequest={successRequest}
			/>
		</>
	);
};

export default CashDeskOperation;
