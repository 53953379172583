import { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import { notificationContainer } from 'utils/notificationContainer';
import { useSelect } from 'services/hooks/useSelect';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { changeBankAccountStatusRequest } from 'redux/reducers/userManagement/reducer';
import { getBankAccountStatusLoader } from 'redux/reducers/userManagement/selectors';
import { IUserRequestPayload } from 'redux/reducers/userManagement/types';
import { getStatusClassName } from './utils';
import { ICryptoDepositProps } from './types';

const BankAccountTableItem: FC<ICryptoDepositProps> = ({ item, active, toggleActive }) => {
	const dispatch = useDispatch();
	const params = useParams<IUserRequestPayload>();
	const { id } = params;
	const [status, setStatus] = useState<string>(item.status);
	const updateStatusLoader = useSelector(getBankAccountStatusLoader);

	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();

	const handleSelectChange = (_status: string) => {
		setStatus(_status);
		setOpen(false);
	};

	const changeStatus = (statusName: string) => {
		const payload = {
			apiParams: {
				id: String(id),
				account_id: String(item.id),
				statusName,
			},
			onSuccess: () => {
				notificationContainer(`Status was ${statusName}`, 'success');
			},
		};
		dispatch(changeBankAccountStatusRequest(payload));
	};

	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">Name</p>
				<p>{item?.beneficiary_name}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Bank name</p>
				<p>{item?.bank_name}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">SWIFT</p>
				<p>{item?.swift}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">IBAN</p>
				<p>{item?.iban}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Reference</p>
				<p>{item?.reference}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Address</p>
				<p>{item?.address}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Status</p>
				{/* {active ? (
					<div className={`select select--table-type ${open ? 'active' : ''}`}>
						<button type="button" className="select__current" ref={triggerRef} onClick={toggleOpen}>
							<span className="select__current-box">
								<span className="select__current-text">
									{status && capitalizeFirstLetter(status)}
								</span>
							</span>
							<span className="select__current-arrow">
								<svg
									className="stroke"
									width="8"
									height="5"
									viewBox="0 0 8 5"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path d="M1 1L4 4L7 1" stroke="#424242" strokeLinecap="square" />
								</svg>
							</span>
						</button>
						<div className="select__drop" ref={dropRef}>
							<div className="select__drop-scroll">
								<div className="select-drop__item">
									<ul>
										<li>
											<button type="button" onClick={() => handleSelectChange('unverified')}>
												Unverified
											</button>
										</li>
										<li>
											<button type="button" onClick={() => handleSelectChange('verified')}>
												Verified
											</button>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				) : ( */}
				<span className={`status ${getStatusClassName(item.status)}`}>
					{item.status && capitalizeFirstLetter(item.status)}
				</span>
				{/* )} */}
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Action</p>
				{item.status === 'processing' ? (
					<div className="table-buttons">
						<button
							type="button"
							onClick={() => changeStatus('rejected')}
							disabled={updateStatusLoader}
						>
							<span className="edit-icon icon-cancel-icon" />
						</button>
						<button
							type="button"
							onClick={() => changeStatus('verified')}
							disabled={updateStatusLoader}
						>
							<span className="edit-icon icon-succes-icon" />
						</button>
					</div>
				) : (
					''
				)}
			</div>
		</div>
	);
};

export default BankAccountTableItem;
