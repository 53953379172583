import { FC, useState, useEffect, ChangeEvent } from 'react';
import Popup from 'reactjs-popup';
import { useSelector, useDispatch } from 'react-redux';
import { acceptRequestedRateRequest } from 'redux/reducers/cashdesk/reducer';
import { acceptRequestedRateLoading } from 'redux/reducers/cashdesk/selectors';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { IAcceptProps } from './types';

const AcceptPopup: FC<IAcceptProps> = ({ openModal, closeModal, operationId, rate }) => {
	const dispatch = useDispatch();
	const [isAllowed, setIsAllowed] = useState(false);
	const [newRate, setNewRate] = useState(String(rate));

	const { checkEditable } = usePermission();

	const canEdit = checkEditable(permissions.CASHDESK_REQUESTED_RATES);

	const loading = useSelector(acceptRequestedRateLoading);

	const handleAccept = () => {
		if (!canEdit) return;
		dispatch(
			acceptRequestedRateRequest({
				apiParams: {
					operation_id: operationId,
					new_rate: isAllowed && newRate && +newRate !== rate ? +newRate : undefined,
				},
				onSuccess: closeModal,
			}),
		);
	};

	const handleRateChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		if (value && !/^\d+(\.+\d*)?$/.test(value)) {
			return;
		}
		setNewRate(value);
	};

	useEffect(() => {
		if (!isAllowed && newRate !== String(rate)) {
			setNewRate(String(rate));
		}
	}, [isAllowed, newRate, rate]);

	return (
		<Popup open={openModal} onClose={closeModal} closeOnEscape>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup">
						<div className="popup-header">
							<p className="popup-header__title">Accept rate</p>
							<div className="popup-text popup-text--mt-8 popup-text--center">
								<p>Are you sure you want to confirm the rate?</p>
							</div>
						</div>
						<div className="popup-body popup-body--type2">
							<div className="input">
								<div className="input__header">
									<p className="input__name">Add new rate</p>
									<div className="checkbox checkbox--no-mb">
										<label className="checkbox__label">
											<input
												type="checkbox"
												className="hidden"
												checked={isAllowed}
												onChange={() =>
													setIsAllowed((prev) => {
														return !prev;
													})
												}
											/>
											<div className=" checkbox__item">
												<span className="checkbox__item-icon">
													<svg
														className="fill"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 26 26"
														width="26"
														height="26"
														fill="#1a1a1a"
													>
														<path d="M 22.566406 4.730469 L 20.773438 3.511719 C 20.277344 3.175781 19.597656 3.304688 19.265625 3.796875 L 10.476563 16.757813 L 6.4375 12.71875 C 6.015625 12.296875 5.328125 12.296875 4.90625 12.71875 L 3.371094 14.253906 C 2.949219 14.675781 2.949219 15.363281 3.371094 15.789063 L 9.582031 22 C 9.929688 22.347656 10.476563 22.613281 10.96875 22.613281 C 11.460938 22.613281 11.957031 22.304688 12.277344 21.839844 L 22.855469 6.234375 C 23.191406 5.742188 23.0625 5.066406 22.566406 4.730469 Z" />
													</svg>
												</span>
											</div>
											<p className="checkbox__text checkbox__text--for-mobile">Select all</p>
										</label>
									</div>
								</div>
								<div className="input-wrapper">
									<input
										className="input-item"
										type="text"
										placeholder="Enter rate"
										style={{ opacity: !isAllowed ? 0.5 : 1 }}
										disabled={!isAllowed}
										value={newRate}
										onChange={handleRateChange}
									/>
								</div>
							</div>
						</div>
						<div className="popup-submit popup-submit--type2">
							<button
								type="button"
								className="button button--red-type button--full-width "
								onClick={closeModal}
							>
								Cancel
							</button>
							<button
								type="button"
								className="button button--green-type button--full-width "
								disabled={loading || !canEdit}
								onClick={handleAccept}
							>
								Accept
							</button>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default AcceptPopup;
