import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import { SelectReferral } from 'components/Referral/SelectReferral';

// ================================================:
const SelectReferralPage: FC = () => {
	return (
		<Dashboard title="Referral">
			<SelectReferral />
		</Dashboard>
	);
};
export default SelectReferralPage;
