/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { TTypeWalletmanagement } from 'redux/reducers/cashdesk/types';
import {
	getWalletsListRequest,
	getWalletHistoryRequest,
	getWalletOperationListRequest,
} from 'redux/reducers/cashdesk/reducer';
import Pagination from 'ui/Pagination';
import { getHistory, getWallets } from 'redux/reducers/cashdesk/selectors';
import { AddWallet } from './Popups/AddWallet';
import { WalletFilter } from './filters/WalletFilter';
import { HisoryFilter } from './filters/HisoryFilter';
import 'react-tabs/style/react-tabs.css';
import { Wallet } from './Wallet/Wallet';
import { History } from './History/History';
import { TYPE_WALLET, TYPE_HISTORY } from './constants';

const WalletManagement: FC = () => {
	const { search } = useLocation();
	const dispatch = useDispatch();
	const history = useHistory();
	const historyData = useSelector(getHistory);
	const walletList = useSelector(getWallets);
	const location = useLocation();
	const { page, text, field } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);
	const [openModal, setOpenModal] = useState(false);
	const [tab, setTab] = useState<'wallet' | 'history'>('wallet');
	const countPage = tab === TYPE_WALLET ? walletList?.last_page : historyData?.last_page;
	const lengthPage = tab === TYPE_WALLET ? walletList?.data.length : historyData?.data.length;

	const [term, setTerm] = useState('');
	const [debouncedTerm, setDebouncedTerm] = useState(term);

	useEffect(() => {
		const timer = setTimeout(() => setTerm(debouncedTerm), 600);
		return () => clearTimeout(timer);
	}, [debouncedTerm]);

	const handleClearSearch = () => {
		setDebouncedTerm('');
		setTerm('');
	};

	const getRequest = (params: any, typeParam?: TTypeWalletmanagement) => {
		const type = typeParam;

		switch (type) {
			case TYPE_HISTORY:
				dispatch(getWalletHistoryRequest(params));
				break;

			default:
				dispatch(getWalletsListRequest(params));
		}
	};

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		const searchParams = text?.length
			? `?page=${String(pageNumber)}&text=${String(text)}&field=${String(field)}`
			: `?page=${String(pageNumber)}`;
		const searchValue = text?.length ? String(text) : undefined;
		const params = {
			per_page: 15,
			current_page: pageNumber || 1,
			search_value: searchValue,
			search_field: text?.length ? String(field) : undefined,
		};
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});
		getRequest(params, tab);
	};

	useEffect(() => {
		if (!term && !debouncedTerm) {
			// history.push(location.pathname);
			const params = {
				current_page: 1,
			};
			// getRequest(params, tab);
		}
	}, [term, debouncedTerm, tab]);

	useEffect(() => {
		setCurrentPage(Number(page) || 1);
	}, [page]);

	return (
		<>
			<div className="title-block">
				<p className="title"> Wallet management</p>
			</div>
			{tab === TYPE_WALLET ? <WalletFilter /> : <HisoryFilter />}
			<div className="content-block">
				<div className="content-block__inside content-block__inside--type2">
					<div className="content-panel">
						<ul className="tabs-list tabs-list--type2">
							<li
								style={{ cursor: 'pointer' }}
								className={`tabs-list__item ${tab === TYPE_WALLET ? 'active' : ''}`}
							>
								<a
									style={{ textDecoration: 'none' }}
									onClick={() => {
										setTab(TYPE_WALLET);
									}}
									className="tabs-list__link"
								>
									Wallet
								</a>
							</li>

							<li
								style={{ cursor: 'pointer' }}
								className={`tabs-list__item ${tab === TYPE_HISTORY ? 'active' : ''}`}
							>
								<a
									style={{ textDecoration: 'none' }}
									onClick={() => {
										setTab(TYPE_HISTORY);
									}}
									className="tabs-list__link"
								>
									History
								</a>
							</li>
						</ul>
						{tab === TYPE_WALLET ? (
							<button
								type="button"
								onClick={() => setOpenModal(true)}
								className="button button--green-type button--width-auto"
							>
								Add wallet
							</button>
						) : (
							''
						)}
					</div>
					<div className="table-block table-block--mt-24">
						{tab === TYPE_WALLET ? <Wallet /> : <History />}
					</div>
				</div>
			</div>
			<AddWallet
				openModal={openModal}
				closeModal={() => {
					setOpenModal(false);
					// dispatch(setUsers(null));
				}}
				onSuccessed={() => {}}
			/>
			{!lengthPage || countPage === 1 ? (
				''
			) : (
				<div style={{ padding: '14px' }}>
					<Pagination
						pageCount={countPage || 0}
						forcePage={currentPage - 1}
						onPageChange={handlePageChange}
					/>
				</div>
			)}
		</>
	);
};

export default WalletManagement;
