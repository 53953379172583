/* eslint-disable @typescript-eslint/naming-convention */
import { FC, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { deleteOperatorRequest } from 'redux/reducers/cashdesk/reducer';
import usePermission from 'services/hooks/usePermission';
import { permissions as PERMISSIONS } from 'services/constants/permissions';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import DeleteOperatorModal from '../DeleteModal';
import { IListItemProps } from './types';

// ==========================================:
const AdminManagementItem: FC<IListItemProps> = ({ data }) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const { search } = useLocation();
	const { page } = queryString.parse(search);

	const { checkEditable } = usePermission();

	const canEdit = checkEditable(PERMISSIONS.CASHDESK_PERMISSION_MANAGEMENT);

	const {
		id,
		email = '',
		first_name = '',
		last_name = '',
		type = '',
		cashdesk_name = '',
		created_at,
		permissions,
	} = data;

	const [openModal, setOpenModal] = useState(false);
	const closeModal = () => setOpenModal(false);

	const handleDeleteAdmin = (): void => {
		if (!canEdit) return;
		const userId: number = id;
		dispatch(
			deleteOperatorRequest({
				userId,
				page: page || 1,
			}),
		);
	};

	const handleEditAdmin = (): void => {
		if (!canEdit) return;
		const userId: number = id;

		history.push({
			pathname: `/cash-desk/operators/edit/${userId}`,
		});
	};

	const sortedPermissionsByName = useMemo(() => {
		return (
			permissions.length &&
			[...permissions].sort((a, b) => {
				if (a.name < b.name) {
					return -1;
				}
				if (a.name > b.name) {
					return 1;
				}
				return 0;
			})
		);
	}, [permissions]);

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">&#8470;</p>
				<p>{id}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Full name</p>
				<p>
					{first_name} {last_name}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Type</p>
				<p>{capitalizeFirstLetter(type)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Cash Desk</p>
				<p>{cashdesk_name}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Email</p>
				<p>
					<a href={`mailto:${String(email)}`}>{email}</a>
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Permissions</p>
				<div className="permissions">
					<p>Permissions</p>
					<div className="hint-block">
						<span className="permissions-icon permissions-icon--action icon-info-icon" />
						<div className="hint-item hint-item--type2">
							{sortedPermissionsByName && sortedPermissionsByName?.length
								? sortedPermissionsByName.map((item) => (
										<p key={item.id} className="permission-item">
											{item?.name}
										</p>
								  ))
								: null}
							{sortedPermissionsByName && sortedPermissionsByName?.length === 0 && (
								<p className="permission-item">There are no permissions for this user</p>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Registration date</p>
				<p>{created_at}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Edit</p>
				<div className="table-buttons">
					<button type="button" disabled={!canEdit} onClick={handleEditAdmin}>
						<span className="edit-icon edit-icon--action icon-edit-icon" />
					</button>
					<button
						type="button"
						disabled={!canEdit}
						onClick={() => setOpenModal((prevOpenModal) => !prevOpenModal)}
					>
						<span className="trash-icon trash-icon--action icon-trash-icon" />
					</button>
					<DeleteOperatorModal
						openModal={openModal}
						closeModal={closeModal}
						handleDeleteAdmin={handleDeleteAdmin}
					/>
				</div>
			</div>
		</div>
	);
};

export default AdminManagementItem;
