import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrenciesRequest } from 'redux/reducers/cashdesk/reducer';
import { getCurrenciesIsLoad, getCurrencies } from 'redux/reducers/cashdesk/selectors';
import Loader from 'ui/Loader';
import RowItem from './RowItem';

const Currencies = () => {
	const dispatch = useDispatch();
	const loading = useSelector(getCurrenciesIsLoad);
	const spreads = useSelector(getCurrencies);

	useEffect(() => {
		dispatch(getCurrenciesRequest());
	}, [dispatch]);

	return (
		<div className="table table--cashdesk-currencies">
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<div className="td-name">
							<p>Currency</p>
						</div>
					</div>
					<div className="td td--right">
						<div className="td-name">
							<p>Action</p>
						</div>
					</div>
				</div>
			</div>
			{loading ? (
				<div className="list-loader">
					<Loader />
				</div>
			) : (
				<div className="table-body">
					{spreads?.map((item) => (
						<RowItem key={item.id} item={item} />
					))}
				</div>
			)}
		</div>
	);
};

export default Currencies;
