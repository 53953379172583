import { FC, useEffect, useRef, useState } from 'react';
import { IAvailableCurrenciesItem } from 'redux/reducers/cashdesk/types';
import { IDCDropdown } from './types';
/* eslint-disable */
// ==================================:
const CDropdownCurrencies: FC<IDCDropdown> = (props) => {
	const {
		value,
		options,
		placeholder = '',
		onChange,
		currentClass,
		onBloor,
		disabled,
		searchDisable,
		emptyListText = 'No payments method',
	} = props;
	// debugger;
	const [open, setOpen] = useState(false);
	const [search, setSearch] = useState('');
	const node = useRef<HTMLDivElement | null>(null);

	const filteredOptions = search
		? options?.filter((item) => item.asset.name.toLowerCase().startsWith(search.toLowerCase()))
		: options;

	const handleClick = (event: MouseEvent): boolean | undefined => {
		if (node?.current?.contains(event.target as Node)) {
			return false;
		}

		setOpen(false);
		return true;
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClick);

		return () => {
			document.removeEventListener('mousedown', handleClick);
		};
	}, []);

	const handleChange = (selectedValue: IAvailableCurrenciesItem): void => {
		if (disabled) {
			return;
		}
		onChange(selectedValue);
		setOpen(false);
	};

	const handleSelectToggel = (): void => {
		setOpen(!open);
	};

	return (
		<div
			className={`select select--coin-list-new select--height-58 ${currentClass || ''}${
				open ? 'active' : ''
			} ${disabled ? 'select--disabled' : ''}`}
			ref={node}
		>
			<button
				type="button"
				className="select__current"
				onClick={handleSelectToggel}
				onBlur={onBloor}
				disabled={disabled}
			>
				{value ? (
					<>
						<span className="select-coin">
							<img width="30" height="30" src={value?.asset?.img_path} alt="" />
						</span>
						<span className="select-coin-name">
							{value?.asset?.name}
							<span className="select-coin-name__extra">
								&nbsp;{value.asset.code.toUpperCase()}
							</span>
						</span>
					</>
				) : (
					<span className="select-coin grey-text select-coin_playceholder">{placeholder}</span>
				)}
				<span style={{ marginLeft: 'auto' }} className="select__current-arrow">
					<svg
						className="stroke"
						width="8"
						height="5"
						viewBox="0 0 8 5"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M1 1L4 4L7 1" stroke="#424242" strokeLinecap="square" />
					</svg>
				</span>
			</button>
			{open && options && (
				<div className="select__drop">
					{searchDisable ? (
						''
					) : (
						<div className="drop-search">
							<div className="input-wrapper">
								<input
									className="input-item input-item--right-icon"
									type="text"
									placeholder="Enter token name"
									onChange={(e) => setSearch(e.target.value)}
									value={search}
								/>
								<span className="input-icon input-icon--right">
									<svg
										width="22"
										height="22"
										viewBox="0 0 22 22"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											opacity="0.5"
											d="M19.8028 20.865C20.0957 21.1579 20.5706 21.1579 20.8635 20.865C21.1564 20.5721 21.1564 20.0972 20.8635 19.8043L19.8028 20.865ZM20.8635 19.8043L15.5302 14.471L14.4695 15.5316L19.8028 20.865L20.8635 19.8043ZM9.6665 16.918C5.66244 16.918 2.4165 13.672 2.4165 9.66797H0.916504C0.916504 14.5005 4.83401 18.418 9.6665 18.418V16.918ZM16.9165 9.66797C16.9165 13.672 13.6706 16.918 9.6665 16.918V18.418C14.499 18.418 18.4165 14.5005 18.4165 9.66797H16.9165ZM9.6665 2.41797C13.6706 2.41797 16.9165 5.6639 16.9165 9.66797H18.4165C18.4165 4.83548 14.499 0.917969 9.6665 0.917969V2.41797ZM9.6665 0.917969C4.83401 0.917969 0.916504 4.83548 0.916504 9.66797H2.4165C2.4165 5.6639 5.66244 2.41797 9.6665 2.41797V0.917969Z"
											fill="#777E91"
										/>
									</svg>
								</span>
							</div>
						</div>
					)}
					<div className="select__drop-scroll">
						<div className="select__drop-item">
							{filteredOptions?.length ? (
								<ul>
									{filteredOptions?.map((opt: IAvailableCurrenciesItem) => {
										return (
											<li key={opt?.id}>
												<button type="button" onClick={() => handleChange(opt)} disabled={disabled}>
													<span className="select-coin">
														<img width="30" height="30" src={opt?.asset?.img_path} alt="" />
													</span>
													<span className="select__current-text">
														{opt?.asset?.name}
														<span className="select__current-currency">
															&nbsp;{opt?.asset?.code?.toUpperCase()}
														</span>
													</span>
												</button>
											</li>
										);
									})}
								</ul>
							) : (
								<span className="select__drop-link select__drop-link_no--hover">
									<span className="select__drop-text no-options-text">{emptyListText}</span>
								</span>
							)}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default CDropdownCurrencies;
