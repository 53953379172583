import { FC, useEffect, useRef, useState } from 'react';
import { IDCDropdown, IItemCashDesk } from './types';

// ==================================:
const WalletMDropdown: FC<IDCDropdown> = (props) => {
	const {
		value,
		options,
		placeholder = '',
		onChange,
		currentClass,
		onBloor,
		disabled,
		type,
	} = props;

	const [open, setOpen] = useState(false);
	const node = useRef<HTMLDivElement | null>(null);

	const handleClick = (event: MouseEvent): boolean | undefined => {
		if (node?.current?.contains(event.target as Node)) {
			return false;
		}

		setOpen(false);
		return true;
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClick);

		return () => {
			document.removeEventListener('mousedown', handleClick);
		};
	}, []);

	const handleChange = (selectedValue: IItemCashDesk): void => {
		if (disabled) {
			return;
		}
		onChange(selectedValue);
		setOpen(false);
	};

	const handleSelectToggel = (): void => {
		setOpen(!open);
	};

	return (
		<div
			className={`select select--regular2 ${currentClass || ''}${open ? 'active' : ''} ${
				disabled ? 'select--disabled' : ''
			}`}
			ref={node}
		>
			<button
				type="button"
				className="select__current select__current--unselect-type2"
				onClick={handleSelectToggel}
				onBlur={onBloor}
				disabled={disabled}
			>
				{value ? (
					<span className="select__current-text">
						{type === 'cashdesk'
							? value?.name
							: `${String(value?.first_name)} ${String(value?.last_name)}`}
					</span>
				) : (
					<span className="select__current-text">{placeholder}</span>
				)}
				<span className="select__current-arrow">
					<svg
						className="stroke"
						width="8"
						height="5"
						viewBox="0 0 8 5"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M1 1L4 4L7 1" stroke="#424242" strokeLinecap="square" />
					</svg>
				</span>
			</button>
			{open && options && (
				<div className="select__drop">
					<div className="select__drop-scroll">
						<div className="select__drop-item">
							{options?.length ? (
								<ul>
									{options?.map((opt: IItemCashDesk) => {
										return (
											<li key={opt?.id}>
												<button type="button" onClick={() => handleChange(opt)} disabled={disabled}>
													<span className="select__current-text">
														{type === 'cashdesk'
															? opt?.name
															: `${String(opt?.first_name)} ${String(opt?.last_name)}`}
													</span>
												</button>
											</li>
										);
									})}
								</ul>
							) : (
								<span className="select__drop-link select__drop-link_no--hover">
									<span className="select__drop-text no-options-text">
										{value?.name ? 'No cashdesks' : 'No operators'}
									</span>
								</span>
							)}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default WalletMDropdown;
