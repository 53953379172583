import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Dashboard from 'layouts/Dashboard';
import CashdeskOperators from 'components/CashdeskOperators';
import { getAvailCashdesksRequest } from 'redux/reducers/cashdesk/reducer';

// ================================================:
const CashdeskPermissionsPage: FC = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getAvailCashdesksRequest());
	}, [dispatch]);

	return (
		<Dashboard title="Management of permissions">
			<CashdeskOperators />
		</Dashboard>
	);
};

export default CashdeskPermissionsPage;
