import { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { editNotificationRequest } from 'redux/reducers/cashdesk/reducer';
import { getEditNotificationLoading } from 'redux/reducers/cashdesk/selectors';
import { IProps } from './types';

const NotificationRowItem: FC<IProps> = ({ item }) => {
	const dispatch = useDispatch();
	const { title, email_enabled: emailEnabled } = item;
	const [switcher, setSwitcher] = useState(Boolean(emailEnabled));
	const editLoading = useSelector(getEditNotificationLoading);
	const { checkEditable } = usePermission();
	const canEdit = checkEditable(permissions.CASHDESK_CONFIGURATIONS);

	const handleSwitcherChange = (sw: boolean) => {
		if (!canEdit) return;
		setSwitcher(sw);
		dispatch(
			editNotificationRequest({
				id: item.id,
				email_enabled: sw ? 1 : 0,
			}),
		);
	};
	return (
		<div className="tr">
			<div className="td">
				<p>{title}</p>
			</div>
			<div className="td td--right">
				<div className="switch switch--type2">
					<label className="switch__label">
						<input
							type="checkbox"
							className="hidden"
							checked={switcher}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								handleSwitcherChange(e.target.checked);
							}}
							disabled={!canEdit || editLoading}
						/>
						<div className="switch__toggler" />
					</label>
				</div>
			</div>
		</div>
	);
};

export default NotificationRowItem;
