import { FC, useState, useEffect, ChangeEvent } from 'react';
import Popup from 'reactjs-popup';
import { useSelector, useDispatch } from 'react-redux';
import { rejectRequestedRateRequest } from 'redux/reducers/cashdesk/reducer';
import { rejectRequestedRateLoading } from 'redux/reducers/cashdesk/selectors';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { IRejectPopup } from './types';

const RejectPopup: FC<IRejectPopup> = ({ openModal, closeModal, operationId }) => {
	const dispatch = useDispatch();
	const [comment, setComment] = useState('');

	const loading = useSelector(rejectRequestedRateLoading);

	const { checkEditable } = usePermission();

	const canEdit = checkEditable(permissions.CASHDESK_REQUESTED_RATES);

	const handleCommentCahnge = (e: ChangeEvent<HTMLTextAreaElement>) => {
		const { value } = e.target;
		setComment(value);
	};

	const handleReject = () => {
		if (!canEdit || !comment) return;
		dispatch(
			rejectRequestedRateRequest({
				apiParams: { operation_id: operationId, reject_comment: comment },
				onSuccess: closeModal,
			}),
		);
	};

	return (
		<Popup open={openModal} onClose={closeModal} closeOnEscape>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup">
						<div className="popup-header">
							<p className="popup-header__title">Reject rate</p>
							<div className="popup-text popup-text--mt-8 popup-text--center">
								<p>Are you sure you want to reject the rates?</p>
							</div>
						</div>
						<div className="popup-body popup-body--type2">
							<div className="textarea textarea--mt-16">
								<textarea
									className="textarea__item textarea__item--size2"
									placeholder="Comment"
									value={comment}
									onChange={handleCommentCahnge}
								/>
							</div>
						</div>
						<div className="popup-submit popup-submit--type2">
							<button
								type="button"
								className="button button--red-type button--full-width "
								onClick={closeModal}
							>
								Cancel
							</button>
							<button
								type="button"
								className="button button--green-type button--full-width "
								disabled={loading || !canEdit || !comment}
								onClick={handleReject}
							>
								Reject
							</button>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default RejectPopup;
