import { FC, useEffect, useState } from 'react';
import { numberFormat } from 'utils/numberFormat';
import Loader from 'ui/Loader';
import { useSelector, useDispatch } from 'react-redux';
import {
	getCashdeskList,
	getCashdeskData,
	getCashdeskIsLoad,
} from 'redux/reducers/cashdesk/selectors';
import { getCashExportRequest } from 'redux/reducers/cashdesk/reducer';
import { notificationContainer } from 'utils/notificationContainer';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { CreateCashdesk } from './Popups/CreateCashdesk';
import DeskItem from './DeskItem';

// ================================================:
const Dashboard: FC = () => {
	const dispatch = useDispatch();
	const cashdeskData = useSelector(getCashdeskData);
	const cashdeskList = useSelector(getCashdeskList);
	const balanceListIsLoad = useSelector(getCashdeskIsLoad);
	const [openModal, setOpenModal] = useState(false);

	useEffect(() => {
		if (openModal) {
			document.body.classList.add('popup-open');
		} else {
			document.body.classList.remove('popup-open');
		}
	}, [openModal]);

	const handleAddCashdesk = () => {
		setOpenModal(true);
	};
	const handleExport = () => {
		dispatch(getCashExportRequest());
	};

	return (
		<>
			<div className="title-block title-block--between">
				<p className="title">Dashboard</p>
				<button onClick={handleExport} type="button" className="transaction-export-btn">
					Export cashdesk
					<span className="transaction-export-btn__icon">
						<svg
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M4.7495 14.9375H11.2513C13.5163 14.9375 14.9375 13.3332 14.9375 11.063V4.937C14.9375 2.66675 13.5238 1.0625 11.2513 1.0625H4.7495C2.477 1.0625 1.0625 2.66675 1.0625 4.937V11.063C1.0625 13.3332 2.477 14.9375 4.7495 14.9375Z"
								stroke="#777E91"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M8 11.0645V4.93555"
								stroke="#777E91"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M10.811 8.24219L7.99996 11.0652L5.18896 8.24219"
								stroke="#777E91"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</span>
				</button>
			</div>
			<div className="content-block content-block--cashdesk">
				<div className="content-block__inside">
					<div className="dashboard-header">
						<div className="dashboard-header__title">
							<h2>Cash Desks </h2>
							<p>
								Total balance: ${' '}
								{numberFormat(Number(cashdeskData?.global_balance_usd || 0), 'en-EN')}
							</p>
						</div>
						<button
							onClick={handleAddCashdesk}
							type="button"
							className="button button--green-type button--width-auto"
						>
							Add Cash Desk
						</button>
					</div>
					{balanceListIsLoad || !cashdeskList?.length ? (
						<div className="list-loader">
							<Loader />
						</div>
					) : (
						cashdeskList.map((data, id) => <DeskItem key={Number(id)} data={data} />)
					)}
				</div>
			</div>
			<CreateCashdesk
				openModal={openModal}
				closeModal={() => {
					setOpenModal(false);
				}}
				type="add"
			/>
		</>
	);
};

export default Dashboard;
