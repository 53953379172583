import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import Referral from 'components/Referral';

// ================================================:
const ReferralPage: FC = () => {
	return (
		<Dashboard title="Referral">
			<Referral />
		</Dashboard>
	);
};
export default ReferralPage;
