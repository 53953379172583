import { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getHotColdTransactionsIsLoad,
	getHotColdTransactionsList,
	getFilterParams,
} from 'redux/reducers/walletManagement/selectors';
import queryString from 'query-string';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import { useHistory, useLocation } from 'react-router';
import { IHotColdTransactionsRequestPayload } from 'redux/reducers/walletManagement/types';
import {
	geHotColdTransactionsRequest,
	setFilterParams,
} from 'redux/reducers/walletManagement/reducer';
import { TransactionsItem } from './TransactionsItem';

export const Transactions: FC = () => {
	const hotColdTransactionsLoader = useSelector(getHotColdTransactionsIsLoad);
	const hotColdTransactions = useSelector(getHotColdTransactionsList);
	const filterParams = useSelector(getFilterParams);
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const { search } = useLocation();
	const { page, field, text } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);

	const { start_date: startDate, end_date: endDate } = filterParams || {};

	useEffect(() => {
		const params: IHotColdTransactionsRequestPayload = {
			current_page: 1,
			start_date: startDate || null,
			end_date: endDate || null,
		};
		setCurrentPage(1);
		history.push({
			pathname: location.pathname,
		});
		dispatch(geHotColdTransactionsRequest(params));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, startDate, endDate]);

	useEffect(() => {
		if (page && Number(page) === 1 && !text) {
			setCurrentPage(1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	useEffect(() => {
		return () => {
			dispatch(setFilterParams({ start_date: null, end_date: null }));
		};
	}, [dispatch]);

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		let params: IHotColdTransactionsRequestPayload = {
			current_page: pageNumber || 1,
			start_date: startDate || null,
			end_date: endDate || null,
		};
		const searchParams =
			text && field && text.length >= 1
				? `?page=${String(pageNumber || 1)}&text=${String(text)}&field=${String(field)}`
				: `?page=${String(pageNumber)}`;
		if (field && text) {
			params = {
				...params,
				search_value: String(text),
				search_field: String(field),
			};
		}
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});

		dispatch(geHotColdTransactionsRequest(params));
	};

	return (
		<>
			<div className="content-block content-block--walletman">
				<div>
					<div className="table-block table-block--walletman">
						<div className="table-wrapper">
							<div className="table table--wallett-transact table--with-scroll">
								<div className="table-header">
									<div className="tr">
										<div className="td">
											<div className="td-name">
												<p>ID</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Date</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Coin</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Amount</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Type</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Comment</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Status</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>MEMO code</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Address to</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Hash</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Address from</p>
											</div>
										</div>
									</div>
								</div>

								{!hotColdTransactions?.data?.length && !hotColdTransactionsLoader && (
									<div className="user-management-empty-data">
										<p className="user-management-empty__text">No transactions found...</p>
									</div>
								)}

								{hotColdTransactionsLoader ? (
									<div className="list-loader">
										<Loader />
									</div>
								) : (
									<div className="table-body">
										{hotColdTransactions?.data?.map((item) => (
											<TransactionsItem key={item.id} data={item} />
										))}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			{(hotColdTransactions?.last_page ?? 0) > 1 && (
				<Pagination
					pageCount={hotColdTransactions?.last_page ?? 0}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)}
		</>
	);
};
