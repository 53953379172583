import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getNotificationsRequest } from 'redux/reducers/cashdesk/reducer';
import { getNotificationsIsLoad, getNotifications } from 'redux/reducers/cashdesk/selectors';
import Loader from 'ui/Loader';
import RowItem from './RowItem';

const Notifications = () => {
	const dispatch = useDispatch();
	const loading = useSelector(getNotificationsIsLoad);
	const notificationsResponse = useSelector(getNotifications);
	const { notifications = [] } = notificationsResponse || {};

	useEffect(() => {
		dispatch(getNotificationsRequest());
	}, [dispatch]);

	return (
		<div className="table table--cashdesk-notifications">
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<div className="td-name">
							<p>Notifications</p>
						</div>
					</div>
					<div className="td td--right">
						<div className="td-name">
							<p>Email</p>
						</div>
					</div>
				</div>
			</div>
			{loading ? (
				<div className="list-loader">
					<Loader />
				</div>
			) : (
				<div className="table-body">
					{notifications.map((item) => (
						<RowItem key={item.id} item={item} />
					))}
				</div>
			)}
		</div>
	);
};

export default Notifications;
