import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Dashboard from 'layouts/Dashboard';
import {
	getOperatorPermissionsRequest,
	getAvailCashdesksRequest,
} from 'redux/reducers/cashdesk/reducer';
import EditCashdeskOperator from 'components/EditCashdeskOperator';

// ================================================:
const CashdeskOperatorEditPage: FC = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getOperatorPermissionsRequest());
		dispatch(getAvailCashdesksRequest());
	}, [dispatch]);

	return (
		<Dashboard title="Operator edit">
			<EditCashdeskOperator />
		</Dashboard>
	);
};

export default CashdeskOperatorEditPage;
