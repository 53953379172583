/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState, useEffect, ChangeEvent } from 'react';
import { notificationContainer } from 'utils/notificationContainer';
import Popup from 'reactjs-popup';
import { useDispatch, useSelector } from 'react-redux';
import {
	ICreateOperationPayload,
	IAvailableCurrenciesItem,
	IAvailableNetwork,
} from 'redux/reducers/cashdesk/types';
import { currentDateUTC } from 'utils/dateAndTimeHelpers';
import {
	getOperationRateRequest,
	getOperationSpredRequest,
	getOperationCurrenciesRequest,
	createOperationRequest,
	getCheckAddressRequest,
	getOperationUserInitial,
	getOperationCurrenciesInitial,
} from 'redux/reducers/cashdesk/reducer';
import { getUserData } from 'redux/reducers/auth/selectors';
import {
	getOperationsCurrenciesTo,
	getOperationsUser,
	getOperationsCurrenciesFrom,
	getOperationsRate,
	getOperationsSpred,
} from 'redux/reducers/cashdesk/selectors';
import { IItemCashDesk } from 'ui/WalletMDropdown/types';
import WalletMDropdown from 'ui/WalletMDropdown';
import CDropdownCurrencies from 'ui/CDropdownCurrencies';
import { IModalProps } from './types';
import { SuccessPopup } from '../SuccessPopup/SuccessPopup';
import { SDropDown } from '../../DropDowns/SDropDown';
import NDropdown from '../../DropDowns/NDropdown';

// ================================================:
export const CreateOperation: FC<IModalProps> = ({ openModal, closeModal, onSuccessed }) => {
	const user = useSelector(getOperationsUser);
	const operation = [
		{ id: 1, name: 'Deposit/Convert', code: 'deposit_convert' },
		{ id: 2, name: 'Convert/Cash out', code: 'convert_cashout' },
		{ id: 3, name: 'Deposit/Convert/Transfer', code: 'deposit_convert_transfer' },
	];
	const currenciesFrom = useSelector(getOperationsCurrenciesFrom);
	const currenciesTo = useSelector(getOperationsCurrenciesTo);
	const operationsRate = useSelector(getOperationsRate);
	const operationsSpred = useSelector(getOperationsSpred);
	const userData = useSelector(getUserData);
	const dispatch = useDispatch();
	const [amountFrom, setAmountFrom] = useState('');
	const [amountTo, setAmountTo] = useState('');
	const [userName, setuserName] = useState('');
	const [comment, setComment] = useState('');
	const [cnetworks, setCNetworks] = useState(null);
	const [currentNetwork, setCurrentNetwork] = useState<IAvailableNetwork | null>(null);
	const [address, setAddress] = useState('');
	const [check, setCheckAddress] = useState(false);
	const [openComment, setOpenComment] = useState(false);
	const [successRequest, setSuccessRequest] = useState(false);
	const [editRate, setEditRate] = useState(false);
	const [loaderBtn, setLoaderBtn] = useState(false);
	const [infoMessage, setInfoMessage] = useState('');
	const [selectOperator, setSelectOperator] = useState<IItemCashDesk | null>(null);
	const [currencyFrom, setSelectCurrencyFrom] = useState<IAvailableCurrenciesItem | null>(null);
	const [currencyTo, setSelectCurrencyTo] = useState<IAvailableCurrenciesItem | null>(null);
	const [confirmModal, setConfirmModal] = useState(false);
	const [rate, sertRate] = useState('');
	const [spred, setSpread] = useState('');
	const disable =
		!selectOperator ||
		!userName ||
		!currencyFrom ||
		!currencyTo ||
		!Number(amountTo) ||
		!Number(amountFrom) ||
		editRate;

	const disableWithNet = selectOperator?.code === 'deposit_convert_transfer' ? !check : false;

	useEffect(() => {
		if (!selectOperator) return;
		dispatch(
			getOperationCurrenciesRequest({ type: String(selectOperator?.code), destination: 'from' }),
		);
		dispatch(
			getOperationCurrenciesRequest({ type: String(selectOperator?.code), destination: 'to' }),
		);
	}, [dispatch, selectOperator]);

	// useEffect(() => {
	// 	if (!address) return;
	// 	if (currentNetwork) {
	const checkAddressRequest = () => {
		if (currentNetwork) {
			const params: any = {
				params: {
					currency_code: currentNetwork.network_name,
					address,
					network: currentNetwork.network_id,
				},
				onSuccess: (valid: boolean) => setCheckAddress(valid),
				onError: () => console.log('ss'),
			};
			dispatch(getCheckAddressRequest(params));
		}
	};

	// 	}
	// }, [dispatch, address]);

	useEffect(() => {
		if (user) {
			setuserName(user?.user.username);
		}
	}, [user]);

	useEffect(() => {
		if (!currencyFrom || !currencyTo || !user) return;
		dispatch(
			getOperationRateRequest({
				from_asset_code: currencyFrom.asset.code,
				to_asset_code: currencyTo.asset.code,
				user_id: Number(user?.user.id),
			}),
		);
		dispatch(
			getOperationSpredRequest({
				from_asset_code: currencyFrom.asset.code,
				to_asset_code: currencyTo.asset.code,
			}),
		);
		setInfoMessage('');
	}, [currencyFrom, currencyTo, user]);

	useEffect(() => {
		if (!currencyFrom || !currencyTo || !user) return;
		sertRate(String(operationsRate?.rate));
		setSpread(String(operationsSpred?.spread));
	}, [operationsRate, operationsSpred]);

	useEffect(() => {
		if (amountFrom === '') {
			setAmountTo('');
			return;
		}
		setAmountTo(String(Number(rate) * Number(amountFrom)));
	}, [amountFrom]);

	const checkCoin = () => {
		if (currencyFrom?.networks) {
			return currencyFrom?.networks;
		}
		if (currencyTo?.networks) {
			return currencyTo?.networks;
		}
		return false;
	};

	useEffect(() => {
		if (selectOperator?.code === 'deposit_convert_transfer' && checkCoin()) {
			const networksNew: any = checkCoin();
			setCNetworks(networksNew);
		}
	}, [currencyFrom, currencyTo, selectOperator]);

	const handleCloseModal = () => {
		setuserName('');
		setSelectCurrencyFrom(null);
		setSelectCurrencyTo(null);
		sertRate('');
		setSpread('');
		closeModal();
		setAddress('');
		setAmountFrom('');
		setAmountFrom('');
		dispatch(getOperationUserInitial());
		dispatch(getOperationCurrenciesInitial());
		setInfoMessage('');
		setSelectOperator(null);
		setSelectCurrencyFrom(null);
		setSelectCurrencyTo(null);
	};

	const [debouncedValue, setDebouncedValue] = useState('');

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(address); // Оновлюємо значення для запиту через 500 мс
		}, 500);

		return () => clearTimeout(handler); // Очищаємо таймер при зміні `inputValue`
	}, [address]);

	useEffect(() => {
		if (debouncedValue) {
			checkAddressRequest();
		}
	}, [debouncedValue]);

	const handleSubmit = () => {
		if (!user || !amountFrom || !currencyFrom || !currencyTo || !rate) return;
		const params = {
			user_id: Number(user.user.id),
			from_asset_code: currencyFrom.asset.code,
			to_asset_code: currencyTo.asset.code,
			type: String(selectOperator?.code),
			amount_from: Number(amountFrom),
			operation_date: String(currentDateUTC(true)),
			...(editRate && { requested_rate: rate }),
			...(selectOperator?.code === 'deposit_convert_transfer' &&
				currentNetwork && { network: currentNetwork?.network_id }),
			...(address && { address: String(address) }),
			...(comment && { comment: String(comment) }),
		};
		setLoaderBtn(true);
		const onSuccess = () => {
			setLoaderBtn(false);
			if (editRate) {
				handleCloseModal();
				notificationContainer('Rate change request has been sent.', 'info');
				return;
			}
			notificationContainer('Operation created.', 'info');
			setSuccessRequest(true);
		};
		const payload: ICreateOperationPayload = {
			params: { ...params },
			onSuccess,
			onError: () => {
				setLoaderBtn(false);
			},
		};
		dispatch(createOperationRequest(payload));
	};

	const handleSelectCurrencyFrom = (value: IAvailableCurrenciesItem): void => {
		setSelectCurrencyFrom(value);
	};
	const handleSelectCurrencyTo = (value: IAvailableCurrenciesItem): void => {
		setSelectCurrencyTo(value);
	};

	const handleSelectOperators = (value: IItemCashDesk): void => {
		setSelectOperator(value);
	};

	const handleSelectNetwork = (value: IAvailableNetwork): void => {
		setCurrentNetwork(value);
	};

	const handleChangeRate = (e: ChangeEvent<HTMLInputElement>) => {
		if (!editRate) return;
		const { value } = e.currentTarget;
		sertRate(value);
	};
	const sendAmountfrom = (e: ChangeEvent<HTMLInputElement>) => {
		if (!currencyFrom || !currencyTo) {
			setInfoMessage('First you need to choose a currency!');
			return;
		}
		setInfoMessage('');
		const { value } = e.currentTarget;
		setAmountFrom(value);
	};

	return (
		<Popup open={openModal} closeOnDocumentClick onClose={closeModal} closeOnEscape>
			{confirmModal ? (
				<SuccessPopup
					user={user?.user || null}
					userData={userData}
					currencyFrom={String(currencyFrom?.asset.code)}
					currencyTo={String(currencyTo?.asset.code)}
					opertortId={selectOperator}
					fromAmount={amountFrom}
					toAmount={amountTo}
					address={address}
					network={currentNetwork?.network_name}
					date={String(currentDateUTC(true))}
					onSubmit={() => {
						// closeModal();
						handleSubmit();
						// setSelectOperator(null);
					}}
					onBack={() => {
						handleCloseModal();
						setConfirmModal(false);
					}}
					successRequest={successRequest}
				/>
			) : (
				<div className="popup-window">
					<div className="popup-window__inside">
						<div className="popup ">
							<div className="popup-header">
								<p className="popup-header__title">Create Operation</p>
							</div>
							<div className="popup-body popup-body--type2">
								<div className="input ">
									<p className="input__name">User</p>
									<SDropDown />
								</div>
								<div className="input ">
									<p className="input__name">User name</p>
									<div className="input-wrapper">
										<input
											className="input-item"
											value={userName}
											onChange={(e) => setuserName(e.currentTarget.value)}
											type="text"
											readOnly
											placeholder="Enter user name"
										/>
									</div>
								</div>
								<div className="input ">
									<p className="input__name">Operation</p>
									<WalletMDropdown
										options={operation}
										value={selectOperator}
										onChange={handleSelectOperators}
										placeholder="Select operation"
										type="cashdesk"
									/>
								</div>
								<div className="operation-balance-list">
									<div className="operation-balance">
										<p>Balance From</p>
										<p>
											{currencyFrom?.balance} {currencyFrom?.asset.code?.toUpperCase()}
										</p>
									</div>
									<div className="operation-balance">
										<p>Balance To </p>
										<p>
											{currencyTo?.balance} {currencyTo?.asset.code?.toUpperCase()}
										</p>
									</div>
								</div>
								<div className="form-group">
									<div className="input input--mb-8">
										<p className="input__name">From</p>
										<CDropdownCurrencies
											options={currenciesFrom || []}
											value={currencyFrom}
											onChange={handleSelectCurrencyFrom}
											placeholder="Select currency"
											searchDisable
											emptyListText="No currencies"
										/>
									</div>
									<div className="input ">
										<div className="input-wrapper">
											<input
												className="input-item input-item--pr-75"
												type="text"
												placeholder="0.00"
												onChange={sendAmountfrom}
												value={amountFrom}
											/>
											<div className="input-info">
												<span className="input-info__currency">
													{currencyFrom?.asset.code?.toUpperCase()}
												</span>
											</div>
										</div>
									</div>
									{infoMessage ? <span className="input-error">* {infoMessage}</span> : ''}
								</div>
								<div className="form-group">
									<div className="input input--mb-8">
										<p className="input__name">To</p>
										<CDropdownCurrencies
											options={currenciesTo || []}
											value={currencyTo}
											onChange={handleSelectCurrencyTo}
											placeholder="Select currency"
											searchDisable
											emptyListText="No currencies"
										/>
									</div>
									<div className="input ">
										<div className="input-wrapper">
											<input
												className="input-item input-item--pr-75"
												type="text"
												placeholder="0.00"
												readOnly
												value={amountTo}
											/>
											<div className="input-info">
												<span className="input-info__currency">
													{currencyTo?.asset.code?.toUpperCase()}
												</span>
											</div>
										</div>
									</div>
								</div>
								<div className="input ">
									<p className="input__name">Rate</p>
									<div className="input-and-btn input-and-btn--flex input-and-btn--gap-10">
										<div className="input-wrapper">
											<input
												className="input-item input-item--right-icon"
												type="text"
												placeholder="0"
												value={rate}
												onChange={handleChangeRate}
											/>
											<div className="input-info">
												<button
													onClick={() => setEditRate(true)}
													type="button"
													className="d-flex input-info__action"
												>
													<svg
														width="18"
														height="18"
														viewBox="0 0 18 18"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M0.599609 11.4001L0.246056 11.0465L0.0996094 11.193V11.4001H0.599609ZM11.3996 0.600098L11.7532 0.246544C11.5579 0.0512821 11.2413 0.0512821 11.0461 0.246544L11.3996 0.600098ZM17.3996 6.6001L17.7532 6.95365C17.9484 6.75839 17.9484 6.44181 17.7532 6.24654L17.3996 6.6001ZM6.59961 17.4001V17.9001H6.80672L6.95316 17.7537L6.59961 17.4001ZM0.599609 17.4001H0.0996094C0.0996094 17.6762 0.323467 17.9001 0.599609 17.9001L0.599609 17.4001ZM0.953163 11.7537L11.7532 0.953651L11.0461 0.246544L0.246056 11.0465L0.953163 11.7537ZM11.0461 0.953651L17.0461 6.95365L17.7532 6.24654L11.7532 0.246544L11.0461 0.953651ZM17.0461 6.24654L6.24606 17.0465L6.95316 17.7537L17.7532 6.95365L17.0461 6.24654ZM6.59961 16.9001H0.599609V17.9001H6.59961V16.9001ZM1.09961 17.4001V11.4001H0.0996094V17.4001H1.09961ZM7.44606 4.55365L13.4461 10.5537L14.1532 9.84654L8.15316 3.84654L7.44606 4.55365ZM9.59961 17.9001H17.9996V16.9001H9.59961V17.9001Z"
															fill="#00DA83"
														/>
													</svg>
												</button>
											</div>
										</div>
										{editRate ? (
											<button
												onClick={handleSubmit}
												type="button"
												className="button button--green-type button--br-5"
											>
												Validation
											</button>
										) : (
											''
										)}
									</div>
								</div>
								<div className="input ">
									<p className="input__name">Spread</p>
									<div className="input-wrapper">
										<input
											className="input-item input-item--pr-60"
											type="text"
											placeholder="0"
											value={spred}
											onChange={(e) => setSpread(e.currentTarget.value)}
										/>
										<div className="input-info">
											<span className="input-info__currency">%</span>
										</div>
									</div>
								</div>
								{selectOperator?.code === 'deposit_convert_transfer' ? (
									<>
										<div className="input ">
											<p className="input__name">Select network</p>
											<NDropdown
												options={cnetworks}
												value={currentNetwork}
												onChange={handleSelectNetwork}
												placeholder="Select network"
												type="cashdesk"
											/>
										</div>
										<div className="input ">
											<p className="input__name">Transfer Address ( Public Key )</p>
											<div className="input-wrapper">
												<input
													onChange={(e) => setAddress(e.currentTarget.value)}
													value={address}
													className="input-item "
													type="text"
													placeholder="Enter address"
												/>
											</div>
										</div>
									</>
								) : (
									''
								)}

								<div className="input ">
									<div className="input__header">
										<p className="input__name input__name--fw-500">Comment</p>
										<button
											type="button"
											onClick={() => setOpenComment(!openComment)}
											className="input-drop-btn"
										>
											<svg
												width="12"
												height="7"
												viewBox="0 0 12 7"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M1.5 1.25L6 5.75L10.5 1.25"
													stroke="#022A36"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										</button>
									</div>
									<div style={{ display: `${openComment ? 'block' : ''}` }} className="input__drop">
										<div className="textarea textarea--mt-16">
											<textarea
												value={comment}
												onChange={(e) => setComment(e.currentTarget.value)}
												className="textarea__item "
												placeholder="Comment"
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="popup-submit popup-submit--type2">
								<button
									onClick={handleCloseModal}
									type="button"
									className="button button--cancel button--full-width "
								>
									Cancel
								</button>
								<button
									type="button"
									onClick={() => setConfirmModal(true)}
									className="button button--green-type  button--full-width "
									disabled={disable || disableWithNet}
								>
									Confirm
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</Popup>
	);
};
