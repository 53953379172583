import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from 'ui/Pagination';
import { getMaintenance, getMaintenanceSettings } from 'redux/reducers/settings/selectors';
import {
	getMaintenanceRequest,
	updateMaintenanceSettingsRequest,
	getMaintenanceSettingsRequest,
} from 'redux/reducers/settings/reducer';
import { Switch } from 'ui/Switch';
import 'react-tabs/style/react-tabs.css';
import { Table } from './Table';

// ================================================:
const SettingsMaintenance: FC = () => {
	const dispatch = useDispatch();
	const maintenance = useSelector(getMaintenance);
	const maintenanceSettings = useSelector(getMaintenanceSettings);

	const [isChecked, setIsChecked] = useState<boolean>(false);
	const [currentPage, setCurrentPage] = useState<number>(1);

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		const params = {
			current_page: pageNumber || 1,
		};

		dispatch(getMaintenanceRequest({ params }));
	};

	useEffect(() => {
		dispatch(getMaintenanceRequest({ params: { current_page: 1 } }));
		dispatch(getMaintenanceSettingsRequest());
	}, [dispatch]);

	useEffect(() => {
		setIsChecked(maintenanceSettings?.is_enabled === 1);
	}, [maintenanceSettings]);

	const handleToggle = () => {
		const newStatus = isChecked ? 0 : 1;
		setIsChecked(!isChecked);
		dispatch(updateMaintenanceSettingsRequest({ params: { is_active: newStatus } }));
	};

	return (
		<div className="content-block content-block--mt-0">
			<div className="content-block__inside content-block__inside--type2">
				<div className="tfa-block">
					<div className="tfa-block__info">
						<h3 className="tfa-block__title">Enable Maintenance Mode</h3>
						<p className="tfa-block__text">
							This setting allows you to enable or disable Maintenance Mode for the platform. When
							enabled, users will see a maintenance message, and all APIs will be temporarily
							disabled
						</p>
						<Switch checked={isChecked} onToggle={handleToggle} />
						<p className="tfa-block__text">
							The platform is currently in Maintenance Mode. Users cannot access the system.
						</p>
					</div>
				</div>
				<div className="title-block title-block--big-mt">
					<p className="block-title">Activity Log </p>
				</div>
				<Table />
				{(maintenance?.last_page ?? 0) > 1 && (
					<Pagination
						pageCount={maintenance?.last_page ?? 0}
						forcePage={currentPage - 1}
						onPageChange={handlePageChange}
					/>
				)}
			</div>
		</div>
	);
};

export default SettingsMaintenance;
