export const OPERATIONS_SEARCH_ARRAY = [
	'operation_type',
	'operator_id',
	'user_id',
	'currency',
	'full_name',
	'personal_number',
	'status',
];

export const OPERATIONS_SEARCH_TEXT_ARRAY = [
	'Operation type',
	'Operator ID',
	'User ID',
	'Currency',
	'Full name',
	'Personal number',
	'Status',
];

export const OPERATION_TYPE_ARRAY = [
	'all',
	'deposit_convert',
	'convert_cashout',
	'deposit_convert_transfer',
];

export const OPERATION_TYPE_TEXT_ARRAY = [
	'All',
	'Deposit/Convert',
	'Convert/Cashout',
	'Deposit/Convert/Transfer',
];

export const STATUS_ARRAY = [
	'all',
	'processing',
	'completed',
	'in_progress',
	'on_hold',
	'rejected',
];

export const STATUS_TEXT_ARRAY = [
	'All',
	'Processing',
	'Completed',
	'In progress',
	'On hold',
	'Rejected',
];

export const OPERATIONS_USER_SEARCH_ARRAY = ['user_id', 'email', 'reference'];

export const OPERATIONS_USER_SEARCH_TEXT_ARRAY = ['User ID', 'E-mail', 'Personal Number'];
