import { FC } from 'react';
import { Redirect } from 'react-router-dom';
import usePermission from 'services/hooks/usePermission';
import { IPermissionGate } from './types';

const PermissionGate: FC<IPermissionGate> = ({ permissions, permissionsEdit, children }) => {
	const { checkReadable, checkEditable, permissions: allPermissions } = usePermission();

	const notAllowed =
		(permissions && !checkReadable(permissions)) ||
		(permissionsEdit && !checkEditable(permissionsEdit));

	if (notAllowed) {
		return <Redirect to="/" />;
	}
	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{children}</>;
};

export default PermissionGate;
