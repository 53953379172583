/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState, ChangeEvent, MouseEvent, useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { notificationContainer } from 'utils/notificationContainer';
import { deleteCashDesktRequest, deleteCurrencytRequest } from 'redux/reducers/cashdesk/reducer';
import { IRemoveCashDeskData, IRemoveCurrencyCashDeskData } from 'redux/reducers/cashdesk/types';
import { useDispatch } from 'react-redux';
import { numberFormat } from 'utils/numberFormat';
import { CreateCashdesk } from '../Popups/CreateCashdesk';
import Chart from '../Chart';
import DeleteModal from '../Popups/DeleteModal';
import { IBalancesProps } from './types';
/* eslint-disable */
const DeskItem: FC<IBalancesProps> = ({ data }) => {
	const dispatch = useDispatch();
	const defaultCurrency = data && data?.balances?.length ? data?.balances[0]?.asset_code : '';
	const [balanceTab, setBalanceTab] = useState<string>(defaultCurrency);
	const { balances, total_balance_usd: totalBalanceUsd, qr_url: qrImg, url, name, id } = data;

	const [editMode, setEditMode] = useState<boolean>(false);
	const [openModal, setOpenModal] = useState(false);
	const [openConfirmModal, setOpenConfirmModal] = useState(false);
	const [openCurrencyConfirmModal, setCurrencyOpenConfirmModal] = useState(false);
	const [assetId, setAssetId] = useState<number>();
	// let totalBalance = 0;
	// data.forEach((item) => {
	// 	totalBalance += item.balances[0].balance_usdt;
	// });

	const handleBalanceTab = (e: MouseEvent<HTMLButtonElement>): void => {
		setBalanceTab(e.currentTarget.name);
	};
	const changeCashdesk = (e: ChangeEvent<HTMLInputElement>) => {
		const { checked } = e.currentTarget;
		setEditMode(checked);
	};

	const handleOpenPopup = () => {
		setOpenModal(true);
	};

	useEffect(() => {
		if (openModal) {
			document.body.classList.add('popup-open');
		} else {
			document.body.classList.remove('popup-open');
		}
	}, [openModal]);

	const handleAddressCopy = () => {
		notificationContainer('Address copied successfully!', 'info');
	};

	const closeConfirmModal = () => {
		setOpenConfirmModal(false);
	};

	const handleDelete = () => {
		const payload: IRemoveCashDeskData = {
			params: { cashdesk_id: Number(id) },
			onSuccess: () => {
				notificationContainer('Cashdesk updated', 'success');
				setOpenConfirmModal(false);
			},
		};
		dispatch(deleteCashDesktRequest(payload));
	};

	const handleDeleteCurrency = (assetId: number | string | undefined) => {
		if (data?.balances?.length === 1) {
			notificationContainer('CashDesk must have one currency', 'info');
			return;
		}
		const payload: IRemoveCurrencyCashDeskData = {
			params: { cashdesk_id: Number(id), asset_id: Number(assetId) },
			onSuccess: () => {
				notificationContainer('Currency deleted', 'success');
			},
		};
		setCurrencyOpenConfirmModal(false);
		dispatch(deleteCurrencytRequest(payload));
	};

	return (
		<div className="stats stats--cashdesk">
			<div className="stats__info">
				<div className="stats__header stats__header--type2">
					<div className="stats__header-wrap">
						<span className="stats__title">{name}</span>
						<span className="stats__desc">
							Total balance: $ {numberFormat(totalBalanceUsd, 'en-EN')}
						</span>
					</div>
					<div className="switch switch--type3 switch--label-type">
						<p className="switch__text switch__text--smaller">Edit mode</p>
						<label className="switch__label">
							<input
								onChange={changeCashdesk}
								checked={editMode}
								type="checkbox"
								className="hidden"
							/>
							<span className="switch__toggler" />
						</label>
					</div>
				</div>
				{editMode ? (
					<div className="cashdesk-actions">
						<button
							onClick={handleOpenPopup}
							className="button button--small-size button--green-type button--auto-width"
						>
							Add currency
						</button>
						<button
							onClick={() => setOpenConfirmModal(true)}
							className="button button--small-size button--red-type button--auto-width"
						>
							<span className="btn-icon">
								<svg
									width="13"
									height="14"
									viewBox="0 0 13 14"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M11.3125 3.5625L10.7162 11.9105C10.6648 12.63 10.0661 13.1875 9.34471 13.1875H3.65529C2.93391 13.1875 2.33518 12.63 2.28378 11.9105L1.6875 3.5625M5.125 6.3125V10.4375M7.875 6.3125V10.4375M8.5625 3.5625V1.5C8.5625 1.1203 8.2547 0.8125 7.875 0.8125H5.125C4.7453 0.8125 4.4375 1.1203 4.4375 1.5V3.5625M1 3.5625H12"
										stroke="white"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							</span>
							Delete
						</button>
					</div>
				) : (
					''
				)}
				<div className="stats__list stats__list--cashdesk">
					{balances?.length
						? balances.map((el, id) => (
								<button
									key={id}
									type="button"
									name={el.asset_code}
									onClick={handleBalanceTab}
									className="stats-item-box"
								>
									<div className="stats-item">
										<div className="stats-item__header">
											<div className="stats-item__name">
												<span className="stats-item__img">
													<img src={el.asset_logo_url} alt={el?.asset_code?.toUpperCase()} />
												</span>
												<span className="stats-item__title">{el?.asset_code?.toUpperCase()}</span>
											</div>
											<span className="stats-item__elem">
												{`${numberFormat(el?.balance, 'en-EN')} / $ ${numberFormat(
													el?.balance_usd,
													'en-EN',
												)}`}
											</span>
										</div>
										<div className="stats-item__desc">
											<span className="stats-item__elem">
												<span className="stats-item__elem-chain">BSC-20</span>0 / 80
											</span>
											<span className="stats-item__elem">
												<span className="stats-item__elem-chain">ERC-20</span>0 / 80
											</span>
											<span className="stats-item__elem">
												<span className="stats-item__elem-chain">TRC-20</span>0 / 80
											</span>
										</div>
										{/* <div className="stats-item__arrow">
											<span className="icon-pagination-left-icon" />
										</div> */}
									</div>
									{editMode ? (
										<button
											onClick={() => {
												setAssetId(el.asset_id);
												setCurrencyOpenConfirmModal(true);
											}}
											className="action-btn"
										>
											<svg
												width="14"
												height="16"
												viewBox="0 0 14 16"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M12.25 4.25L11.5995 13.3569C11.5434 14.1418 10.8903 14.75 10.1033 14.75H3.89668C3.10972 14.75 2.45656 14.1418 2.40049 13.3569L1.75 4.25M5.5 7.25V11.75M8.5 7.25V11.75M9.25 4.25V2C9.25 1.58579 8.91421 1.25 8.5 1.25H5.5C5.08579 1.25 4.75 1.58579 4.75 2V4.25M1 4.25H13"
													stroke="#F44336"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										</button>
									) : (
										''
									)}
								</button>
						  ))
						: null}
				</div>
				<div className="cashdesk-qr">
					<p className="cashdesk-qr__title">QR Code</p>
					<div className="cashdesk-qr__item">
						<img src={qrImg} alt="" />
					</div>
					<div className="cashdesk-qr__action">
						<CopyToClipboard text={url} onCopy={handleAddressCopy}>
							<button className="button button--outline button--full-width">Click to copy</button>
						</CopyToClipboard>
					</div>
				</div>
			</div>
			<Chart data={balances} currentBalanceItemName={balanceTab} type="cashdesk" />
			<CreateCashdesk
				openModal={openModal}
				closeModal={() => {
					setOpenModal(false);
				}}
				type="edit"
				cashDeskId={id}
				currentCurrency={balances}
			/>
			<DeleteModal
				openModal={openConfirmModal}
				closeModal={closeConfirmModal}
				handleDelete={handleDelete}
			/>
			<DeleteModal
				openModal={openCurrencyConfirmModal}
				closeModal={() => setCurrencyOpenConfirmModal(false)}
				handleDelete={handleDeleteCurrency}
				currencyId={assetId}
			/>
		</div>
	);
};

export default DeskItem;
