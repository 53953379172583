/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { noExponent, capitalizeFirstLetter } from 'utils/numberFormat';
import { getUpdateWalletstRequest, deleteWalletRequest } from 'redux/reducers/cashdesk/reducer';
import usePermission from 'services/hooks/usePermission';
import { IWalletsProps, IupdateWallets } from './types';

const WalletTableItem: FC<IWalletsProps> = ({ item }) => {
	const [cashdesk] = useState(item?.cashdesk);
	const [balance] = useState(item?.balance);
	const [maxAlert] = useState(item?.max_alert);
	const [minAlert] = useState(item?.min_alert);
	const [operatorId] = useState(item?.operator_id);
	const [code] = useState(item?.asset.code);
	const [initialBalance] = useState(item?.initial_balance);
	const dispatch = useDispatch();

	const [initialBalanceEdit, setInitialBalanceEdit] = useState(String(initialBalance));
	const [balanceEdit, setBalanceEdit] = useState(String(balance));
	const [minAlertEdit, setMinAlertEdit] = useState(String(minAlert));
	const [maxAlertEdit, setMaxAlertEdit] = useState(String(maxAlert));
	const [isClosed, setIsClosed] = useState(false);

	const { checkReadable } = usePermission();

	const handleChangeSubmit = (value: IupdateWallets) => {
		if (
			balanceEdit === '' ||
			minAlertEdit === '' ||
			maxAlertEdit === '' ||
			initialBalanceEdit === ''
		)
			return;
		dispatch(getUpdateWalletstRequest(value));
	};
	const handleDelete = (id: number) => {
		dispatch(deleteWalletRequest({ id }));
	};

	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">Cash Desk#</p>
				<p>{cashdesk.name}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Operator ID</p>
				<p>{operatorId}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Currency</p>
				<p style={{ textTransform: 'uppercase' }}>{code}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Initial balance</p>
				{isClosed ? (
					<div className="table-input table-input--start">
						<input
							type="number"
							onChange={(e) =>
								setInitialBalanceEdit(String(e.target.value.replace(/[^\d\\.]/g, '')))
							}
							placeholder=""
							value={initialBalanceEdit}
						/>
					</div>
				) : (
					<p>{initialBalance}</p>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Balance</p>
				{isClosed ? (
					<div className="table-input table-input--start">
						<input
							type="number"
							onChange={(e) => setBalanceEdit(String(e.target.value.replace(/[^\d\\.]/g, '')))}
							placeholder=""
							value={balanceEdit}
						/>
					</div>
				) : (
					<p>{noExponent(Number(balance).toString())}</p>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Min alert</p>
				{isClosed ? (
					<div className="table-input table-input--start">
						<input
							type="number"
							onChange={(e) => setMinAlertEdit(String(e.target.value.replace(/[^\d\\.]/g, '')))}
							placeholder=""
							value={minAlertEdit}
						/>
					</div>
				) : (
					<p>{noExponent(Number(minAlert).toString())}</p>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Max alert</p>
				{isClosed ? (
					<div className="table-input table-input--start">
						<input
							type="number"
							onChange={(e) => setMaxAlertEdit(String(e.target.value.replace(/[^\d\\.]/g, '')))}
							placeholder=""
							value={maxAlertEdit}
						/>
					</div>
				) : (
					<p>{noExponent(Number(maxAlert).toString())}</p>
				)}
			</div>
			<div className="td  td--right">
				<p className="td-hidden-name">Action</p>
				<div className="table-buttons">
					{isClosed ? (
						<>
							<button onClick={() => setIsClosed(!isClosed)} type="button">
								<span className="edit-icon icon-cancel-icon" />
							</button>
							<button
								onClick={() =>
									handleChangeSubmit({
										id: item.id,
										balance: Number(balanceEdit),
										initial_balance: Number(initialBalanceEdit),
										min_alert: Number(minAlertEdit),
										max_alert: Number(maxAlertEdit),
									})
								}
								type="button"
							>
								<span className="edit-icon icon-succes-icon" />
							</button>
						</>
					) : (
						<button
							type="button"
							onClick={() => setIsClosed(!isClosed)}
							className="table-buttons__edit-btn"
						>
							<svg
								width="18"
								height="19"
								viewBox="0 0 18 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M0.599609 11.8996L0.246056 11.5461L0.0996094 11.6925V11.8996H0.599609ZM11.3996 1.09961L11.7532 0.746056C11.5579 0.550794 11.2413 0.550794 11.0461 0.746056L11.3996 1.09961ZM17.3996 7.09961L17.7532 7.45316C17.8469 7.35939 17.8996 7.23222 17.8996 7.09961C17.8996 6.967 17.8469 6.83982 17.7532 6.74606L17.3996 7.09961ZM6.59961 17.8996V18.3996H6.80672L6.95316 18.2532L6.59961 17.8996ZM0.599609 17.8996H0.0996094C0.0996094 18.1758 0.323467 18.3996 0.599609 18.3996L0.599609 17.8996ZM0.953163 12.2532L11.7532 1.45316L11.0461 0.746056L0.246056 11.5461L0.953163 12.2532ZM11.0461 1.45316L17.0461 7.45316L17.7532 6.74606L11.7532 0.746056L11.0461 1.45316ZM17.0461 6.74606L6.24606 17.5461L6.95316 18.2532L17.7532 7.45316L17.0461 6.74606ZM6.59961 17.3996H0.599609V18.3996H6.59961V17.3996ZM1.09961 17.8996V11.8996H0.0996094V17.8996H1.09961ZM7.44606 5.05316L13.4461 11.0532L14.1532 10.3461L8.15316 4.34606L7.44606 5.05316ZM9.59961 18.3996H17.9996V17.3996H9.59961V18.3996Z"
									fill="#00DA83"
								/>
							</svg>
						</button>
					)}
					<button
						type="button"
						onClick={() => handleDelete(item.id)}
						className="table-buttons__delete-btn"
					>
						<svg
							width="15"
							height="16"
							viewBox="0 0 15 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M0.75 3.5H2.25H14.25"
								stroke="#F44336"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M4.5 3.5V2C4.5 1.60218 4.65804 1.22064 4.93934 0.93934C5.22064 0.658035 5.60218 0.5 6 0.5H9C9.39782 0.5 9.77936 0.658035 10.0607 0.93934C10.342 1.22064 10.5 1.60218 10.5 2V3.5M12.75 3.5V14C12.75 14.3978 12.592 14.7794 12.3107 15.0607C12.0294 15.342 11.6478 15.5 11.25 15.5H3.75C3.35218 15.5 2.97064 15.342 2.68934 15.0607C2.40804 14.7794 2.25 14.3978 2.25 14V3.5H12.75Z"
								stroke="#F44336"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</button>
				</div>
			</div>
		</div>
	);
};
export default WalletTableItem;
