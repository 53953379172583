/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState } from 'react';
import { TYPE_TRANSACTIONS_CONVERTS } from 'redux/reducers/transactions/constants';
import { ISearchBarProps } from './types';

const SearchBar: FC<ISearchBarProps> = ({
	setDebouncedTerm,
	debouncedTerm,
	searchFieldArray,
	searchFieldTextArray,
	searchField,
	setSearchField,
	handleClearSearch,
	typeSearch,
	dropdownArray,
	dropdownTextArray,
	inputEnter,
	inputWidth = '',
	inputStatus = false,
	tab = '',
	btnClass = '',
}) => {
	const [vueSelect, setVueSelect] = useState(false);
	const [vueDropdownSelect, setVueDropdownSelect] = useState(false);
	const [dropdown, setDropdown] = useState(dropdownArray?.[0] ?? 'all');

	const multipleFields = searchFieldArray.length > 1;

	const index = searchFieldArray.indexOf(searchField);
	const searchFieldText =
		(searchFieldTextArray?.length ?? 0) > 0
			? searchFieldTextArray?.[index] ?? searchField
			: searchField;
	const normText = (txt: string) => `${txt[0].toUpperCase()}${txt.slice(1)}`.replace('_', ' ');
	const selectBtnWidth = inputWidth ? String(inputWidth) : '100px';
	return (
		<div className="search-bar">
			<div style={{ display: 'flex' }}>
				{searchFieldArray.length > 0 && tab !== TYPE_TRANSACTIONS_CONVERTS && (
					<div className="count-label-select">
						<div className="select-block">
							<div
								className={`search-field select ${vueSelect ? 'active' : ''} ${
									btnClass ? 'btn--custom' : ''
								}`}
							>
								<button
									style={{
										width:
											inputStatus || searchField === 'p2p_nickname'
												? '155px'
												: String(selectBtnWidth),
										fontSize: multipleFields ? 'inherit' : '16px',
									}}
									type="button"
									className={multipleFields ? 'select__current' : ''}
									onClick={() => {
										if (!multipleFields) {
											return;
										}
										setVueSelect(!vueSelect);
										setVueDropdownSelect(false);
									}}
								>
									{normText(searchFieldText)}
									{multipleFields && (
										<span className="select__current-arrow">
											<svg
												width="9"
												height="5"
												viewBox="0 0 9 5"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path d="M1.5 1L4.5 4L7.5 1" stroke="#ffffff" strokeLinecap="square" />
											</svg>
										</span>
									)}
								</button>
								<div className="select__drop">
									<div className="select__drop-scroll">
										<div className="select-drop__item">
											<ul>
												{searchFieldArray.map((item, i) => {
													return (
														<li key={item} value={item}>
															<button
																type="button"
																onClick={() => {
																	setVueSelect(false);
																	if (searchField !== item) {
																		setSearchField(item);
																		handleClearSearch();
																		setDropdown('all');
																	}
																}}
															>
																{normText(searchFieldTextArray?.[i] ?? item)}
															</button>
														</li>
													);
												})}
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
				{typeSearch === 'dropdown' ? (
					<div className="count-label-select">
						<div className="select-block">
							<div className={`select input-item-with-select ${vueDropdownSelect ? 'active' : ''}`}>
								<button
									style={{ width: '230px' }}
									type="button"
									className="select__current"
									onClick={() => {
										setVueSelect(false);
										setVueDropdownSelect(!vueDropdownSelect);
									}}
								>
									{normText(dropdownTextArray?.[dropdownArray?.indexOf(dropdown) ?? 0] ?? dropdown)}
									<span className="select__current-arrow">
										<svg
											width="9"
											height="5"
											viewBox="0 0 9 5"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path d="M1.5 1L4.5 4L7.5 1" stroke="#011E26" strokeLinecap="square" />
										</svg>
									</span>
								</button>
								<div className="select__drop">
									<div className="select__drop-scroll">
										<div className="select-drop__item">
											<ul>
												{dropdownArray?.map((item, i) => {
													return (
														<li key={item} value={item}>
															<button
																type="button"
																onClick={() => {
																	setVueDropdownSelect(false);
																	setDebouncedTerm(item === 'all' ? '' : item);
																	setDropdown(item);
																	// handleClearSearch();
																}}
															>
																{normText(dropdownTextArray?.[i] ?? item)}
															</button>
														</li>
													);
												})}
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				) : (
					<div className="input input--search mb-0">
						<label>
							<div className="input-wrapper">
								<input
									className={`search-input input-item input-item--right-icon input-item--search ${
										searchFieldArray.length > 1 ? 'input-item-with-select' : ''
									} ${btnClass ? 'btn--custom' : ''}`}
									type="text"
									placeholder={inputStatus ? inputEnter : `Search by ${searchFieldText}`}
									onChange={(e) => setDebouncedTerm(e.target.value)}
									value={debouncedTerm}
									disabled={inputStatus}
								/>
								<span className="input-icon input-icon--right">
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											opacity="0.5"
											d="M23.2 23.2L16.8 16.8M10.4 20C5.09812 20 0.800049 15.702 0.800049 10.4C0.800049 5.09812 5.09812 0.800049 10.4 0.800049C15.702 0.800049 20 5.09812 20 10.4C20 15.702 15.702 20 10.4 20Z"
											stroke="#777E91"
										/>
									</svg>
								</span>
							</div>
						</label>
					</div>
				)}
			</div>
			{/* {(vueSelect || vueDropdownSelect) && (
				<div
					className="popup-window"
					onClick={() => {
						setVueSelect(false);
						setVueDropdownSelect(false);
					}}
				/>
			)} */}
		</div>
	);
};

export default SearchBar;
