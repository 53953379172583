import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import queryString from 'query-string';
import SearchBar from 'components/SearchBar';
import { getAvailableCashdesks } from 'redux/reducers/cashdesk/selectors';
import { getOperationUserRequest } from 'redux/reducers/cashdesk/reducer';
import { OPERATIONS_USER_SEARCH_ARRAY, OPERATIONS_USER_SEARCH_TEXT_ARRAY } from '../../constants';

/* eslint-disable react-hooks/exhaustive-deps */

export const SDropDown = () => {
	const dispatch = useDispatch();
	const { search } = useLocation();
	const { page, text, field } = queryString.parse(search);

	const [dropdownArray, setDropdownArray] = useState<{
		dropdownArray: string[];
		dropdownTextArray: string[];
	}>({ dropdownArray: ['user_id'], dropdownTextArray: ['User Id'] });
	const [typeSearchDropdown, setTypeSearchDropdown] = useState(false);

	const [searchField, setSearchField] = useState(field ? String(field) : 'user_id');

	const [term, setTerm] = useState('');
	const [debouncedTerm, setDebouncedTerm] = useState(term);

	useEffect(() => {
		const timer = setTimeout(() => setTerm(debouncedTerm), 800);
		return () => clearTimeout(timer);
	}, [debouncedTerm]);

	const handleClearSearch = () => {
		setDebouncedTerm('');
		setTerm('');
	};

	const handleSearch = (txt: string) => {
		if (!txt) return;
		const searchValue = String(txt).length >= 1 ? txt : undefined;
		const params = {
			search_value: searchValue,
			search_field: txt.length >= 1 ? searchField : undefined,
		};

		dispatch(getOperationUserRequest(params));
	};

	useEffect(() => {
		handleSearch(term);
	}, [term, searchField]);

	const searchFieldHandler = (txt: string) => {
		setSearchField(txt);
		setTypeSearchDropdown(false);
	};

	return (
		<div className="input-with-select input-with-select--type3">
			<SearchBar
				setDebouncedTerm={setDebouncedTerm}
				debouncedTerm={debouncedTerm}
				searchFieldArray={OPERATIONS_USER_SEARCH_ARRAY}
				searchField={searchField}
				setSearchField={searchFieldHandler}
				handleClearSearch={handleClearSearch}
				searchFieldTextArray={OPERATIONS_USER_SEARCH_TEXT_ARRAY}
				typeSearch={typeSearchDropdown ? 'dropdown' : undefined}
				dropdownArray={dropdownArray.dropdownArray}
				dropdownTextArray={dropdownArray.dropdownTextArray}
				inputWidth="120px"
				btnClass="true"
			/>
		</div>
	);
};
