import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import Configurations from 'components/CashDesk/Configurations';

// ================================================:
const CashDeskConfigurationsPage: FC = () => {
	return (
		<Dashboard title="Configurations">
			<Configurations />
		</Dashboard>
	);
};

export default CashDeskConfigurationsPage;
