import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import Dispute from 'components/Dispute';

// ================================================:
const DisputePage: FC = () => {
	return (
		<Dashboard title="Dispute">
			<Dispute />
		</Dashboard>
	);
};
export default DisputePage;
