import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import RequestedRates from 'components/CashDesk/RequestedRates';

// ================================================:
const CashdeskRequestedRatesPage: FC = () => {
	return (
		<Dashboard title="Requested rates">
			<RequestedRates />
		</Dashboard>
	);
};

export default CashdeskRequestedRatesPage;
