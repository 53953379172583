import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import FeeManagementFiat from 'components/FeeManagementFiat';

// ================================================:
const FeeManagementFiatPage: FC = () => {
	return (
		<Dashboard title="Fee management fiat">
			<FeeManagementFiat />
		</Dashboard>
	);
};

export default FeeManagementFiatPage;
