/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { noExponent, capitalizeFirstLetter } from 'utils/numberFormat';
import { getUpdateWalletstRequest, deleteWalletRequest } from 'redux/reducers/cashdesk/reducer';
import { allStatus } from 'redux/reducers/transactions/constants';
import { Link } from 'react-router-dom';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { IHistoryProps, IupdateWallets } from './types';

const HistoryTableItem: FC<IHistoryProps> = ({ item }) => {
	const [cashdesk] = useState(item?.cashdesk);
	const [type] = useState(item.operation_type);
	const [adminId] = useState(item?.admin_id);
	const [balance] = useState(item?.balance);
	const [maxAlert] = useState(item?.max_alert);
	const [minAlert] = useState(item?.min_alert);
	const [operatorId] = useState(item?.operator_id);
	const [code] = useState(item?.asset.code);
	const [initialBalance] = useState(item?.initial_balance);
	const [previousBalance] = useState(item?.previous_balance);
	// const [date] = useState(item?.created_at);
	const dispatch = useDispatch();

	const date = new Date(Number(item?.created_at) * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+Number(item?.created_at) + offset) * 1000);

	const [initialBalanceEdit, setInitialBalanceEdit] = useState(String(initialBalance));
	const [balanceEdit, setBalanceEdit] = useState(String(balance));
	const [minAlertEdit, setMinAlertEdit] = useState(String(minAlert));
	const [maxAlertEdit, setMaxAlertEdit] = useState(String(maxAlert));
	const [isClosed, setIsClosed] = useState(false);

	const { checkReadable } = usePermission();

	const handleChangeSubmit = (value: IupdateWallets) => {
		if (
			balanceEdit === '' ||
			minAlertEdit === '' ||
			maxAlertEdit === '' ||
			initialBalanceEdit === ''
		)
			return;
		dispatch(getUpdateWalletstRequest(value));
	};

	const handleDelete = (id: number) => {
		dispatch(deleteWalletRequest({ id }));
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Admin ID</p>
				<p>{adminId}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Type</p>
				<p>{type}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Cash Desk#</p>
				<p>{cashdesk.name}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Operator ID</p>
				<p>{operatorId}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Currency</p>
				<p style={{ textTransform: 'uppercase' }}>{code}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Initial balance</p>
				<p>{initialBalance || '-'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Previous Balance</p>
				<p>{previousBalance || '-'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Balance</p>

				<p>{balance || '-'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Min alert</p>

				<p>{minAlert || '-'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Max alert</p>

				<p>{maxAlert || '-'}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Date</p>
				<p>
					{dateOffset.toLocaleDateString('en-GB')} &nbsp;
					{dateOffset.toLocaleTimeString()}
				</p>
			</div>
		</div>
	);
};
export default HistoryTableItem;
