import { FC, useEffect } from 'react';
import Dashboard from 'layouts/Dashboard';
import Dashboards from 'components/CashDesk/Dashboard';
import { useDispatch } from 'react-redux';
import { getCashDeskListRequest, getActiveCurrencyRequest } from 'redux/reducers/cashdesk/reducer';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import PermissionGate from 'ui/PermissionGate';
import { routesPermissions } from 'routes/routesList';

// ==========================================:
const DashboardPage: FC = () => {
	const dispatch = useDispatch();

	// const { checkReadable } = usePermission();

	useEffect(() => {
		dispatch(getCashDeskListRequest());
		dispatch(getActiveCurrencyRequest());
	}, [dispatch]);

	return (
		<Dashboard title="Admin">
			{/* <PermissionGate
				permissions={routesPermissions.home.permissions}
				permissionsEdit={routesPermissions.home.permissionsEdit}
			> */}
			<Dashboards />
			{/* </PermissionGate> */}
		</Dashboard>
	);
};

export default DashboardPage;
