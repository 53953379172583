import { FC, useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { Field, Form, Formik } from 'formik';
import queryString from 'query-string';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
	getOperationPermissions,
	getAvailableCashdesks,
	getOperatorById,
} from 'redux/reducers/cashdesk/selectors';
import {
	editOperatorRequest,
	createOperatorRequest,
	getOperatorByIdRequest,
} from 'redux/reducers/cashdesk/reducer';
import {
	email,
	fullName,
	password,
	confirmPassword,
	cashdeskId,
	cashdeskOperatorType,
} from 'utils/formValidation';
import AdminInput from 'ui/Formik/AdminInput';
import AdminSelect from 'ui/Formik/AdminSelect';
import {
	IAddOperatorRequest,
	IUpdateOperatorRequest,
	TOperatorType,
} from 'redux/reducers/cashdesk/types';
import { IEditAdminFormValue, IEditAdminSubmitPermissionsValue } from './types';
import PermissionTableList from '../AdminManagement/CreateAdmin/PermissionTableList';

// ==========================================:
const EditAdmin = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const permissionsList = useSelector(getOperationPermissions);
	const availableCashdesks = useSelector(getAvailableCashdesks);
	const operator = useSelector(getOperatorById);
	const [passwordEmpty, setPasswordEmpty] = useState(false);
	const { page } = queryString.parse(history.location.search);
	const { id } = useParams<{ id: string }>();

	useEffect(() => {
		if (!id) return;
		dispatch(getOperatorByIdRequest(+id));
	}, [dispatch, id]);

	const types = [
		{ id: 'operator', name: 'Operator' },
		{ id: 'manager', name: 'Manager' },
	];

	const defaultCashdeskId = availableCashdesks?.find(
		(item) => item.name === operator?.cashdesk_name,
	)?.id;

	const initialValues: IEditAdminFormValue = {
		fullName: `${operator?.first_name ?? ''} ${operator?.last_name ?? ''}`,
		email: operator?.email || '',
		password: '',
		confirmPassword: '',
		cashdesk_id: defaultCashdeskId,
		type: operator?.type || 'operator',
		permissions: permissionsList?.map((e) => {
			let newEditable = false;
			let newSelected = false;
			for (let i = 0; i < (operator?.permissions?.length || 0); i += 1) {
				if (e.id === operator?.permissions[i].id) {
					newEditable = Boolean(operator?.permissions[i].pivot.editable || false);
					newSelected = true;
				}
			}
			return {
				id: e.id,
				editable: newEditable,
				selected: newSelected,
			};
		}) ?? [{ id: 1, editable: false, selected: false }],
	};
	const validationSchema = yup.object().shape({
		email,
		fullName,
		cashdesk_id: cashdeskId,
		type: cashdeskOperatorType,
	});
	const validationSchemaPassword = yup.object().shape({
		email,
		fullName,
		password,
		confirmPassword,
		cashdesk_id: cashdeskId,
		type: cashdeskOperatorType,
	});

	const valueChange = (values: any) => {
		if (values.password === '' && values.confirmPassword === '') {
			setPasswordEmpty(true);
		} else {
			setPasswordEmpty(false);
		}
	};

	const createAdminSubmit = (values: IEditAdminFormValue): void => {
		const nameArray = values.fullName.trim().split(' ');
		const newPermissions = [] as IEditAdminSubmitPermissionsValue[];
		let j = 0;
		for (let i = 0; i < values.permissions.length; i += 1) {
			if (values.permissions[i].selected) {
				newPermissions[j] = {
					id: values.permissions[i].id,
					editable: values.permissions[i].editable,
				};
				j += 1;
			}
		}

		const newValue: IAddOperatorRequest = {
			first_name: nameArray?.[0] ?? '',
			last_name: nameArray?.[1] ?? '',
			email: values.email,
			permissions: newPermissions,
			cashdesk_id: values.cashdesk_id,
			type: values.type as TOperatorType,
			password: values.password,
		};

		const editValue: IUpdateOperatorRequest = {
			operator_id: +id,
			first_name: nameArray?.[0] ?? '',
			last_name: nameArray?.[1] ?? '',
			email: values.email,
			permissions: newPermissions,
			cashdesk_id: values.cashdesk_id,
			type: values.type as TOperatorType,
		};

		if (id && !passwordEmpty) {
			editValue.password = values.password;
		}

		dispatch(
			id
				? editOperatorRequest({
						data: editValue,
						history,
				  })
				: createOperatorRequest({
						data: newValue,
						history,
				  }),
		);
	};
	const handleCancelCreate = (): void => {
		history.push({
			pathname: '/cash-desk/operators',
			search: `?page=${String(page || 1)}`,
		});
	};

	const [isShowPass, setIsShowPass] = useState(false);
	const [isShowRepeatPass, setIsShowRepeatPass] = useState(false);

	return (
		<>
			<div className="title-block">
				<button type="button" className="back-btn" onClick={() => history.goBack()}>
					<svg
						className="fill"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M13.9534 7.5536L14.3069 7.20004L13.5998 6.49294L13.2463 6.84649L13.9534 7.5536ZM8.79981 12L8.44625 11.6465L8.0927 12L8.44625 12.3536L8.79981 12ZM13.2463 17.1536L13.5998 17.5071L14.3069 16.8L13.9534 16.4465L13.2463 17.1536ZM13.2463 6.84649L8.44625 11.6465L9.15336 12.3536L13.9534 7.5536L13.2463 6.84649ZM8.44625 12.3536L13.2463 17.1536L13.9534 16.4465L9.15336 11.6465L8.44625 12.3536ZM11.9998 22.7C6.09035 22.7 1.2998 17.9095 1.2998 12H0.299805C0.299804 18.4618 5.53807 23.7 11.9998 23.7V22.7ZM22.6998 12C22.6998 17.9095 17.9092 22.7 11.9998 22.7V23.7C18.4615 23.7 23.6998 18.4618 23.6998 12H22.6998ZM11.9998 1.30005C17.9093 1.30005 22.6998 6.0906 22.6998 12H23.6998C23.6998 5.53832 18.4615 0.30005 11.9998 0.300049V1.30005ZM11.9998 0.300049C5.53807 0.300048 0.299806 5.53831 0.299805 12H1.2998C1.29981 6.0906 6.09036 1.30005 11.9998 1.30005V0.300049Z"
							fill="#25262D"
						/>
					</svg>
				</button>
				<p className="title">{id ? 'Edit User' : 'Create New User'}</p>
			</div>
			<Formik
				initialValues={initialValues}
				validationSchema={
					!id || (id && !passwordEmpty) ? validationSchemaPassword : validationSchema
				}
				onSubmit={(values: IEditAdminFormValue, { setSubmitting }) => {
					createAdminSubmit(values);
					setSubmitting(false);
				}}
				validateOnBlur
				enableReinitialize
			>
				{({ isSubmitting, isValid, dirty, values, setFieldValue, errors }) => {
					valueChange(values);
					return (
						<Form>
							<div className="create-operator-form">
								<div className="forms-block">
									<div className="form">
										<p className="block-title">Name</p>
										<div className="form__content">
											<div className="inputs-two-column inputs-two-column--type2">
												<div className="input-group">
													<Field
														title="Full name"
														type="text"
														placeholder="Full name"
														name="fullName"
														required
														component={AdminInput}
														fieldIcon={<span className="input-icon icon-user-managment-icon" />}
													/>
													<Field
														title="Email address"
														type="email"
														placeholder="Email address"
														name="email"
														required
														component={AdminInput}
														fieldIcon={<span className="input-icon icon-mail-icon" />}
													/>
												</div>
												<div className="input-group">
													<Field
														title="Cash Desk"
														type="text"
														placeholder="Cash Desk"
														name="cashdesk_id"
														required
														component={AdminSelect}
														options={availableCashdesks}
														keyProp="id"
														renderOption={({ item, onClick = () => {} }: any) => (
															<button type="button" onClick={onClick}>
																{item.name}
															</button>
														)}
														renderSelected={(v: number | string) =>
															availableCashdesks?.find((item) => item.id === v)?.name
														}
														disabled={!!id}
														fieldIcon={<span className="input-icon icon-user-managment-icon" />}
													/>
													<Field
														title="Type"
														type="text"
														placeholder="Full name"
														name="type"
														required
														options={types}
														keyProp="id"
														renderOption={({ item, onClick = () => {} }: any) => (
															<button type="button" onClick={onClick}>
																{item.name}
															</button>
														)}
														renderSelected={(v: number | string) =>
															types?.find((item) => item.id === v)?.name
														}
														component={AdminSelect}
														disabled={!!id}
														fieldIcon={<span className="input-icon icon-user-managment-icon" />}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="forms-block">
									<div className="form">
										<p className="block-title">Password</p>
										<div className="form__content">
											<Field
												title="Password"
												type={isShowPass ? 'text' : 'password'}
												placeholder="Password"
												name="password"
												required
												component={AdminInput}
												fieldIcon={<span className="input-icon icon-password-icon" />}
												fieldRightIcon={
													<span
														className={`input-icon input-icon--right 
														input-icon-cursor-pointer ${isShowPass ? 'icon-eye-icon' : 'icon-eye-password-icon'}`}
														onClick={() => setIsShowPass(!isShowPass)}
													/>
												}
											/>
											<Field
												title="Repeat Password"
												type={isShowRepeatPass ? 'text' : 'password'}
												placeholder="Repeat Password"
												name="confirmPassword"
												required
												component={AdminInput}
												fieldIcon={<span className="input-icon icon-password-icon" />}
												fieldRightIcon={
													<span
														className={`input-icon input-icon--right input-icon-cursor-pointer ${
															isShowRepeatPass ? 'icon-eye-icon' : 'icon-eye-password-icon'
														}`}
														onClick={() => setIsShowRepeatPass(!isShowRepeatPass)}
													/>
												}
											/>
										</div>
									</div>
								</div>
							</div>
							<PermissionTableList
								data={permissionsList || []}
								setFieldValue={setFieldValue}
								permissions={values.permissions}
							/>
							<div className="table-footer">
								<button
									className="button button--right button--transparent"
									type="button"
									onClick={handleCancelCreate}
								>
									Cancel
								</button>
								<button
									className="button button--right"
									type="submit"
									// disabled={!(isValid && dirty) || isSubmitting}
								>
									Save
								</button>
							</div>
						</Form>
					);
				}}
			</Formik>
		</>
	);
};

export default EditAdmin;
