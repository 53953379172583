import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';

import { noExponent } from 'utils/numberFormat';
import { notificationContainer } from 'utils/notificationContainer';
import { allStatus } from 'redux/reducers/transactions/constants';
import { setTabMenuUserIndex } from 'redux/reducers/userManagement/reducer';
import { ReactComponent as CopyIcon } from 'assets/img/icons/copy-to-clipboard.svg';
import { ICryptoDepositProps } from './types';

const CryptoWithdrawalTableItem: FC<ICryptoDepositProps> = ({ item }) => {
	const dispatch = useDispatch();
	const {
		id,
		network,
		fee,
		address,
		tx_url: txUrl,
		hash,
		amount,
		created_at: created,
		memo,
	} = item;
	const date = new Date(created * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+created + offset) * 1000);

	const handleAddressCopy = () => {
		notificationContainer('Transaction url copied successfully!', 'info');
	};
	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">ID</p>
				<p>{id}</p>
			</div>
			{/* <div className="td td--left">
				<Link
					onClick={() => {
						dispatch(setTabMenuUserIndex(0));
					}}
					to={`/user-management/${String(userId)}`}
				>
					<p className="td-hidden-name">User ID</p>
					<p>{userId}</p>
				</Link>
			</div> */}
			<div className="td">
				<p className="td-hidden-name">Asset</p>
				<p style={{ textTransform: 'uppercase' }}>{network === undefined ? '-' : network}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Value</p>
				<p>{noExponent(Number(amount).toString())}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Fee</p>
				<p>{noExponent(Number(fee).toString())}</p>
			</div>
			<div
				style={{
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
				}}
				className="td"
			>
				<p className="td-hidden-name">TX link</p>
				<p className="copy-button">
					<span className="copy-button__text">{hash}</span>

					{txUrl && (
						<CopyToClipboard text={txUrl} onCopy={handleAddressCopy}>
							<button type="button" className="copy-button__item">
								<CopyIcon />
							</button>
						</CopyToClipboard>
					)}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">MEMO code</p>
				<p>{memo}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Created</p>
				<p>
					{dateOffset.toLocaleDateString('en-GB')} <span className="custom-dot"> • </span>
					{dateOffset.toLocaleTimeString()}
				</p>
			</div>

			<div className="td td--right">
				<p className="td-hidden-name">Status</p>
				<div className="status-wrapper">
					<span className={`status ${allStatus?.[item?.status]?.statusClass ?? ''}`}>
						{allStatus?.[item?.status]?.statusText}
					</span>
				</div>
			</div>
		</div>
	);
};
export default CryptoWithdrawalTableItem;
