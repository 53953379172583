/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { useDispatch, useSelector } from 'react-redux';
import { IAddWalletPayload, IAvailableCurrenciesItem } from 'redux/reducers/cashdesk/types';
import {
	getAvailCashdesksRequest,
	getAvailableOperatorsRequest,
	getAddWalleyRequest,
	getWalletCurrenciesRequest,
} from 'redux/reducers/cashdesk/reducer';
import {
	getAvailableCashdesks,
	getAvailableOperators,
	getAvailableWalletCurrencies,
} from 'redux/reducers/cashdesk/selectors';
import { IItemCashDesk } from 'ui/WalletMDropdown/types';
import WalletMDropdown from 'ui/WalletMDropdown';
import CDropdownCurrencies from 'ui/CDropdownCurrencies';
import { IModalProps } from './types';
import { SuccessPopup } from '../SuccessPopup/SuccessPopup';

// ================================================:
export const AddWallet: FC<IModalProps> = ({ openModal, closeModal, onSuccessed }) => {
	const cashdesks = useSelector(getAvailableCashdesks);
	const operators = useSelector(getAvailableOperators);
	const currencies = useSelector(getAvailableWalletCurrencies);
	const dispatch = useDispatch();
	const [initialBalance, setinitialBalance] = useState('');
	const [loaderBtn, setLoaderBtn] = useState(false);
	const [selectCashDesk, setSelectCashDesk] = useState<IItemCashDesk | null>(null);
	const [selectOperator, setSelectOperator] = useState<IItemCashDesk | null>(null);
	const [currency, setSelectCurrency] = useState<IAvailableCurrenciesItem | null>(null);
	const [confirmModal, setConfirmModal] = useState(false);
	const [minAlert, setMinAlert] = useState('');
	const [maxAlert, setMaxAlert] = useState('');

	const disable =
		!selectCashDesk ||
		!selectOperator ||
		!currency ||
		!minAlert ||
		!maxAlert ||
		!initialBalance ||
		loaderBtn;

	useEffect(() => {
		dispatch(getAvailCashdesksRequest());
	}, [dispatch]);

	useEffect(() => {
		if (!selectCashDesk) return;
		dispatch(getAvailableOperatorsRequest({ cashdesk_id: selectCashDesk.id }));
		dispatch(getWalletCurrenciesRequest({ cashdesk_id: selectCashDesk.id }));
	}, [selectCashDesk]);

	const handleSubmit = () => {
		if (!selectCashDesk?.id || !selectOperator?.id || !currency?.id) return;
		const params = {
			cashdesk_id: Number(selectCashDesk.id),
			operator_id: Number(selectOperator?.id),
			asset_id: currency?.cashdesk_asset_id,
			initial_balance: Number(initialBalance),
			min_alert: Number(minAlert),
			max_alert: Number(maxAlert),
		};
		setLoaderBtn(true);
		const onSuccess = () => {
			setConfirmModal(true);
			setLoaderBtn(false);
		};
		const payload: IAddWalletPayload = {
			params: { ...params },
			onSuccess,
			onError: () => {
				setLoaderBtn(false);
			},
		};
		dispatch(getAddWalleyRequest(payload));
	};

	const handleSelect = (value: IItemCashDesk): void => {
		setSelectCashDesk(value);
		setSelectCurrency(null);
		setSelectOperator(null);
	};

	const handleSelectCurrency = (value: IAvailableCurrenciesItem): void => {
		setSelectCurrency(value);
	};

	const handleSelectOperators = (value: IItemCashDesk): void => {
		setSelectOperator(value);
	};

	return (
		<Popup open={openModal} closeOnDocumentClick onClose={closeModal} closeOnEscape>
			{confirmModal ? (
				<SuccessPopup
					cashdesk={selectCashDesk}
					currency={currency}
					opertortId={selectOperator}
					initialBalance={initialBalance}
					minAlert={minAlert}
					maxAlert={maxAlert}
					onSubmit={() => {
						closeModal();
						setConfirmModal(false);
						setSelectCashDesk(null);
						setSelectCurrency(null);
						setSelectOperator(null);
					}}
					onBack={() => closeModal()}
				/>
			) : (
				<div className="popup-window">
					<div className="popup-window__inside">
						<div className="popup ">
							<div className="popup-header">
								<p className="popup-header__title">Wallet Operations</p>
							</div>
							<div className="popup-body popup-body--type2">
								<div className="input ">
									<p className="input__name">Cash desk</p>
									<WalletMDropdown
										options={cashdesks}
										value={selectCashDesk}
										onChange={handleSelect}
										placeholder="Select cash desk"
										type="cashdesk"
									/>
								</div>
								<div className="input ">
									<p className="input__name">Operator name</p>
									<WalletMDropdown
										options={operators}
										value={selectOperator}
										onChange={handleSelectOperators}
										placeholder="Select operator name"
										type="operator"
									/>
								</div>
								<div className="input ">
									<p className="input__name">Currency</p>
									<CDropdownCurrencies
										options={currencies || []}
										value={currency}
										onChange={handleSelectCurrency}
										placeholder="Select currency"
									/>
								</div>
								<div className="input ">
									<p className="input__name">Initial Balance</p>
									<div className="input-wrapper">
										<input
											className="input-item"
											value={initialBalance}
											onChange={(e) => setinitialBalance(e.currentTarget.value)}
											type="text"
											placeholder="Enter starting balance"
										/>
									</div>
								</div>

								<div className="input-row-wrap">
									<p className="input-name input-name--type2">Min/Max alerts</p>
									<div className="inputs-two-column inputs-two-column--gap-10 inputs-two-column--type2">
										<div className="input ">
											<div className="input-wrapper">
												<input
													className="input-item input-item--right-icon"
													type="text"
													placeholder="Min alerts"
													value={minAlert}
													onChange={(e) => {
														setMinAlert(e.currentTarget.value);
													}}
												/>
												<div className="input-info">
													<span className="input-info__currency">
														{currency?.asset?.code?.toUpperCase()}
													</span>
												</div>
											</div>
										</div>
										<div className="input ">
											<div className="input-wrapper">
												<input
													className="input-item input-item--right-icon"
													type="text"
													value={maxAlert}
													placeholder="Max alerts"
													onChange={(e) => {
														setMaxAlert(e.currentTarget.value);
													}}
												/>
												<div className="input-info">
													<span className="input-info__currency">
														{currency?.asset?.code?.toUpperCase()}
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="popup-submit popup-submit--type2">
								<button
									onClick={closeModal}
									type="button"
									className="button button--cancel button--full-width "
								>
									Cancel
								</button>
								<button
									type="button"
									onClick={() => handleSubmit()}
									className="button button--green-type  button--full-width "
									disabled={disable}
								>
									Confirm
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</Popup>
	);
};
