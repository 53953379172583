import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import WalletManagement from 'components/CashDesk/WalletManagement';

// ================================================:
const CashdeskWalletManagementPage: FC = () => {
	return (
		<Dashboard title="Wallet Management">
			<WalletManagement />
		</Dashboard>
	);
};

export default CashdeskWalletManagementPage;
