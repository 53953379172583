import { createSlice, PayloadAction } from '@reduxjs/toolkit';
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	ICashdeskStore,
	ICashdeskListSuccessPayload,
	IOperatorsRequest,
	IOperatorsResponse,
	IOperator,
	TAvaliableCashdesksResponse,
	TOperatorPermissionsResponse,
	IEditOperatorPayload,
	ICreateOperatorPayload,
	IDeleteOperatorPayload,
	IAddCashdesksPayload,
	IAddCurrencyCashdesksPayload,
	IREmoveCurrencyCashdesksPayload,
	IRemoveCashdesksPayload,
	IRemoveCurrencyPayload,
	IWalletManagementPayload,
	IWalletManagementResponse,
	IChangeWalletPayload,
	IWalletDeletePyload,
	IHistoryManagementResponse,
	IOperatorsPayload,
	IAvailableOperatorsResponse,
	INotificationsResponse,
	ISpread,
	IEditSpreadPayload,
	ICurrency,
	IEditNotificationRequest,
	INotification,
	IEditCurrencyStatusRequest,
	IAddWalletPayload,
	IHostoryOperatorsResponse,
	IGetAvailableCurrenciesPayload,
	IAvailableOperationCurrenciesItem,
	IGetOperationCurrenciesPayload,
	IGetOperationSpreadPayload,
	IGetOperationSpreadResponse,
	IGetOperationUserPayload,
	IGetOperationUserResponse,
	IGetOperationRatePayload,
	IGetOperationRateResponse,
	IOperationsRequest,
	IOperationsResponse,
	IOperationCurrenciesFromTo,
	ICreateOperationPayload,
	ICheckNetworkPayload,
	IRequestedRatesParams,
	IRequestedRatesResponse,
	IAcceptRatePayload,
	IRejectRatePayload,
	IContinueCreateOperationPayload,
} from './types';

const initialState: ICashdeskStore = {
	cashdeskListLoader: false,
	cashdeskData: null,
	operatorsLoading: false,
	operatorsList: null,
	operatorByIdLoading: false,
	operatorById: null,
	availableCashdesks: null,
	operationPermissionsLoading: false,
	operationPermissions: null,
	editOperatorLoader: false,
	deleteOperatorLoader: false,
	activeCurrency: null,
	activeCurrencyLoader: false,
	walletsList: null,
	walletsListLoader: false,
	historyList: null,
	historyListLoader: false,
	operators: null,
	notifications: null,
	notificationsLoading: false,
	editNotificationLoading: false,
	spreads: null,
	spreadsLoading: false,
	editSpreadLoading: false,
	currencies: null,
	currenciesLoading: false,
	editCurrencyLoading: false,
	operatorsHistoryList: null,
	availableWalletCurrency: null,
	availableOpertaionCurrencyFrom: null,
	availableOpertaionCurrencyTo: null,
	operationSpred: null,
	operationUser: null,
	operationRate: null,
	operationsLoading: false,
	operationsExportLoading: false,
	operationsList: null,
	requestedRatesLoading: false,
	exportRequestedRatesLoading: false,
	requestedRates: null,
	acceptRateLoading: false,
	rejectRateLoading: false,
};

const cashdesk = createSlice({
	name: '@@backofficeBalance',
	initialState,
	reducers: {
		getCashDeskListRequest: (state, action: PayloadAction) => {
			const cashdeskListState = state;

			cashdeskListState.cashdeskListLoader = true;
		},

		setCashDeskListSuccess: (state, action: PayloadAction<ICashdeskListSuccessPayload>) => {
			const { payload } = action;
			const cashdeskListSuccessState = state;

			cashdeskListSuccessState.cashdeskData = payload;
			cashdeskListSuccessState.cashdeskListLoader = false;
		},
		getCashExportRequest: (state, action: PayloadAction) => {
			const cashdeskListState = state;
		},
		getOperatorsRequest: (state, { payload }: PayloadAction<IOperatorsRequest>) => {
			const cashdeskState = state;

			if (payload.export !== 1) {
				cashdeskState.operatorsLoading = true;
			}
		},

		getOperatorsSuccess: (state, action: PayloadAction<IOperatorsResponse>) => {
			const cashdeskState = state;
			const { payload } = action;

			cashdeskState.operatorsLoading = false;
			cashdeskState.operatorsList = payload;
		},

		getOperatorsError: (state) => {
			const cashdeskState = state;

			cashdeskState.operatorsLoading = false;
		},

		getOperatorByIdRequest: (state, { payload }: PayloadAction<number>) => {
			const cashdeskState = state;

			cashdeskState.operatorByIdLoading = true;
		},

		getOperatorByIdSuccess: (state, action: PayloadAction<IOperator>) => {
			const cashdeskState = state;
			const { payload } = action;

			cashdeskState.operatorByIdLoading = false;
			cashdeskState.operatorById = payload;
		},

		getOperatorByIdError: (state) => {
			const cashdeskState = state;

			cashdeskState.operatorsLoading = false;
		},

		resetOperatorById: (state) => {
			const cashdeskState = state;

			cashdeskState.operatorById = null;
		},

		getAvailCashdesksRequest: (state) => {
			const cashdeskState = state;
		},

		getAvailCashdesksSuccess: (state, action: PayloadAction<TAvaliableCashdesksResponse>) => {
			const cashdeskState = state;
			const { payload } = action;

			cashdeskState.availableCashdesks = payload;
		},

		getOperatorPermissionsRequest: (state) => {
			const cashdeskState = state;

			cashdeskState.operationPermissionsLoading = true;
		},

		getOperatorPermissionsSuccess: (state, action: PayloadAction<TOperatorPermissionsResponse>) => {
			const cashdeskState = state;
			const { payload } = action;

			cashdeskState.operationPermissionsLoading = false;
			cashdeskState.operationPermissions = payload;
		},

		getOperatorPermissionsError: (state) => {
			const cashdeskState = state;

			cashdeskState.operationPermissionsLoading = false;
		},

		editOperatorRequest: (state, action: PayloadAction<IEditOperatorPayload>) => {
			const editAdminState = state;

			editAdminState.editOperatorLoader = true;
		},
		editOperatorFinally: (state) => {
			const editAdminState = state;

			editAdminState.editOperatorLoader = false;
		},

		createOperatorRequest: (state, action: PayloadAction<ICreateOperatorPayload>) => {
			const editAdminState = state;

			editAdminState.editOperatorLoader = true;
		},
		createOperatorFinally: (state) => {
			const editAdminState = state;

			editAdminState.editOperatorLoader = false;
		},

		deleteOperatorRequest: (state, action: PayloadAction<IDeleteOperatorPayload>) => {
			const editAdminState = state;

			editAdminState.deleteOperatorLoader = true;
		},

		deleteOperatorFinally: (state) => {
			const editAdminState = state;

			editAdminState.deleteOperatorLoader = false;
		},

		getActiveCurrencyRequest: (state, action: PayloadAction) => {
			const cashdeskState = state;

			cashdeskState.activeCurrencyLoader = true;
		},

		setActiveCurrencySuccess: (state, action: PayloadAction<any>) => {
			const { payload } = action;
			const cashdeskSuccessState = state;

			cashdeskSuccessState.activeCurrency = payload;
			cashdeskSuccessState.activeCurrencyLoader = false;
		},
		createCashDeskRequest: (state, action: PayloadAction<IAddCashdesksPayload>) => {
			const cashdeskState = state;
		},

		createCashDeskRequestSuccess: (state, action: PayloadAction<any>) => {
			const { payload } = action;
			const cashdeskSuccessState = state;
		},

		addCurrencyCashDesktRequest: (state, action: PayloadAction<IAddCurrencyCashdesksPayload>) => {
			const cashdeskState = state;
		},
		deleteCashDesktRequest: (state, action: PayloadAction<IRemoveCashdesksPayload>) => {
			const cashdeskState = state;
		},
		deleteCurrencytRequest: (state, action: PayloadAction<IREmoveCurrencyCashdesksPayload>) => {
			const cashdeskState = state;
		},
		deleteCurrencySuccess: (state, action: PayloadAction<IRemoveCurrencyPayload>) => {
			const cashdeskState = state.cashdeskData;
			const { cashdesk_id: cashdeskId, asset_id: assetId } = action.payload;
			if (!state.cashdeskData) return;
			const newCashdesk = state.cashdeskData.cashdesks.find((item) => item.id === cashdeskId);
			if (newCashdesk) {
				newCashdesk.balances = newCashdesk.balances.filter(
					(balance) => balance.asset_id !== assetId,
				);
			}
		},
		getWalletsListRequest: (state, { payload }: PayloadAction<IWalletManagementPayload>) => {
			const walletListState = state;
			if (payload.export !== 1) {
				walletListState.walletsListLoader = true;
			}
		},
		getWalletsListSuccess: (state, action: PayloadAction<IWalletManagementResponse>) => {
			const walletListState = state;

			walletListState.walletsList = action.payload;
			walletListState.walletsListLoader = false;
		},
		getUpdateWalletstRequest: (state, action: PayloadAction<IChangeWalletPayload>) => {
			const walletListState = state;

			// walletState.walletsListLoader = true;
		},
		getAvailableOperatorsRequest: (state, action: PayloadAction<IOperatorsPayload>) => {
			const operatorsState = state;

			operatorsState.cashdeskListLoader = true;
		},
		getAvailableOperatorsSuccess: (state, action: PayloadAction<IAvailableOperatorsResponse>) => {
			const operatorsState = state;
			const { payload } = action;

			operatorsState.operators = payload;
		},
		getWalletOperationListRequest: (state, action: PayloadAction<any>) => {
			const cashdeskListState = state;
		},
		getWalletOperationListSuccess: (state, action: PayloadAction<IHostoryOperatorsResponse>) => {
			const cashdeskListState = state;

			cashdeskListState.operatorsHistoryList = action.payload;
		},
		getWalletHistoryRequest: (state, { payload }: PayloadAction<IWalletManagementPayload>) => {
			const cashdeskListState = state;
			if (payload.export !== 1) {
				cashdeskListState.historyListLoader = true;
			}
		},
		getHistoryListSuccess: (state, action: PayloadAction<IHistoryManagementResponse>) => {
			const historyListState = state;

			historyListState.historyList = action.payload;
			historyListState.historyListLoader = false;
		},
		getAddWalleyRequest: (state, action: PayloadAction<IAddWalletPayload>) => {
			const cashdeskListState = state;
		},
		deleteWalletRequest: (state, action: PayloadAction<IWalletDeletePyload>) => {
			const cashdeskState = state;
		},
		getNotificationsRequest: (state) => {
			const cashdeskState = state;
			cashdeskState.notificationsLoading = true;
		},

		getNotificationsSuccess: (state, action: PayloadAction<INotificationsResponse>) => {
			const cashdeskState = state;
			const { payload } = action;

			cashdeskState.notificationsLoading = false;
			cashdeskState.notifications = payload;
		},
		getNotificationsError: (state) => {
			const cashdeskState = state;

			cashdeskState.notificationsLoading = false;
		},
		editNotificationRequest: (state, action: PayloadAction<IEditNotificationRequest>) => {
			const cashdeskState = state;

			cashdeskState.editNotificationLoading = true;
		},
		editNotificationSuccess: (state, { payload }: PayloadAction<INotification>) => {
			const cashdeskState = state;

			cashdeskState.editNotificationLoading = false;

			if (!cashdeskState.notifications?.notifications) {
				return;
			}

			cashdeskState.notifications.notifications = cashdeskState.notifications?.notifications?.map(
				(item) => {
					if (item.id === payload.id) {
						return {
							...item,
							email_enabled: item.email_enabled,
						};
					}
					return item;
				},
			);
		},
		editNotificationError: (state) => {
			const cashdeskState = state;

			cashdeskState.editNotificationLoading = false;
		},
		getSpreadsRequest: (state) => {
			const cashdeskState = state;

			cashdeskState.spreadsLoading = true;
		},
		getSpreadsSuccess: (state, { payload }: PayloadAction<ISpread[]>) => {
			const cashdeskState = state;

			cashdeskState.spreadsLoading = false;
			cashdeskState.spreads = payload;
		},
		getSpreadsError: (state) => {
			const cashdeskState = state;

			cashdeskState.spreadsLoading = false;
		},
		editSpreadRequest: (state, action: PayloadAction<IEditSpreadPayload>) => {
			const cashdeskState = state;

			cashdeskState.editSpreadLoading = true;
		},
		editSpreadSuccess: (state, { payload }: PayloadAction<ISpread>) => {
			const cashdeskState = state;

			cashdeskState.editSpreadLoading = false;

			if (!cashdeskState.spreads) {
				return;
			}

			cashdeskState.spreads = cashdeskState.spreads?.map((item) => {
				if (item.id === payload.id) {
					return {
						...item,
						first_asset_spread: payload.first_asset_spread,
						second_asset_spread: payload.second_asset_spread,
					};
				}
				return item;
			});
		},
		editSpreadError: (state) => {
			const cashdeskState = state;

			cashdeskState.editSpreadLoading = false;
		},
		getCurrenciesRequest: (state) => {
			const cashdeskState = state;

			cashdeskState.currenciesLoading = true;
		},
		getCurrenciesSuccess: (state, { payload }: PayloadAction<ICurrency[]>) => {
			const cashdeskState = state;

			cashdeskState.currenciesLoading = false;
			cashdeskState.currencies = payload;
		},
		getWalletCurrenciesRequest: (state, action: PayloadAction<IGetAvailableCurrenciesPayload>) => {
			const cashdeskState = state;
		},
		getWalletCurrenciesSuccess: (state, { payload }: PayloadAction<any>) => {
			const cashdeskState = state;

			cashdeskState.availableWalletCurrency = payload;
		},
		getCurrenciesError: (state) => {
			const cashdeskState = state;

			cashdeskState.currenciesLoading = false;
		},
		editCurrencyStatusRequest: (state, action: PayloadAction<IEditCurrencyStatusRequest>) => {
			const cashdeskState = state;

			cashdeskState.editCurrencyLoading = true;
		},
		editCurrencyStatusSuccess: (state, { payload }: PayloadAction<ICurrency>) => {
			const cashdeskState = state;

			cashdeskState.editCurrencyLoading = false;

			if (!cashdeskState.currencies) {
				return;
			}

			cashdeskState.currencies = cashdeskState.currencies?.map((item) => {
				if (item.id === payload.id) {
					return {
						...item,
						active: item.active,
					};
				}
				return item;
			});
		},
		editCurrencyStatusError: (state) => {
			const cashdeskState = state;

			cashdeskState.editCurrencyLoading = false;
		},
		getOperationsRequest: (state, { payload }: PayloadAction<IOperationsRequest>) => {
			const cashdeskState = state;

			if (payload.export === 1) {
				cashdeskState.operationsExportLoading = true;
			} else {
				cashdeskState.operationsLoading = true;
			}
		},
		getOperationsSuccess: (state, { payload }: PayloadAction<IOperationsResponse>) => {
			const cashdeskState = state;

			cashdeskState.operationsLoading = false;
			cashdeskState.operationsExportLoading = false;
			cashdeskState.operationsList = payload;
		},
		getOperationsError: (state) => {
			const cashdeskState = state;

			cashdeskState.operationsLoading = false;
		},
		setOperationsExportLoading: (state, { payload }: PayloadAction<boolean>) => {
			const cashdeskState = state;

			cashdeskState.operationsExportLoading = payload;
		},
		getOperationCurrenciesRequest: (
			state,
			action: PayloadAction<IGetOperationCurrenciesPayload>,
		) => {
			const cashdeskState = state;
		},
		getOperationCurrenciesSuccess: (
			state,
			{ payload }: PayloadAction<IOperationCurrenciesFromTo>,
		) => {
			const cashdeskState = state;

			if (payload.from) {
				cashdeskState.availableOpertaionCurrencyFrom = payload.data;
			} else {
				cashdeskState.availableOpertaionCurrencyTo = payload.data;
			}
		},
		getOperationCurrenciesInitial: (state) => {
			const cashdeskState = state;
			cashdeskState.availableOpertaionCurrencyFrom = null;
			cashdeskState.availableOpertaionCurrencyTo = null;
		},
		getOperationSpredRequest: (state, action: PayloadAction<IGetOperationSpreadPayload>) => {
			const cashdeskState = state;
		},
		getOperationSpredSuccess: (state, { payload }: PayloadAction<IGetOperationSpreadResponse>) => {
			const cashdeskState = state;
			cashdeskState.operationSpred = payload;
		},
		getOperationUserRequest: (state, action: PayloadAction<IGetOperationUserPayload>) => {
			const cashdeskState = state;
		},

		getOperationUserSuccess: (state, { payload }: PayloadAction<IGetOperationUserResponse>) => {
			const cashdeskState = state;
			cashdeskState.operationUser = payload;
		},

		getOperationUserInitial: (state) => {
			const cashdeskState = state;
			cashdeskState.operationUser = null;
		},
		getOperationRateRequest: (state, action: PayloadAction<IGetOperationRatePayload>) => {
			const cashdeskState = state;
		},
		getOperationRateSuccess: (state, { payload }: PayloadAction<IGetOperationRateResponse>) => {
			const cashdeskState = state;
			cashdeskState.operationRate = payload;
		},
		createOperationRequest: (state, action: PayloadAction<ICreateOperationPayload>) => {
			const cashdeskState = state;
		},
		createOperationSuccess: (state, { payload }: PayloadAction<IGetOperationRateResponse>) => {
			const cashdeskState = state;
			cashdeskState.operationRate = payload;
		},
		getCheckAddressRequest: (state, action: PayloadAction<ICheckNetworkPayload>) => {
			const cashdeskState = state;
		},
		getRequestedRatesRequest: (state, { payload }: PayloadAction<IRequestedRatesParams>) => {
			const cashdeskState = state;

			if (payload.export === 1) {
				cashdeskState.exportRequestedRatesLoading = true;
			} else {
				cashdeskState.requestedRatesLoading = true;
			}
		},
		getRequestedRatesSuccess: (state, { payload }: PayloadAction<IRequestedRatesResponse>) => {
			const cashdeskState = state;

			cashdeskState.requestedRatesLoading = false;
			cashdeskState.requestedRates = payload;
		},
		getRequestedRatesError: (state) => {
			const cashdeskState = state;
			cashdeskState.requestedRatesLoading = false;
		},
		setExportRatesLoading: (state, { payload }: PayloadAction<boolean>) => {
			const cashdeskState = state;

			cashdeskState.exportRequestedRatesLoading = payload;
		},

		acceptRequestedRateRequest: (state, action: PayloadAction<IAcceptRatePayload>) => {
			const cashdeskState = state;

			cashdeskState.acceptRateLoading = true;
		},

		acceptRequestedRateSuccess: (state, { payload }: PayloadAction<{ id: number }>) => {
			const { id } = payload;
			const cashdeskState = state;

			cashdeskState.acceptRateLoading = false;
			if (!cashdeskState.requestedRates?.data) return;
			cashdeskState.requestedRates.data = cashdeskState.requestedRates.data.filter(
				(item) => item.id !== id,
			);
		},

		acceptRequestedRateError: (state) => {
			const cashdeskState = state;

			cashdeskState.acceptRateLoading = false;
		},

		rejectRequestedRateRequest: (state, action: PayloadAction<IRejectRatePayload>) => {
			const cashdeskState = state;

			cashdeskState.rejectRateLoading = true;
		},

		rejectRequestedRateSuccess: (state, { payload }: PayloadAction<{ id: number }>) => {
			const { id } = payload;
			const cashdeskState = state;

			cashdeskState.rejectRateLoading = false;
			if (!cashdeskState.requestedRates?.data) return;
			cashdeskState.requestedRates.data = cashdeskState.requestedRates.data.filter(
				(item) => item.id !== id,
			);
		},

		rejectRequestedRateError: (state) => {
			const cashdeskState = state;

			cashdeskState.rejectRateLoading = false;
		},
		createOperationContinueRequest: (
			state,
			action: PayloadAction<IContinueCreateOperationPayload>,
		) => {
			const cashdeskState = state;
		},

		cashDeskListInitState: () => initialState,
	},
});

export default cashdesk.reducer;

export const {
	getCashDeskListRequest,
	setCashDeskListSuccess,
	cashDeskListInitState,
	getCashExportRequest,
	getOperatorsRequest,
	getOperatorsSuccess,
	getOperatorsError,
	getAvailCashdesksRequest,
	getAvailCashdesksSuccess,
	getOperatorPermissionsRequest,
	getOperatorPermissionsSuccess,
	getOperatorPermissionsError,
	getOperatorByIdRequest,
	getOperatorByIdSuccess,
	getOperatorByIdError,
	resetOperatorById,
	editOperatorRequest,
	editOperatorFinally,
	createOperatorRequest,
	createOperatorFinally,
	deleteOperatorRequest,
	deleteOperatorFinally,
	getActiveCurrencyRequest,
	setActiveCurrencySuccess,
	createCashDeskRequest,
	createCashDeskRequestSuccess,
	addCurrencyCashDesktRequest,
	deleteCashDesktRequest,
	deleteCurrencytRequest,
	deleteCurrencySuccess,
	getWalletsListRequest,
	getWalletsListSuccess,
	getUpdateWalletstRequest,
	deleteWalletRequest,
	getWalletHistoryRequest,
	getHistoryListSuccess,
	getAvailableOperatorsRequest,
	getAvailableOperatorsSuccess,
	getNotificationsRequest,
	getNotificationsSuccess,
	getNotificationsError,
	editNotificationRequest,
	editNotificationSuccess,
	editNotificationError,
	getSpreadsRequest,
	getSpreadsSuccess,
	getSpreadsError,
	editSpreadRequest,
	editSpreadSuccess,
	editSpreadError,
	getCurrenciesRequest,
	getCurrenciesSuccess,
	getCurrenciesError,
	editCurrencyStatusRequest,
	editCurrencyStatusSuccess,
	editCurrencyStatusError,
	getAddWalleyRequest,
	getWalletOperationListRequest,
	getWalletOperationListSuccess,
	getWalletCurrenciesRequest,
	getWalletCurrenciesSuccess,
	getOperationsRequest,
	getOperationsSuccess,
	getOperationsError,
	setOperationsExportLoading,
	getOperationUserRequest,
	getOperationUserSuccess,
	getOperationUserInitial,
	getOperationCurrenciesRequest,
	getOperationCurrenciesSuccess,
	getOperationCurrenciesInitial,
	getOperationSpredRequest,
	getOperationRateRequest,
	getOperationRateSuccess,
	getOperationSpredSuccess,
	createOperationRequest,
	createOperationSuccess,
	getCheckAddressRequest,
	getRequestedRatesRequest,
	getRequestedRatesSuccess,
	getRequestedRatesError,
	setExportRatesLoading,
	acceptRequestedRateRequest,
	acceptRequestedRateSuccess,
	acceptRequestedRateError,
	rejectRequestedRateSuccess,
	rejectRequestedRateRequest,
	rejectRequestedRateError,
	createOperationContinueRequest,
} = cashdesk.actions;
