import { FC } from 'react';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { IOperationTableRow } from './types';

const formatType = (type: string) => {
	const [first, second, third] = type.split('_');
	return `${capitalizeFirstLetter(first)}/${capitalizeFirstLetter(second)}${
		third ? `/${capitalizeFirstLetter(third)}` : ''
	}`;
};

const getStatusClass = (status: string) => {
	switch (status) {
		case 'completed':
			return 'status--verified';
		case 'on_hold':
			return 'status--on-hold';
		case 'rejected':
			return 'status--rejected';
		case 'processing':
			return 'status--grey';
		default:
			return '';
	}
};

const OperationTableRow: FC<IOperationTableRow> = ({ item, onChange }) => {
	const {
		id,
		cashdesk_name: cashdeskName,
		operator_id: operatorId,
		type,
		username,
		personal_code: personalCode,
		user_id: userId,
		created_at: createdAt,
		actual_rate: actualRate,
		requested_rate: requestedRate,
		from_asset_code: fromAssetCode,
		to_asset_code: toAssetCode,
		amount_from: amountFrom,
		amount_to: amountTo,
		reject_comment: rejectComment,
		requested_rate_status: requestedRateStatus,
		message,
		network,
		tx_hash: txHash,
		comment,
		status,
	} = item;

	const date = new Date(createdAt * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+createdAt + offset) * 1000);

	const { checkReadable } = usePermission();

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">ID</p>
				<p>{id}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Account</p>
				<p>{cashdeskName}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Operator ID</p>
				<p>{operatorId}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Transaction type</p>
				<p>{type && formatType(type)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Full name</p>
				<p>{username}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Personal Number</p>
				<p>{personalCode}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">User ID</p>
				<p>
					{userId != null && checkReadable(permissions.MANAGE_USERS) ? (
						<a href="/">{userId}</a>
					) : (
						userId
					)}{' '}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Date</p>
				<p>
					{dateOffset.toLocaleDateString('en-GB')}{' '}
					{dateOffset.toLocaleTimeString(undefined, { hour12: false })}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Actual Rate</p>
				<p>{actualRate}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Requested Rate</p>
				<p>{requestedRate}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">New Rate</p>
				{/* <p></p> */}
			</div>
			<div className="td">
				<p className="td-hidden-name">Admin ID</p>
				<p>{operatorId}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">From</p>
				<p>{fromAssetCode?.toUpperCase()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">To</p>
				<p>{toAssetCode?.toUpperCase()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Amount from</p>
				<p>{amountFrom}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Amount To</p>
				<p>{amountTo}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Network</p>
				<p>{network?.toUpperCase()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Hash tx</p>
				<p>{txHash}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Transfered</p>
				<div className="copy-button">
					<span className="copy-button__text">{/* TODO: Спросить что за поле */}</span>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Comment</p>
				<div className="hint-block">
					<div className="hint-block__text">
						<p>{comment}</p>
					</div>
					<div className="hint-item">
						<p>{comment}</p>
					</div>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Rate comment</p>
				<div className="hint-block">
					<div className="hint-block__text">
						<p>{rejectComment}</p>
					</div>
					<div className="hint-item">
						<p>{rejectComment}</p>
					</div>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">System message</p>
				<div className="hint-block">
					<div className="hint-block__text">
						<p>{message}</p>
					</div>
					<div className="hint-item">
						<p>{message}</p>
					</div>
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Status</p>
				<div className="status-block">
					<span className={`status ${getStatusClass(status || '')}`}>
						{status && capitalizeFirstLetter(status)}
					</span>
					{status && requestedRateStatus === 'accepted' && status === 'processing' ? (
						<button type="button" onClick={() => onChange(item)} className="status-action">
							<svg
								width="14"
								height="14"
								viewBox="0 0 14 14"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M7.58268 13.2707H5.24935C2.08185 13.2707 0.728516 11.9173 0.728516 8.74984V5.24984C0.728516 2.08234 2.08185 0.729004 5.24935 0.729004H8.74935C11.9168 0.729004 13.2702 2.08234 13.2702 5.24984V7.58317C13.2702 7.82234 13.0718 8.02067 12.8327 8.02067C12.5935 8.02067 12.3952 7.82234 12.3952 7.58317V5.24984C12.3952 2.56067 11.4385 1.604 8.74935 1.604H5.24935C2.56018 1.604 1.60352 2.56067 1.60352 5.24984V8.74984C1.60352 11.439 2.56018 12.3957 5.24935 12.3957H7.58268C7.82185 12.3957 8.02018 12.594 8.02018 12.8332C8.02018 13.0723 7.82185 13.2707 7.58268 13.2707Z"
									fill="#777E91"
								/>
								<path
									d="M7.58268 13.2707H5.24935C2.08185 13.2707 0.728516 11.9173 0.728516 8.74984V5.24984C0.728516 2.08234 2.08185 0.729004 5.24935 0.729004H8.74935C11.9168 0.729004 13.2702 2.08234 13.2702 5.24984V7.58317C13.2702 7.82234 13.0718 8.02067 12.8327 8.02067C12.5935 8.02067 12.3952 7.82234 12.3952 7.58317V5.24984C12.3952 2.56067 11.4385 1.604 8.74935 1.604H5.24935C2.56018 1.604 1.60352 2.56067 1.60352 5.24984V8.74984C1.60352 11.439 2.56018 12.3957 5.24935 12.3957H7.58268C7.82185 12.3957 8.02018 12.594 8.02018 12.8332C8.02018 13.0723 7.82185 13.2707 7.58268 13.2707Z"
									fill="#777E91"
								/>
								<path
									d="M4.08398 8.56928C3.84482 8.56928 3.64648 8.37094 3.64648 8.13178V6.25928C3.64648 6.02011 3.84482 5.82178 4.08398 5.82178C4.32315 5.82178 4.52148 6.02011 4.52148 6.25928V8.13178C4.52148 8.37678 4.32315 8.56928 4.08398 8.56928Z"
									fill="#777E91"
								/>
								<path
									d="M9.91602 8.56928C9.67685 8.56928 9.47852 8.37094 9.47852 8.13178V6.25928C9.47852 6.02011 9.67685 5.82178 9.91602 5.82178C10.1552 5.82178 10.3535 6.02011 10.3535 6.25928V8.13178C10.3535 8.37678 10.1552 8.56928 9.91602 8.56928Z"
									fill="#777E91"
								/>
								<path
									d="M7.58268 13.2707H5.24935C2.08185 13.2707 0.728516 11.9173 0.728516 8.74984V5.24984C0.728516 2.08234 2.08185 0.729004 5.24935 0.729004H8.74935C11.9168 0.729004 13.2702 2.08234 13.2702 5.24984V7.58317C13.2702 7.82234 13.0718 8.02067 12.8327 8.02067C12.5935 8.02067 12.3952 7.82234 12.3952 7.58317V5.24984C12.3952 2.56067 11.4385 1.604 8.74935 1.604H5.24935C2.56018 1.604 1.60352 2.56067 1.60352 5.24984V8.74984C1.60352 11.439 2.56018 12.3957 5.24935 12.3957H7.58268C7.82185 12.3957 8.02018 12.594 8.02018 12.8332C8.02018 13.0723 7.82185 13.2707 7.58268 13.2707Z"
									fill="#777E91"
								/>
								<path
									d="M10.2544 12.6877C10.1378 12.6877 10.0269 12.6411 9.94527 12.5594L9.0236 11.6436C8.85444 11.4744 8.8486 11.1944 9.0236 11.0252C9.19277 10.8561 9.47277 10.8502 9.64194 11.0252L10.2311 11.6086L11.9228 9.63106C12.0803 9.44439 12.3544 9.42689 12.5411 9.58439C12.7219 9.74189 12.7453 10.0161 12.5878 10.2027L10.5928 12.5361C10.5111 12.6294 10.4003 12.6819 10.2778 12.6877C10.2661 12.6877 10.2603 12.6877 10.2544 12.6877Z"
									fill="#777E91"
								/>
								<path
									d="M7 8.3125C6.76083 8.3125 6.5625 8.11417 6.5625 7.875V6.125C6.5625 5.88583 6.76083 5.6875 7 5.6875C7.23917 5.6875 7.4375 5.88583 7.4375 6.125V7.875C7.4375 8.11417 7.23917 8.3125 7 8.3125Z"
									fill="#777E91"
								/>
							</svg>
						</button>
					) : (
						''
					)}
				</div>
			</div>
		</div>
	);
};

export default OperationTableRow;
