export const RATES_SEARCH_ARRAY = [
	'operation_id',
	'operation_type',
	'operator_id',
	'user_id',
	'currency',
	'full_name',
	'personal_number',
];

export const RATES_SEARCH_TEXT_ARRAY = [
	'operation ID',
	'Operation type',
	'Operator ID',
	'User ID',
	'Currency',
	'Full name',
	'Personal number',
];

export const OPERATION_TYPE_ARRAY = [
	'all',
	'deposit_convert',
	'convert_cashout',
	'deposit_convert',
	'transfer',
];

export const OPERATION_TYPE_TEXT_ARRAY = [
	'All',
	'Deposit/Convert',
	'Convert/Cashout',
	'Deposit/Convert',
	'Transfer',
];
