import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import CashDeskOperation from 'components/CashDesk/Operation';

// ================================================:
const CashdeskOperationPage: FC = () => {
	return (
		<Dashboard title="Operation">
			<CashDeskOperation />
		</Dashboard>
	);
};

export default CashdeskOperationPage;
