/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState, useEffect, ChangeEvent } from 'react';
import { notificationContainer } from 'utils/notificationContainer';
import Popup from 'reactjs-popup';
import { useDispatch, useSelector } from 'react-redux';
import {
	ICreateOperationPayload,
	IAvailableCurrenciesItem,
	IAvailableNetwork,
} from 'redux/reducers/cashdesk/types';
import { currentDateUTC } from 'utils/dateAndTimeHelpers';
import {
	getOperationRateRequest,
	getOperationSpredRequest,
	getOperationCurrenciesRequest,
	createOperationRequest,
	getCheckAddressRequest,
} from 'redux/reducers/cashdesk/reducer';
import { getUserData } from 'redux/reducers/auth/selectors';
import {
	getOperationsCurrenciesTo,
	getOperationsUser,
	getOperationsCurrenciesFrom,
	getOperationsRate,
	getOperationsSpred,
} from 'redux/reducers/cashdesk/selectors';
import { IItemCashDesk } from 'ui/WalletMDropdown/types';
import WalletMDropdown from 'ui/WalletMDropdown';
import CDropdownCurrencies from 'ui/CDropdownCurrencies';
import { IModalProps } from './types';
import { SuccessPopup } from '../SuccessPopup/SuccessPopup';
import { SDropDown } from '../../DropDowns/SDropDown';
import NDropdown from '../../DropDowns/NDropdown';

// ================================================:
export const ContinueOperation: FC<IModalProps> = ({
	openModal,
	closeModal,
	item,
	onConfirm,
	successRequest,
}) => {
	const userData = useSelector(getUserData);
	return (
		<Popup open={openModal} closeOnDocumentClick onClose={closeModal} closeOnEscape>
			<SuccessPopup
				user={item || null}
				userData={userData}
				edit="edit"
				currencyFrom={String(item?.from_asset_code)}
				currencyTo={String(item?.to_asset_code)}
				opertortId={item?.id}
				fromAmount={Number(item?.amount_from)}
				toAmount={Number(item?.amount_to)}
				// address={address}
				// network={currentNetwork?.network_name}
				date={String(currentDateUTC(true))}
				onSubmit={() => {
					onConfirm?.(Number(item?.id), String(currentDateUTC(true)));
				}}
				onBack={() => closeModal()}
				successRequest={successRequest}
			/>
		</Popup>
	);
};
