import { FC } from 'react';
import Popup from 'reactjs-popup';
import { IDeleteAdminProps } from './types';

// ================================================:
const DeleteOperatorModal: FC<IDeleteAdminProps> = ({
	openModal,
	closeModal,
	handleDeleteAdmin,
}) => {
	return (
		<Popup
			open={openModal}
			closeOnDocumentClick
			onClose={closeModal}
			closeOnEscape
			overlayStyle={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
		>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup popup--medium">
						<div className="popup-body">
							<div className="popup-text popup-text--center popup-text--bigger">
								<p>Are you sure you want to delete?</p>
							</div>
							<div className="popup-submit popup-submit--sb popup-submit--column">
								<button
									className="button button--wider button--del button--full"
									type="button"
									onClick={closeModal}
								>
									Cancel
								</button>
								<button
									className="button button--wider button--text"
									type="button"
									onClick={handleDeleteAdmin}
								>
									Yes, I&apos;m sure
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default DeleteOperatorModal;
