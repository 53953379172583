/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import AdminFormConrol from 'ui/Formik/AdminFormConrol';
import { useSelect } from 'services/hooks/useSelect';
import { useFormikContext } from 'formik';
import { IAdminSelect } from './types';

// ==========================================:
const AdminSelect: FC<IAdminSelect> = (props) => {
	const {
		ariaLabel,
		type,
		field,
		placeholder,
		inputMode,
		autoFocus,
		options,
		renderSelected,
		renderOption,
		keyProp = 'id',
		disabled,
	} = props;

	const { values, setFieldValue } = useFormikContext<any>();

	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();

	const handleSelect = (item: string | number) => {
		setFieldValue(field.name, item);
		setOpen(false);
	};

	return (
		<AdminFormConrol ariaLabel={ariaLabel} {...props}>
			<div className={`select select--height-50 ${open ? 'active' : ''}`}>
				<button
					type="button"
					className="select__current select__current--fs-16"
					ref={triggerRef}
					onClick={toggleOpen}
					disabled={disabled}
				>
					<span className="select__current-text">
						{renderSelected ? renderSelected(values[field.name]) : values[field.name]}
					</span>
					<span className="select__current-arrow">
						<svg
							className="stroke"
							width="8"
							height="5"
							viewBox="0 0 8 5"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="M1 1L4 4L7 1" stroke="#424242" strokeLinecap="square" />
						</svg>
					</span>
				</button>
				<div className="select__drop" ref={dropRef}>
					<div className="select__drop-scroll">
						<ul>
							{options?.map((item: any) => (
								<li key={String(item[keyProp])}>
									{renderOption ? (
										renderOption({ item, onClick: () => handleSelect(item[keyProp]) })
									) : (
										<button type="button">{typeof item === 'string' && item}</button>
									)}
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</AdminFormConrol>
	);
};

export default AdminSelect;
