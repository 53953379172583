import { FC } from 'react';

import { noExponent } from 'utils/numberFormat';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { IOpenOrdersProps } from './types';

const SpotOpenOrders: FC<IOpenOrdersProps> = ({ item }) => {
	const {
		id,
		created_at: createdAt,
		pair,
		type,
		price,
		quantity,
		filled,
		total,
		trigger_conditions: triggerConditions,
	} = item;
	const date = new Date(createdAt * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+createdAt + offset) * 1000);

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">ID</p>
				{id}
			</div>
			<div className="td">
				<p className="td-hidden-name">Date</p>
				<p>{dateOffset.toLocaleDateString('en-GB')}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Pair</p>
				<p>{pair?.replace('_', '/').toUpperCase()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Type</p>
				<p>{triggerConditions ? 'Stop-Limit' : 'Limit'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Side</p>
				<p>{type && capitalizeFirstLetter(type)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Price</p>
				<p>{price !== null ? noExponent(Number(price).toString()) : '-'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Amount</p>
				<p>{quantity && noExponent(Number(quantity))}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Filled</p>
				<p>{filled && noExponent(filled)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Total</p>
				<p>{total && noExponent(total)}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Trigger conditions</p>
				<p>{triggerConditions ? `${type === 'sell' ? '<=' : '>='} ${triggerConditions}` : '-'}</p>
			</div>
		</div>
	);
};

export default SpotOpenOrders;
