import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { History } from 'history';

import adminManagement from 'redux/reducers/adminManagement/reducer';
import balance from 'redux/reducers/balance/reducer';
import settings from 'redux/reducers/settings/reducer';
import app from 'redux/reducers/app/reducer';
import auth from 'redux/reducers/auth/reducer';
import userManagement from 'redux/reducers/userManagement/reducer';
import fees from 'redux/reducers/fees/reducer';
import fiatFees from 'redux/reducers/fiatFees/reducer';
import withdrawals from 'redux/reducers/withdrawals/reducer';
import notifications from 'redux/reducers/notifications/reducer';
import tradingPairs from 'redux/reducers/tradingPairs/reducer';
import walletManagement from 'redux/reducers/walletManagement/reducer';
import tokenManagement from 'redux/reducers/tokenManagement/reducer';
import marketMakerBot from 'redux/reducers/marketMakerBot/reducer';
import limitations from 'redux/reducers/limitations/reducer';
import transactions from 'redux/reducers/transactions/reducer';
import trades from 'redux/reducers/trade/reducer';
import orders from 'redux/reducers/orders/reducer';
import dispute from 'redux/reducers/dispute/reducer';
import currencies from 'redux/reducers/сurrencies/reducer';
import sockets from 'redux/reducers/sockets/reducer';
import chat from 'redux/reducers/chat/reducer';
import bankAccount from 'redux/reducers/bankAccount/reducer';
import externalMaker from 'redux/reducers/externalMaker/reducer';
import translates from 'redux/reducers/translates/reducer';
import referral from 'redux/reducers/referral/reducer';
import staking from 'redux/reducers/staking/reducer';
import marketBot from 'redux/reducers/marketBot/reducer';
import unidentifiedDeposits from 'redux/reducers/unidentifiedDeposits/reducer';
import pendingDeposits from 'redux/reducers/pendingDeposits/reducer';
import cashdesk from 'redux/reducers/cashdesk/reducer';
// ==========================================:
const createRootReducer = (history: History) =>
	combineReducers({
		router: connectRouter(history),
		loadingBar: loadingBarReducer,
		adminManagement,
		balance,
		settings,
		app,
		auth,
		userManagement,
		fees,
		fiatFees,
		withdrawals,
		notifications,
		tradingPairs,
		walletManagement,
		tokenManagement,
		marketMakerBot,
		limitations,
		transactions,
		trades,
		orders,
		dispute,
		currencies,
		sockets,
		chat,
		bankAccount,
		externalMaker,
		translates,
		referral,
		staking,
		marketBot,
		unidentifiedDeposits,
		pendingDeposits,
		cashdesk,
	});

export default createRootReducer;
