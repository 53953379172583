import { IAdminsListPermissionsItem } from 'redux/reducers/adminManagement/types';
import { routesPermissions } from './routesList';

export const isRouteAvailable = (path: string, permissions: IAdminsListPermissionsItem[]) => {
	const allPermissionNames = permissions.map((item) => item.name);
	const routePermissionNames = Object.values(routesPermissions).find(
		(item) => item.path === path,
	)?.permissions;
	return routePermissionNames?.every((item) => allPermissionNames.includes(item));
};

export const getFirstAvaliablePath = (permissions: IAdminsListPermissionsItem[]) => {
	const permissionNames = permissions.map((item) => item.name);
	return Object.values(routesPermissions).find((item) =>
		item.permissions?.every((item2) => permissionNames.includes(item2)),
	)?.path;
};
