import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import Withdrawals from 'components/Withdrawals';

// ================================================:
const PendingDepositsPage: FC = () => {
	return (
		<Dashboard title="Pending deposits">
			<Withdrawals />
		</Dashboard>
	);
};

export default PendingDepositsPage;
