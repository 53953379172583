import { FC, useEffect, useRef, useState } from 'react';
import { IActiveCurrency } from 'redux/reducers/cashdesk/types';
import { IDCDropdown } from './types';

// ==================================:
const CashDeskDropdown: FC<IDCDropdown> = (props) => {
	const {
		value,
		options,
		placeholder = '',
		onChange,
		currentClass,
		onBloor,
		disabled,
		handleDelete,
	} = props;

	const [open, setOpen] = useState(false);
	const node = useRef<HTMLDivElement | null>(null);

	const handleClick = (event: MouseEvent): boolean | undefined => {
		if (node?.current?.contains(event.target as Node)) {
			return false;
		}

		setOpen(false);
		return true;
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClick);

		return () => {
			document.removeEventListener('mousedown', handleClick);
		};
	}, []);

	const coincideCurrency = (val: IActiveCurrency) => {
		const coincide = value && value.find((id) => id === val.id);
		return coincide;
	};

	const handleChange = (selectedValue: IActiveCurrency): void => {
		if (disabled) {
			return;
		}
		if (coincideCurrency(selectedValue)) return;
		onChange(selectedValue);
		// setOpen(false);
	};

	const handleSelectToggel = (e: React.MouseEvent<HTMLButtonElement>): void => {
		const target = e.target as HTMLElement;

		if (target.closest('.select-option-label')) {
			return;
		}

		setOpen(!open);
	};
	const currencyIsCheck = (val: number) => {
		const isCheck = value?.find((id) => id === val);
		return isCheck;
	};

	const checkCurrency = (id: number) => {
		const code = options?.find((item) => Number(item.id) === Number(id))?.code?.toUpperCase();
		return code;
	};

	return (
		<div
			className={`select select--coin-list select--line-options select--multiple-options ${
				currentClass || ''
			}${open ? 'active' : ''} ${disabled ? 'select--disabled' : ''}`}
			ref={node}
		>
			<button
				type="button"
				onClick={handleSelectToggel}
				onBlur={onBloor}
				// disabled={disabled}
				className="select-multiple-options select__current"
			>
				<div className="select-multiple-options__list">
					{value?.map((id) => {
						return (
							<div className="select-option-label">
								<span className="select-option-label__text">{checkCurrency(id)}</span>
								<button
									onClick={() => handleDelete(id)}
									type="button"
									className="d-flex select-option-label__delete"
								>
									<svg
										width="10"
										height="10"
										viewBox="0 0 10 10"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="M1 1L9 9M1 9L9 1" stroke="#777E90" strokeWidth="1.5" />
									</svg>
								</button>
							</div>
						);
					})}
				</div>
				<span className="select__current-arrow">
					<svg
						className="stroke"
						width="8"
						height="5"
						viewBox="0 0 8 5"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M1 1L4 4L7 1" stroke="#424242" strokeLinecap="square" />
					</svg>
				</span>
			</button>
			{open && options && (
				<div className="select__drop">
					<div className="select__drop-scroll">
						<ul>
							{options?.map((opt: IActiveCurrency) => {
								return (
									<li key={opt?.id} className={`${currencyIsCheck(opt.id) ? 'active' : ''}`}>
										<button type="button" onClick={() => handleChange(opt)} disabled={disabled}>
											<span className="select-coin">
												<img width="30" height="30" src={opt?.img_path} alt="" />
											</span>
											{opt.code.toUpperCase()}
											<span className="select-extra-text">{opt.name}</span>
											<span className="select-check ">
												<svg
													width="16"
													height="12"
													viewBox="0 0 16 12"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M14.6673 1L5.50065 10.1667L1.33398 6"
														stroke="#03BC70"
														strokeWidth="2"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											</span>
										</button>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			)}
		</div>
	);
};

export default CashDeskDropdown;
