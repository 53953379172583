import { FC, useEffect } from 'react';
import Dashboard from 'layouts/Dashboard';
import AdminPanel from 'components/AdminPanel';
import { useDispatch } from 'react-redux';
import {
	getBalanceListRequest,
	getHotBalanceListRequest,
	getTotalFeeCollectedListRequest,
} from 'redux/reducers/balance/reducer';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import PermissionGate from 'ui/PermissionGate';
import { routesPermissions } from 'routes/routesList';

// ==========================================:
const Home: FC = () => {
	const dispatch = useDispatch();

	const { checkReadable } = usePermission();

	useEffect(() => {
		dispatch(getBalanceListRequest());
		if (checkReadable(permissions.MANAGE_WALLETS)) {
			dispatch(getHotBalanceListRequest());
		}
		if (checkReadable(permissions.VIEW_PROFITS)) {
			dispatch(getTotalFeeCollectedListRequest());
		}
	}, [checkReadable, dispatch]);

	return (
		<Dashboard title="Admin">
			<PermissionGate
				permissions={routesPermissions.home.permissions}
				permissionsEdit={routesPermissions.home.permissionsEdit}
			>
				<AdminPanel />
			</PermissionGate>
		</Dashboard>
	);
};

export default Home;
