/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { navList } from 'routes/routesList';
import { Link } from 'react-router-dom';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import Pagination from 'ui/Pagination';
import { ExportIcon } from 'assets/img/icons';
import { getOperatorsRequest } from 'redux/reducers/cashdesk/reducer';
import { IOperatorsRequest, TOperatorSearchField } from 'redux/reducers/cashdesk/types';
import { getOperators, getAvailableCashdesks } from 'redux/reducers/cashdesk/selectors';
import SearchBar from '../SearchBar';
import OperatorsTable from './OperatorsTable';

const CashdeskPermissions = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const { search } = useLocation();
	const { checkEditable } = usePermission();

	const operators = useSelector(getOperators);
	const cashDesks = useSelector(getAvailableCashdesks);

	const searchFieldArray = ['user_id', 'cashdesk_id'];
	const searchFieldTextArray = ['User ID', 'Сash Desk'];

	const { page, text, field } = queryString.parse(search);
	const [searchField, setSearchField] = useState(field ? String(field) : searchFieldArray[0]);

	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);

	const [dropdownArray, setDropdownArray] = useState<{
		dropdownArray: string[];
		dropdownTextArray: string[];
	}>({ dropdownArray: ['all'], dropdownTextArray: ['All'] });

	const [typeSearchDropdown, setTypeSearchDropdown] = useState(false);

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		let verifyText = '';
		if (text) {
			verifyText = typeof text === 'string' ? text : String(text);
		}

		const searchParams =
			verifyText.length > 0
				? `?page=${String(pageNumber)}${verifyText && `&text=${verifyText}&field=${searchField}`}`
				: `?page=${pageNumber}`;

		const params: IOperatorsRequest =
			verifyText.length > 0
				? {
						current_page: pageNumber || 1,
						search_field: searchField as TOperatorSearchField,
						search_value: verifyText,
				  }
				: {
						current_page: pageNumber || 1,
				  };
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});

		dispatch(getOperatorsRequest(params));
	};

	const handleSearch = (txt: string) => {
		const params = {
			search_value: txt,
			search_field: searchField as TOperatorSearchField,
		};

		if (txt.length >= 1) {
			dispatch(getOperatorsRequest(params));
		} else {
			dispatch(getOperatorsRequest({ current_page: 1 }));
		}
		history.push({
			pathname: location.pathname,
			search: txt.length >= 1 ? `?page=${String(page || 1)}&text=${txt}&field=${searchField}` : ``,
		});
		setCurrentPage(txt.length >= 1 ? Number(page || 1) : 1);
	};

	const [term, setTerm] = useState('');
	const [debouncedTerm, setDebouncedTerm] = useState(term);

	useEffect(() => {
		setCurrentPage(Number(page) || 1);
	}, [page]);

	useEffect(() => {
		const timer = setTimeout(() => setTerm(debouncedTerm), 800);
		return () => clearTimeout(timer);
	}, [debouncedTerm]);

	useEffect(() => {
		handleSearch(term);
	}, [term, searchField]);

	const handleClearSearch = () => {
		setDebouncedTerm('');
		setTerm('');
	};

	const searchFieldHandler = (txt: string) => {
		if (txt === 'cashdesk_id') {
			setDropdownArray({
				dropdownArray: ['all', ...(cashDesks?.map((item) => String(item.id)) || [])],
				dropdownTextArray: ['All', ...(cashDesks?.map((item) => item.name) || [])],
			});
			setSearchField(txt);
			setTypeSearchDropdown(true);
			return;
		}

		setSearchField(txt);
		setTypeSearchDropdown(false);
	};

	const handleExport = () => {
		const searchValue = text && text.length >= 1 ? String(text) : undefined;
		const params: IOperatorsRequest = {
			current_page: Number(page) || 1,
			export: 1,
			search_value: searchValue,
			search_field: text && text.length >= 1 ? (searchField as TOperatorSearchField) : undefined,
		};
		dispatch(getOperatorsRequest(params));
	};

	return (
		<>
			<div className="title-block">
				<p className="title">Management of Permissions</p>
			</div>
			<div className="count-label count-label--filter-type">
				<SearchBar
					inputWidth="120px"
					setDebouncedTerm={setDebouncedTerm}
					debouncedTerm={debouncedTerm}
					searchFieldArray={searchFieldArray}
					searchField={searchField}
					setSearchField={searchFieldHandler}
					handleClearSearch={handleClearSearch}
					searchFieldTextArray={searchFieldTextArray}
					typeSearch={typeSearchDropdown ? 'dropdown' : undefined}
					dropdownArray={dropdownArray.dropdownArray}
					dropdownTextArray={dropdownArray.dropdownTextArray}
				/>
				<button type="button" className="transaction-export-btn" onClick={handleExport}>
					Export users
					<span className="transaction-export-btn__icon">
						<ExportIcon />
					</span>
				</button>
			</div>
			<div className="content-block">
				<div className="content-block__inside content-block__inside--type2">
					<div className="content-panel content-panel--content-right">
						{checkEditable(permissions.CASHDESK_PERMISSION_MANAGEMENT) && (
							<Link
								to={navList.cashDeskPermissionsNew.path}
								className="button button--green-type button--width-auto"
							>
								Add new role
							</Link>
						)}
					</div>
					<div className="table-block table-block--mt-24">
						<OperatorsTable />
					</div>
					{(operators?.total ?? 1) > 1 && (
						<Pagination
							pageCount={operators?.last_page ?? 1}
							forcePage={currentPage - 1}
							onPageChange={handlePageChange}
						/>
					)}
				</div>
			</div>
		</>
	);
};

export default CashdeskPermissions;
