import { FC, useRef } from 'react';
import { PDFExport } from '@progress/kendo-react-pdf';
import PDFFileDownload from '../PDFFileDownload';
import { IPropsPopup } from './type';

// ================================================:
export const SuccessPopup: FC<IPropsPopup> = ({
	userData,
	user,
	opertortId,
	currencyFrom,
	currencyTo,
	fromAmount,
	toAmount,
	edit,
	date,
	address,
	network,
	onSubmit,
	successRequest,
	onBack,
}) => {
	const pdfExportComponent = useRef<PDFExport>(null);

	const handleDownload = () => {
		if (pdfExportComponent.current) {
			pdfExportComponent.current.save();
		}
	};
	const newData = {
		userData,
		user,
		opertortId,
		currencyFrom,
		currencyTo,
		fromAmount,
		toAmount,
		date,
		address,
		network,
	};
	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className={`popup ${address ? 'popup--width-560' : ''}`}>
					<button type="button" className="popup__close" onClick={onBack}>
						<svg
							width="18"
							height="18"
							viewBox="0 0 18 18"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M15 3L3 15"
								stroke="#777E91"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M3 3L15 15"
								stroke="#777E91"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</button>
					<div className="popup-header">
						<p className="popup-header__title">
							{successRequest ? 'Operation created succesfully' : 'Create Operation'}
						</p>
						{successRequest ? (
							''
						) : (
							<div className="popup-text popup-text--mt-8 popup-text--center">
								<p>Are you sure you want to create transaction</p>
							</div>
						)}
					</div>
					<div className="popup-body popup-body--type2">
						<div className="withdrawal-options ">
							<div className="withdrawal-option">
								<span className="withdrawal-option__title">User ID</span>
								<span className="withdrawal-option__desc">{user?.id}</span>
							</div>
							<div className="withdrawal-option">
								<span className="withdrawal-option__title">User name</span>
								<span className="withdrawal-option__desc">{user?.username}</span>
							</div>
							<div className="withdrawal-option">
								<span className="withdrawal-option__title">Amount From</span>
								<span className="withdrawal-option__desc">
									{fromAmount} {currencyFrom?.toUpperCase()}
								</span>
							</div>
							<div className="withdrawal-option">
								<span className="withdrawal-option__title">Amount To </span>
								<span className="withdrawal-option__desc">
									{toAmount} {currencyTo?.toUpperCase()}
								</span>
							</div>
						</div>
						<div className="withdrawal-options ">
							<div className="withdrawal-option">
								<span className="withdrawal-option__title">Cash Desk Name</span>
								<span className="withdrawal-option__desc">
									{edit === 'edit' ? user?.cashdesk_name : userData?.cashdesk_name}
								</span>
							</div>
							<div className="withdrawal-option">
								<span className="withdrawal-option__title">Operator ID</span>
								<span className="withdrawal-option__desc">
									{edit === 'edit' ? user?.operator_id : userData?.id}
								</span>
							</div>
							{address ? (
								<>
									<div className="withdrawal-option">
										<span className="withdrawal-option__title">Address</span>
										<span className="withdrawal-option__desc">{address}</span>
									</div>
									<div className="withdrawal-option">
										<span className="withdrawal-option__title">Network</span>
										<span className="withdrawal-option__desc">{network}</span>
									</div>
								</>
							) : (
								''
							)}

							<div className="withdrawal-option">
								<span className="withdrawal-option__title">Personal Number</span>
								<span className="withdrawal-option__desc">{user?.personal_code}</span>
							</div>
							<div className="withdrawal-option">
								<span className="withdrawal-option__title">Date</span>
								<span className="withdrawal-option__desc">{date}</span>
							</div>
						</div>
					</div>
					<div className="popup-submit popup-submit--type2">
						<button
							type="button"
							onClick={handleDownload}
							className="button button--outline button--full-width "
						>
							Print now
						</button>
						{successRequest ? (
							<button
								type="button"
								onClick={onBack}
								className="button button--check button--full-width"
							>
								Okey
							</button>
						) : (
							<button
								type="button"
								onClick={onSubmit}
								className="button button--check button--full-width"
							>
								Confirm
							</button>
						)}
					</div>
				</div>
			</div>
			<div style={{ position: 'fixed', left: '-10000' }}>
				<PDFExport ref={pdfExportComponent} paperSize="A4">
					<PDFFileDownload data={newData} />
				</PDFExport>
			</div>
		</div>
	);
};
