import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import queryString from 'query-string';
import {
	getRequestedRatesLoading,
	getExportRequestedRatesLoading,
	getRequestedRates,
} from 'redux/reducers/cashdesk/selectors';
import { getRequestedRatesRequest } from 'redux/reducers/cashdesk/reducer';
import { IRequestedRatesParams } from 'redux/reducers/cashdesk/types';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import { ExportIcon } from 'assets/img/icons';
import SearchBar from 'components/SearchBar';
import {
	RATES_SEARCH_ARRAY,
	RATES_SEARCH_TEXT_ARRAY,
	OPERATION_TYPE_ARRAY,
	OPERATION_TYPE_TEXT_ARRAY,
} from './constants';
import TableHeader from './TableHeader';
import TableRow from './TableRow';

const RequestedRates = () => {
	const dispatch = useDispatch();
	const loading = useSelector(getRequestedRatesLoading);
	const exportLoading = useSelector(getExportRequestedRatesLoading);
	const requestedRates = useSelector(getRequestedRates);

	const history = useHistory();
	const { search } = useLocation();
	const location = useLocation();
	const { page, text, field } = queryString.parse(search);

	const [searchField, setSearchField] = useState(field ? String(field) : 'full_name');
	const [dropdownArray, setDropdownArray] = useState<{
		dropdownArray: string[];
		dropdownTextArray: string[];
	}>({ dropdownArray: ['all'], dropdownTextArray: ['all'] });
	const [typeSearchDropdown, setTypeSearchDropdown] = useState(false);

	const [term, setTerm] = useState('');
	const [debouncedTerm, setDebouncedTerm] = useState(term);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);

	useEffect(() => {
		const timer = setTimeout(() => setTerm(debouncedTerm), 800);
		return () => clearTimeout(timer);
	}, [debouncedTerm]);

	const handleClearSearch = () => {
		setDebouncedTerm('');
		setTerm('');
	};

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		const searchParams = text?.length
			? `?page=${String(pageNumber)}&text=${String(text)}&field=${String(field)}`
			: `?page=${String(pageNumber)}`;
		let searchValue = text?.length ? String(text) : undefined;
		if (field === 'pair') {
			searchValue = searchValue?.toLowerCase().replace('/', '_');
		}
		const params = {
			per_page: 15,
			current_page: pageNumber || 1,
			search_value: searchValue,
			search_field: text?.length ? String(field) : undefined,
		};
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});

		dispatch(getRequestedRatesRequest(params));
	};

	const handleSearch = (txt: string) => {
		let searchValue = txt.length >= 1 ? txt : undefined;
		if (searchField === 'pair') {
			searchValue = searchValue?.toLowerCase().replace('/', '_');
		}
		const params = {
			current_page: 1,
			search_value: searchValue,
			search_field: txt.length >= 1 ? searchField : undefined,
		};

		dispatch(getRequestedRatesRequest(params));
		history.push({
			pathname: location.pathname,
			search:
				txt.length >= 1 ? `?page=${1}&text=${String(txt)}&field=${searchField}` : `?page=${1}`,
		});
		setCurrentPage(txt.length >= 1 ? Number(page || 1) : 1);
	};

	useEffect(() => {
		handleSearch(term);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [term, searchField]);

	const searchFieldHandler = (txt: string) => {
		if (txt === 'operation_type') {
			setDropdownArray({
				dropdownArray: OPERATION_TYPE_ARRAY,
				dropdownTextArray: OPERATION_TYPE_TEXT_ARRAY,
			});
			setSearchField(txt);
			setTypeSearchDropdown(true);
			return;
		}

		setSearchField(txt);
		setTypeSearchDropdown(false);
	};

	const handleExport = () => {
		let searchValue = text && text.length >= 1 ? String(text) : undefined;
		if (searchField === 'pair') {
			searchValue = searchValue?.toLowerCase().replace('/', '_');
		}
		const params: IRequestedRatesParams = {
			current_page: currentPage,
			export: 1,
			search_value: searchValue,
			search_field: text && text.length >= 1 ? searchField : undefined,
		};
		dispatch(getRequestedRatesRequest(params));
	};

	return (
		<>
			<div className="title-block">
				<p className="title">Requested Rates</p>
			</div>
			<div className="count-label count-label--filter-type">
				<SearchBar
					setDebouncedTerm={setDebouncedTerm}
					debouncedTerm={debouncedTerm}
					searchFieldArray={RATES_SEARCH_ARRAY}
					searchField={searchField}
					setSearchField={searchFieldHandler}
					handleClearSearch={handleClearSearch}
					searchFieldTextArray={RATES_SEARCH_TEXT_ARRAY}
					typeSearch={typeSearchDropdown ? 'dropdown' : undefined}
					dropdownArray={dropdownArray.dropdownArray}
					dropdownTextArray={dropdownArray.dropdownTextArray}
					inputWidth="140px"
				/>
				<button
					type="button"
					disabled={exportLoading}
					className="transaction-export-btn"
					onClick={handleExport}
				>
					Export
					<span className="transaction-export-btn__icon">
						<ExportIcon />
					</span>
				</button>
			</div>
			<div className="content-block">
				<div className="content-block__inside content-block__inside--type2">
					<div className="table-block table-block--mt-24">
						<div className="table-wrapper">
							<div className="table table--cashdesk-requests table--with-scroll">
								<div className="table-header">
									<TableHeader />
								</div>
								{!loading && !requestedRates?.data.length && (
									<div className="user-management-empty-data">
										<p className="user-management-empty__text">No requested rates found...</p>
									</div>
								)}
								<div className="table-body">
									{loading ? (
										<div className="list-loader">
											<Loader />
										</div>
									) : (
										requestedRates?.data.map((item) => <TableRow key={item.id} item={item} />)
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="pagination-block">
				{(requestedRates?.last_page ?? 0) > 1 && (
					<Pagination
						pageCount={requestedRates?.last_page ?? 0}
						forcePage={currentPage - 1}
						onPageChange={handlePageChange}
					/>
				)}
			</div>
		</>
	);
};

export default RequestedRates;
