import { useState } from 'react';
import Notifications from './Notifications';
import Spreads from './Spreads';
import Currencies from './Currencies';
import { TabType } from './types';

const tabContent = (activeTab: TabType) => {
	switch (activeTab) {
		case 'spreads':
			return <Spreads />;
		case 'currencies':
			return <Currencies />;
		default:
			return <Notifications />;
	}
};

const Configurations = () => {
	const [tab, setTab] = useState<TabType>('notification');

	return (
		<>
			<div className="title-block">
				<p className="title">Configurations</p>
			</div>
			<div className="content-block">
				<div className="content-block__inside content-block__inside--type2">
					<div className="content-panel">
						<ul className="tabs-list tabs-list--type2">
							<li className={`tabs-list__item ${tab === 'notification' ? 'active' : ''}`}>
								<button
									type="button"
									className="tabs-list__link"
									onClick={() => setTab('notification')}
								>
									Notification
								</button>
							</li>
							<li className={`tabs-list__item ${tab === 'spreads' ? 'active' : ''}`}>
								<button type="button" className="tabs-list__link" onClick={() => setTab('spreads')}>
									Spreads
								</button>
							</li>
							<li className={`tabs-list__item ${tab === 'currencies' ? 'active' : ''}`}>
								<button
									type="button"
									className="tabs-list__link"
									onClick={() => setTab('currencies')}
								>
									Currencies
								</button>
							</li>
						</ul>
					</div>
					<div className="table-block table-block--mt-24">
						<div className="table-wrapper">{tabContent(tab)}</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Configurations;
