import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import TwoFA from 'components/TwoFA';

// ================================================:
const TwoFAPage: FC = () => {
	return (
		<Dashboard title="2FA">
			<TwoFA />
		</Dashboard>
	);
};

export default TwoFAPage;
