import { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { editSpreadRequest } from 'redux/reducers/cashdesk/reducer';
import { getSpreadEditing } from 'redux/reducers/cashdesk/selectors';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { IProps } from './types';

const decimalRegex = /^$|^\d+(\.\d*)?$/;

const NotificationRowItem: FC<IProps> = ({ item }) => {
	const dispatch = useDispatch();
	const [firstAssetSpread, setFirstAssetSpread] = useState(String(item.first_asset_spread));
	const [secondAssetSpread, setSecondAssetSpread] = useState(String(item.second_asset_spread));
	const [isEditing, setIsEditing] = useState(false);
	const { checkEditable } = usePermission();
	const canEdit = checkEditable(permissions.CASHDESK_CONFIGURATIONS);
	const editLoading = useSelector(getSpreadEditing);

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Pair</p>
				<p>{item.code.replace('_', '/').toUpperCase()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">First coin</p>
				{isEditing ? (
					<div className="table-input table-input--start">
						<input
							type="text"
							value={firstAssetSpread}
							onChange={(e) => {
								const { value } = e.target;
								if (decimalRegex.test(value)) {
									setFirstAssetSpread(value);
								}
							}}
						/>
					</div>
				) : (
					<p>{item.first_asset_spread}%</p>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Second coin</p>
				{isEditing ? (
					<div className="table-input table-input--start">
						<input
							type="text"
							value={secondAssetSpread}
							onChange={(e) => {
								const { value } = e.target;
								if (decimalRegex.test(value)) {
									setSecondAssetSpread(value);
								}
							}}
						/>
					</div>
				) : (
					<p>{item.second_asset_spread}%</p>
				)}
			</div>
			<div className="td td--right">
				<div className="table-buttons">
					{isEditing ? (
						<>
							<button
								type="button"
								onClick={() => {
									setFirstAssetSpread(String(item.first_asset_spread));
									setSecondAssetSpread(String(item.second_asset_spread));
									setIsEditing(false);
								}}
							>
								<span className="edit-icon icon-cancel-icon" />
							</button>
							<button
								type="button"
								disabled={editLoading}
								onClick={() => {
									dispatch(
										editSpreadRequest({
											apiParams: {
												id: item.id,
												first_asset_spread: +firstAssetSpread,
												second_asset_spread: +secondAssetSpread,
											},
											onSuccess: () => {
												setIsEditing(false);
											},
										}),
									);
								}}
							>
								<span className="edit-icon icon-succes-icon" />
							</button>
						</>
					) : (
						<button
							disabled={!canEdit}
							type="button"
							onClick={() => {
								if (!canEdit) return;
								setIsEditing(true);
							}}
						>
							<span className="edit-icon icon-edit-icon" />
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default NotificationRowItem;
