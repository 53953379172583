import { call, put, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { notificationContainer } from 'utils/notificationContainer';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { api, responseErrors } from 'services';
import axios from 'axios';
import {
	getCashDeskListRequest,
	setCashDeskListSuccess,
	cashDeskListInitState,
	getCashExportRequest,
	getOperatorsRequest,
	getOperatorsSuccess,
	getOperatorsError,
	getOperatorByIdRequest,
	getOperatorByIdSuccess,
	getOperatorByIdError,
	resetOperatorById,
	getOperatorPermissionsRequest,
	getOperatorPermissionsSuccess,
	getOperatorPermissionsError,
	getAvailCashdesksRequest,
	getAvailCashdesksSuccess,
	editOperatorRequest,
	editOperatorFinally,
	createOperatorRequest,
	createOperatorFinally,
	deleteOperatorRequest,
	deleteOperatorFinally,
	getActiveCurrencyRequest,
	setActiveCurrencySuccess,
	createCashDeskRequest,
	addCurrencyCashDesktRequest,
	deleteCashDesktRequest,
	deleteCurrencytRequest,
	deleteCurrencySuccess,
	getWalletsListRequest,
	getWalletsListSuccess,
	getUpdateWalletstRequest,
	deleteWalletRequest,
	getWalletHistoryRequest,
	getHistoryListSuccess,
	getAvailableOperatorsRequest,
	getAvailableOperatorsSuccess,
	getNotificationsRequest,
	getNotificationsSuccess,
	getNotificationsError,
	editNotificationRequest,
	editNotificationSuccess,
	editNotificationError,
	getSpreadsRequest,
	getSpreadsSuccess,
	getSpreadsError,
	editSpreadRequest,
	editSpreadSuccess,
	editSpreadError,
	getCurrenciesRequest,
	getCurrenciesSuccess,
	getCurrenciesError,
	editCurrencyStatusRequest,
	editCurrencyStatusSuccess,
	editCurrencyStatusError,
	getAddWalleyRequest,
	getWalletOperationListRequest,
	getWalletOperationListSuccess,
	getWalletCurrenciesRequest,
	getWalletCurrenciesSuccess,
	getOperationsRequest,
	getOperationsSuccess,
	getOperationsError,
	setOperationsExportLoading,
	getOperationUserRequest,
	getOperationUserSuccess,
	getOperationCurrenciesRequest,
	getOperationCurrenciesSuccess,
	getOperationSpredRequest,
	getOperationRateRequest,
	getOperationRateSuccess,
	getOperationSpredSuccess,
	createOperationRequest,
	createOperationSuccess,
	getCheckAddressRequest,
	getRequestedRatesRequest,
	getRequestedRatesSuccess,
	getRequestedRatesError,
	setExportRatesLoading,
	acceptRequestedRateRequest,
	acceptRequestedRateSuccess,
	acceptRequestedRateError,
	rejectRequestedRateSuccess,
	rejectRequestedRateRequest,
	rejectRequestedRateError,
	createOperationContinueRequest,
} from './reducer';
import {
	ICashdeskListResponseData,
	IActiveCurrencyResponse,
	IAddCashdesksPayload,
	IAddCurrencyCashdesksPayload,
	IRemoveCashdesksPayload,
	IREmoveCurrencyCashdesksPayload,
	IOperatorsRequest,
	IOperatorsResponse,
	TOperatorPermissionsResponse,
	TAvaliableCashdesksResponse,
	IEditOperatorPayload,
	ICreateOperatorPayload,
	IDeleteOperatorPayload,
	IOperator,
	IWalletManagementResponse,
	IWalletManagementPayload,
	IChangeWalletPayload,
	IWalletDeletePyload,
	IHistoryManagementResponse,
	IOperatorsPayload,
	IAvailableOperatorsResponse,
	INotificationsResponse,
	ISpread,
	ICurrency,
	IEditSpreadPayload,
	IEditNotificationRequest,
	INotification,
	IEditCurrencyStatusRequest,
	IAddWalletPayload,
	IHostoryOperatorsResponse,
	IGetAvailableCurrenciesPayload,
	IGetAvailableCurrenciesResponse,
	IOperationsRequest,
	IOperationsResponse,
	IGetOperationUserPayload,
	IGetOperationUserResponse,
	IGetOperationCurrenciesPayload,
	IGetOperationCurrenciesResponse,
	IGetOperationRatePayload,
	IGetOperationRateResponse,
	IGetOperationSpreadPayload,
	IGetOperationSpreadResponse,
	ICreateOperationPayload,
	ICheckNetworkPayload,
	ICheckNetworkResponse,
	IRequestedRatesParams,
	IRequestedRatesResponse,
	IAcceptRatePayload,
	IRejectRatePayload,
	IContinueCreateOperationPayload,
} from './types';

// =============================================================:
function* balanceListWorker() {
	try {
		yield put(showLoading());
		const response: ICashdeskListResponseData = yield call(api.cashdesk.getCashdesks);

		yield put(setCashDeskListSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(cashDeskListInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* getCashExportWorker() {
	try {
		yield put(showLoading());
		yield call(api.cashdesk.exportCashdesks);
		notificationContainer('Cash Desk successfully exported, check your mailbox!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		notificationContainer('export', 'error');
	} finally {
		yield put(hideLoading());
	}
}

function* getActiveCurrencyRequestWorker() {
	try {
		yield put(showLoading());
		const response: IActiveCurrencyResponse = yield call(api.cashdesk.getActiveCurrency);
		yield put(setActiveCurrencySuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* createCashDeskWorker(action: PayloadAction<IAddCashdesksPayload>) {
	const { params, onSuccess } = action.payload;
	try {
		yield put(showLoading());
		yield call(api.cashdesk.addCashdesk, params);
		onSuccess?.();
		yield put(getCashDeskListRequest());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* addCurrencyCashDesktWorker(action: PayloadAction<IAddCurrencyCashdesksPayload>) {
	const { params, onSuccess } = action.payload;
	try {
		yield put(showLoading());
		yield call(api.cashdesk.addCashdeskCurrency, params);
		onSuccess?.();
		yield put(getCashDeskListRequest());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* deleteCashDesktWorker(action: PayloadAction<IRemoveCashdesksPayload>) {
	const { params, onSuccess } = action.payload;
	try {
		yield put(showLoading());
		yield call(api.cashdesk.deleteCashdesk, params);
		onSuccess?.();
		yield put(getCashDeskListRequest());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* deleteCurrencytWorker(action: PayloadAction<IREmoveCurrencyCashdesksPayload>) {
	const { params, onSuccess } = action.payload;
	try {
		yield put(showLoading());
		yield call(api.cashdesk.deleteCashdeskCurrency, params);
		onSuccess?.();
		yield put(deleteCurrencySuccess(params));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* getOperatorsWorker(action: PayloadAction<IOperatorsRequest>) {
	const { payload } = action;

	yield put(resetOperatorById());

	try {
		yield put(showLoading());
		const response: IOperatorsResponse = yield call(api.cashdesk.getOperators, payload);

		if (payload.export === 1) {
			notificationContainer('Users successfully exported, check your mailbox!', 'info');
		} else yield put(getOperatorsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(getOperatorsError());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* getOperatorByIdWorker(action: PayloadAction<number>) {
	const { payload } = action;

	yield put(resetOperatorById());

	try {
		yield put(showLoading());
		const response: IOperator = yield call(api.cashdesk.getOperatorById, payload);

		yield put(getOperatorByIdSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(getOperatorByIdError());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* permissionsListWorker() {
	try {
		yield put(showLoading());
		const response: TOperatorPermissionsResponse = yield call(api.cashdesk.getOperatorsPermissions);

		yield put(getOperatorPermissionsSuccess(response));
	} catch (error) {
		yield put(getOperatorPermissionsError());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* cashdeskListWorker() {
	try {
		yield put(showLoading());
		const response: TAvaliableCashdesksResponse = yield call(api.cashdesk.getAvailableCashdesks);

		yield put(getAvailCashdesksSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* editOperatorWorker(action: PayloadAction<IEditOperatorPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.cashdesk.updateOperator, payload.data);

		notificationContainer('Operator has been edited!', 'success');

		payload.history.push({
			pathname: '/cash-desk/operators',
			search: `?page=${String(payload.page)}`,
		});
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(getOperatorPermissionsRequest());
	} finally {
		yield put(hideLoading());
		yield put(editOperatorFinally());
	}
}

// =============================================================:
function* createOperatorWorker(action: PayloadAction<ICreateOperatorPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.cashdesk.addOperator, payload.data);
		notificationContainer('Operator has been created!', 'success');
		payload.history.push('/cash-desk/operators');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(createOperatorFinally());
	}
}

// =============================================================:
function* deleteOpearatorWorker(action: PayloadAction<IDeleteOperatorPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.cashdesk.deleteOperator, payload.userId);

		notificationContainer('Operator has been deleted!', 'success');

		const params = { current_page: Number(payload.page) || 1 };
		yield put(getOperatorsRequest(params));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(deleteOperatorFinally());
	}
}

// =============================================================:
function* getWalletsListWorker(action: PayloadAction<IWalletManagementPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IWalletManagementResponse = yield call(api.cashdesk.getWallets, payload);

		if (payload.export === 1) {
			notificationContainer('Wallets successfully exported, check your mailbox!', 'info');
		} else yield put(getWalletsListSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getUpdateWalletstWorker(action: PayloadAction<IChangeWalletPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.cashdesk.updateWallet, payload);
		yield put(getWalletsListRequest({ current_page: 1 }));
		notificationContainer('Wallet has been edited', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* deleteWalletWorker(action: PayloadAction<IWalletDeletePyload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.cashdesk.deletetWallet, payload);
		yield put(getWalletsListRequest({ current_page: 1 }));
		notificationContainer('Wallet has been deleted', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getWalletHistoryWorker(action: PayloadAction<IWalletManagementPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IHistoryManagementResponse = yield call(
			api.cashdesk.getWalletsHistory,
			payload,
		);
		if (payload.export === 1) {
			notificationContainer('History successfully exported, check your mailbox!', 'info');
		} else yield put(getHistoryListSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getAvailableOperatorsWorker(action: PayloadAction<IOperatorsPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IAvailableOperatorsResponse = yield call(
			api.cashdesk.getAvailableOperators,
			payload,
		);
		yield put(getAvailableOperatorsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getAddWalleyWorker({ payload }: PayloadAction<IAddWalletPayload>) {
	const { params, onError, onSuccess } = payload;

	try {
		yield put(showLoading());
		yield call(api.cashdesk.addWallet, params);
		onSuccess?.();
		yield put(getWalletsListRequest({ current_page: 1 }));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
			onError?.();
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getWalletOperationListWorker({ payload }: PayloadAction<IAddWalletPayload>) {
	try {
		yield put(showLoading());
		const response: IHostoryOperatorsResponse = yield call(api.cashdesk.getWalletOperationList);
		yield put(getWalletOperationListSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* getNotificationsWorker() {
	try {
		yield put(showLoading());
		const response: INotificationsResponse = yield call(api.cashdesk.getNotifications);

		yield put(getNotificationsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(getNotificationsError());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* editNotificationWorker({ payload }: PayloadAction<IEditNotificationRequest>) {
	try {
		yield put(showLoading());
		const response: INotification = yield call(api.cashdesk.updateNotification, payload);

		notificationContainer('Email status was changed!', 'success');

		yield put(editNotificationSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(editNotificationError());
	}
}

// =============================================================:
function* getSpreadsWorker() {
	try {
		yield put(showLoading());
		const response: ISpread[] = yield call(api.cashdesk.getSpreads);

		yield put(getSpreadsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(getSpreadsError());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* editSpreadWorker({ payload }: PayloadAction<IEditSpreadPayload>) {
	const { apiParams, onSuccess } = payload;

	try {
		yield put(showLoading());
		const response: ISpread = yield call(api.cashdesk.updateSpread, apiParams);

		notificationContainer('Spread has been edited!', 'success');

		yield put(editSpreadSuccess(response));

		onSuccess?.();
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(editSpreadError());
	}
}

// =============================================================:
function* getCurrenciesWorker() {
	try {
		yield put(showLoading());
		const response: ICurrency[] = yield call(api.cashdesk.getCurrencies);

		yield put(getCurrenciesSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(getCurrenciesError());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* editCurrencyStatusWorker({ payload }: PayloadAction<IEditCurrencyStatusRequest>) {
	try {
		yield put(showLoading());
		const response: ICurrency = yield call(api.cashdesk.updateCurrency, payload);

		notificationContainer('Currency status was changed!', 'success');

		yield put(editCurrencyStatusSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(editCurrencyStatusError());
	}
}

// =============================================================:
function* getWalletCurrenciesWorker({ payload }: PayloadAction<IGetAvailableCurrenciesPayload>) {
	try {
		yield put(showLoading());
		const response: IGetAvailableCurrenciesResponse = yield call(
			api.cashdesk.getAvailableCurrencies,
			payload,
		);

		yield put(getWalletCurrenciesSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

// =============================================================:
function* getOperationsWorker({ payload }: PayloadAction<IOperationsRequest>) {
	try {
		yield put(showLoading());
		const response: IOperationsResponse = yield call(api.cashdesk.getOperations, payload);

		if (payload.export === 1) {
			yield put(setOperationsExportLoading(false));
			notificationContainer('Operations successfully exported, check your mailbox!', 'info');
		} else yield put(getOperationsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(setOperationsExportLoading(false));
		yield put(getOperationsError());
	}
}

function* getOperationUserWorker({ payload }: PayloadAction<IGetOperationUserPayload>) {
	try {
		yield put(showLoading());
		const response: IGetOperationUserResponse = yield call(api.cashdesk.getOperationUser, payload);

		yield put(getOperationUserSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getOperationCurrenciesWorker({ payload }: PayloadAction<IGetOperationCurrenciesPayload>) {
	try {
		yield put(showLoading());
		const response: IGetOperationCurrenciesResponse = yield call(
			api.cashdesk.getAvailableOperationCurrencies,
			payload,
		);

		yield put(
			getOperationCurrenciesSuccess({ from: payload.destination === 'from', data: response }),
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getOperationSpredWorker({ payload }: PayloadAction<IGetOperationSpreadPayload>) {
	try {
		yield put(showLoading());
		const response: IGetOperationSpreadResponse = yield call(
			api.cashdesk.getOperationSpread,
			payload,
		);

		yield put(getOperationSpredSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getOperationRateRequestWorker({ payload }: PayloadAction<IGetOperationRatePayload>) {
	try {
		yield put(showLoading());
		const response: IGetOperationRateResponse = yield call(api.cashdesk.getOperationRate, payload);

		yield put(getOperationRateSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* createOperationWorker({ payload }: PayloadAction<ICreateOperationPayload>) {
	const { onError, onSuccess, params } = payload;
	try {
		yield put(showLoading());
		const response: IGetOperationRateResponse = yield call(api.cashdesk.createOperation, params);

		yield put(createOperationSuccess(response));
		onSuccess?.();
		yield put(getOperationsRequest({ current_page: 1 }));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			onError?.();
			responseErrors(error);
		}
	}
}

function* getCheckAddressWorker({ payload }: PayloadAction<ICheckNetworkPayload>) {
	const { onError, onSuccess, params } = payload;
	try {
		yield put(showLoading());
		const response: ICheckNetworkResponse = yield call(api.cashdesk.checkWithdrawalAddress, params);
		if (response) {
			onSuccess?.(Boolean(response?.is_valid));
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			onError?.();
			responseErrors(error);
		}
	}
}

function* getRequestedRatesWorker({ payload }: PayloadAction<IRequestedRatesParams>) {
	try {
		yield put(showLoading());
		const response: IRequestedRatesResponse = yield call(api.cashdesk.getRequestedRates, payload);

		if (payload.export === 1) {
			yield put(setExportRatesLoading(false));
			notificationContainer('Requested rates successfully exported, check your mailbox!', 'info');
		} else yield put(getRequestedRatesSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
			yield put(getRequestedRatesError());
			yield put(setExportRatesLoading(false));
		}
	}
}

function* acceptRequestRateWorker({ payload }: PayloadAction<IAcceptRatePayload>) {
	const { apiParams, onSuccess } = payload;

	try {
		yield put(showLoading());
		yield call(api.cashdesk.acceptRequestedRate, apiParams);
		yield put(acceptRequestedRateSuccess({ id: apiParams.operation_id }));
		notificationContainer('Requested rate was accepted!', 'success');
		onSuccess?.();
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(acceptRequestedRateError());
	} finally {
		yield put(hideLoading());
	}
}

function* rejectRequestRateWorker({ payload }: PayloadAction<IRejectRatePayload>) {
	const { apiParams, onSuccess } = payload;

	try {
		yield put(showLoading());
		yield call(api.cashdesk.rejectRequestedRate, apiParams);
		yield put(rejectRequestedRateSuccess({ id: apiParams.operation_id }));
		notificationContainer('Requested rate was rejected!', 'success');
		onSuccess?.();
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(rejectRequestedRateError());
	} finally {
		yield put(hideLoading());
	}
}

function* createOperationContinueWorker({
	payload,
}: PayloadAction<IContinueCreateOperationPayload>) {
	const { params, onSuccess } = payload;
	try {
		yield put(showLoading());
		yield call(api.cashdesk.continueOperation, params);
		onSuccess?.();
		yield put(getOperationsRequest({ current_page: 1 }));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

// =============================================================:
export function* cashDeskSaga() {
	yield takeEvery(getCashDeskListRequest.type, balanceListWorker);
	yield takeEvery(getCashExportRequest.type, getCashExportWorker);
	yield takeEvery(getOperatorsRequest.type, getOperatorsWorker);
	yield takeEvery(getOperatorByIdRequest.type, getOperatorByIdWorker);
	yield takeEvery(getOperatorPermissionsRequest.type, permissionsListWorker);
	yield takeEvery(getAvailCashdesksRequest.type, cashdeskListWorker);
	yield takeEvery(editOperatorRequest.type, editOperatorWorker);
	yield takeEvery(createOperatorRequest.type, createOperatorWorker);
	yield takeEvery(deleteOperatorRequest.type, deleteOpearatorWorker);
	yield takeEvery(getActiveCurrencyRequest.type, getActiveCurrencyRequestWorker);
	yield takeEvery(createCashDeskRequest.type, createCashDeskWorker);
	yield takeEvery(addCurrencyCashDesktRequest.type, addCurrencyCashDesktWorker);
	yield takeEvery(deleteCashDesktRequest.type, deleteCashDesktWorker);
	yield takeEvery(deleteCurrencytRequest.type, deleteCurrencytWorker);
	yield takeEvery(getWalletsListRequest.type, getWalletsListWorker);
	yield takeEvery(getUpdateWalletstRequest.type, getUpdateWalletstWorker);
	yield takeEvery(deleteWalletRequest.type, deleteWalletWorker);
	yield takeEvery(getWalletHistoryRequest.type, getWalletHistoryWorker);
	yield takeEvery(getAvailableOperatorsRequest.type, getAvailableOperatorsWorker);
	yield takeEvery(getNotificationsRequest.type, getNotificationsWorker);
	yield takeEvery(getSpreadsRequest.type, getSpreadsWorker);
	yield takeEvery(getCurrenciesRequest.type, getCurrenciesWorker);
	yield takeEvery(editSpreadRequest.type, editSpreadWorker);
	yield takeEvery(editNotificationRequest.type, editNotificationWorker);
	yield takeEvery(editCurrencyStatusRequest.type, editCurrencyStatusWorker);
	yield takeEvery(getAddWalleyRequest.type, getAddWalleyWorker);
	yield takeEvery(getWalletOperationListRequest.type, getWalletOperationListWorker);
	yield takeEvery(getWalletCurrenciesRequest.type, getWalletCurrenciesWorker);
	yield takeEvery(getOperationsRequest.type, getOperationsWorker);
	yield takeEvery(getOperationUserRequest.type, getOperationUserWorker);
	yield takeEvery(getOperationCurrenciesRequest.type, getOperationCurrenciesWorker);
	yield takeEvery(getOperationSpredRequest.type, getOperationSpredWorker);
	yield takeEvery(getOperationRateRequest.type, getOperationRateRequestWorker);
	yield takeEvery(createOperationRequest.type, createOperationWorker);
	yield takeEvery(getCheckAddressRequest.type, getCheckAddressWorker);
	yield takeEvery(getRequestedRatesRequest.type, getRequestedRatesWorker);
	yield takeEvery(acceptRequestedRateRequest.type, acceptRequestRateWorker);
	yield takeEvery(rejectRequestedRateRequest.type, rejectRequestRateWorker);
	yield takeEvery(createOperationContinueRequest.type, createOperationContinueWorker);
}
