import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { getAuthIsAuthenticated, getAuthIsNotAuthenticated } from 'redux/reducers/auth/selectors';
import { isRouteAvailable, getFirstAvaliablePath } from './utils';

const locationHelper = locationHelperBuilder({});

interface OwnProps {
	location?: {
		pathname?: string;
		search?: string;
	};
}

// ==========================================:
export const isAuth = connectedRouterRedirect({
	redirectPath: (state, ownProps: OwnProps) => {
		const { pathname = '', search = '' } = ownProps.location || {};
		const returnPath =
			!pathname || pathname === '/'
				? '/login'
				: `/login?redirect=${encodeURIComponent(`${pathname}${search}`)}`;
		return returnPath;
	},
	// Сделал кастомный редирект, дефолтный выключил т.к. редиректит по умолчанию на главную '/'
	// на которую может не быть прав
	allowRedirectBack: false,
	authenticatedSelector: getAuthIsAuthenticated,
	wrapperDisplayName: 'FrontUserIsAuthenticated',
});

// ==========================================:
export const isGuest = connectedRouterRedirect({
	redirectPath: (state, ownProps) => {
		let defaultPath = '/';
		const homeAvaliable = isRouteAvailable(defaultPath, state.auth.userData?.permissions || []);
		if (!homeAvaliable) {
			defaultPath = getFirstAvaliablePath(state.auth.userData?.permissions || []) || '/';
		}
		return locationHelper.getRedirectQueryParam(ownProps) || defaultPath;
	},
	allowRedirectBack: false,
	authenticatedSelector: getAuthIsNotAuthenticated,
	wrapperDisplayName: 'FrontUserIsNotAuthenticated',
});
