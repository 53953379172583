import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import queryString from 'query-string';
import SearchBar from 'components/SearchBar';
import { IWalletManagementPayload } from 'redux/reducers/cashdesk/types';
import { getAvailableCashdesks } from 'redux/reducers/cashdesk/selectors';
import { getWalletsListRequest } from 'redux/reducers/cashdesk/reducer';
import { WALLET_SEARCH_ARRAY, WALLET_SEARCH_TEXT_ARRAY } from '../../constants';

import { Filter } from '../Filter';
/* eslint-disable react-hooks/exhaustive-deps */

export const WalletFilter = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { search } = useLocation();
	const { page, text, field } = queryString.parse(search);
	const cashdesks = useSelector(getAvailableCashdesks);

	const location = useLocation();

	const [dropdownArray, setDropdownArray] = useState<{
		dropdownArray: string[];
		dropdownTextArray: string[];
	}>({ dropdownArray: ['all'], dropdownTextArray: ['all'] });
	const [typeSearchDropdown, setTypeSearchDropdown] = useState(false);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);

	const [searchField, setSearchField] = useState(field ? String(field) : 'operator_id');

	const [term, setTerm] = useState('');
	const [debouncedTerm, setDebouncedTerm] = useState(term);

	useEffect(() => {
		const timer = setTimeout(() => setTerm(debouncedTerm), 800);
		return () => clearTimeout(timer);
	}, [debouncedTerm]);

	const handleClearSearch = () => {
		setDebouncedTerm('');
		setTerm('');
	};

	const handleSearch = (txt: string) => {
		const searchValue = String(txt).length >= 1 ? txt : undefined;
		const params = {
			current_page: 1,
			search_value: searchValue,
			search_field: txt.length >= 1 ? searchField : undefined,
		};

		dispatch(getWalletsListRequest(params));
		history.push({
			pathname: location.pathname,
			search:
				txt.length >= 1 ? `?page=${1}&text=${String(txt)}&field=${searchField}` : `?page=${1}`,
		});
		setCurrentPage(txt.length >= 1 ? Number(page || 1) : 1);
	};

	useEffect(() => {
		handleSearch(term);
	}, [term, searchField]);

	const searchFieldHandler = (txt: string) => {
		if (txt === 'cashdesk_id' && cashdesks?.length) {
			setDropdownArray({
				dropdownArray: ['all', ...(cashdesks?.map((item) => String(item.id)) || [])],
				dropdownTextArray: ['All', ...(cashdesks?.map((item) => item.name) || [])],
			});
			setSearchField(txt);
			setTypeSearchDropdown(true);
			return;
		}
		setSearchField(txt);
		setTypeSearchDropdown(false);
	};

	const handleExport = () => {
		const searchValue = text && text.length >= 1 ? String(text) : undefined;
		const params: IWalletManagementPayload = {
			current_page: Number(page) || 1,
			export: 1,
			search_value: searchValue,
			search_field: text && text.length >= 1 ? searchField : undefined,
		};
		dispatch(getWalletsListRequest(params));
	};
	return (
		<div className="count-label count-label--filter-type">
			<SearchBar
				setDebouncedTerm={setDebouncedTerm}
				debouncedTerm={debouncedTerm}
				searchFieldArray={WALLET_SEARCH_ARRAY}
				searchField={searchField}
				setSearchField={searchFieldHandler}
				handleClearSearch={handleClearSearch}
				searchFieldTextArray={WALLET_SEARCH_TEXT_ARRAY}
				typeSearch={typeSearchDropdown ? 'dropdown' : undefined}
				dropdownArray={dropdownArray.dropdownArray}
				dropdownTextArray={dropdownArray.dropdownTextArray}
				inputWidth="120px"
			/>
			<Filter exportTitle="Export wallets" onExport={handleExport} />
		</div>
	);
};
