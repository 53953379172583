const TableHeader = () => {
	return (
		<div className="tr">
			<div className="td">
				<div className="td-name">
					<p>ID</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Account</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Operator ID</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Transaction type</p>
				</div>
			</div>

			<div className="td">
				<div className="td-name">
					<p>Full name</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Personal number</p>
				</div>
			</div>
			<div className="td  ">
				<div className="td-name">
					<p>User ID</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Date</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Actual Rate</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Requested Rate</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>New Rate</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Admin Id</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>From</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>To</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Amount From</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Amount To</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Network</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Hash tx</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Transfered</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Comment</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Rate comment</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>System message</p>
				</div>
			</div>
			<div className="td td--right">
				<div className="td-name">
					<p>Status</p>
				</div>
			</div>
		</div>
	);
};

export default TableHeader;
