import { FC } from 'react';

interface SwitchProps {
	checked: boolean;
	onToggle: () => void;
}

export const Switch: FC<SwitchProps> = ({ checked, onToggle }) => {
	return (
		<div className="switch switch--type2">
			<p className="switch__text">{checked ? 'On' : 'Off'}</p>
			<label className="switch__label">
				<input type="checkbox" className="hidden" checked={checked} onChange={onToggle} />
				<div className="switch__toggler" />
			</label>
		</div>
	);
};
