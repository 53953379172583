import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import PendingDeposits from 'components/PendingDeposits';

// ================================================:
const WithdrawalsPage: FC = () => {
	return (
		<Dashboard title="Pending withdrawals">
			<PendingDeposits />
		</Dashboard>
	);
};

export default WithdrawalsPage;
