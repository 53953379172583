import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import {
	ICashdeskStore,
	ICashdeskListResponseData,
	ICashdeskList,
	IOperatorsResponse,
	TAvaliableCashdesksResponse,
	TOperatorPermissionsResponse,
	IActiveCurrencyResponse,
	IOperator,
	IWalletManagementData,
	IHistoryManagementData,
	IOperatorsItem,
	INotificationsResponse,
	ISpread,
	ICurrency,
	IHostoryOperatorsResponse,
	IGetAvailableCurrenciesResponse,
	IOperationsResponse,
	IOperationUser,
	IAvailableOperationCurrenciesItem,
	IOperationRate,
	IGetOperationSpreadResponse,
	IRequestedRatesResponse,
} from './types';

// ====================================================:
const getCashdeskState = (state: IStoreState): ICashdeskStore => state.cashdesk;
export const getCashdesk = createSelector(
	[getCashdeskState],
	(cashdesk: ICashdeskStore) => cashdesk,
);

// ====================================================:
export const getCashdeskIsLoad = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): boolean => cashdesk.cashdeskListLoader,
);

// ====================================================:
export const getCashdeskData = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): ICashdeskListResponseData => cashdesk.cashdeskData,
);

export const getCashdeskList = createSelector(
	[getCashdeskData],
	(cashdesk: ICashdeskListResponseData): ICashdeskList[] | undefined => cashdesk?.cashdesks,
);

// ====================================================:
export const getOperatorsIsLoad = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): boolean => cashdesk.operatorsLoading,
);

export const getOperators = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): null | IOperatorsResponse => cashdesk.operatorsList,
);

// ====================================================:
export const getAvailableCashdesks = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): null | TAvaliableCashdesksResponse => cashdesk.availableCashdesks,
);

// ====================================================:
export const getOperationPermissionsLoading = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): boolean => cashdesk.operationPermissionsLoading,
);

// ====================================================:
export const getOperationPermissions = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): null | TOperatorPermissionsResponse => cashdesk.operationPermissions,
);

// ====================================================:
export const getActiveCurrency = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): IActiveCurrencyResponse | null => cashdesk.activeCurrency,
);

// ====================================================:
export const getOperatorById = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): IOperator | null => cashdesk.operatorById,
);

// ====================================================:
export const getWallets = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): IWalletManagementData | null => cashdesk.walletsList,
);

export const getWalletsLoader = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): boolean => cashdesk.walletsListLoader,
);

// ====================================================:
export const getHistory = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): IHistoryManagementData | null => cashdesk.historyList,
);

export const getHistoryLoader = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): boolean => cashdesk.historyListLoader,
);

export const getAvailableOperators = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): IOperatorsItem[] | null => cashdesk.operators,
);

// ====================================================:
export const getNotifications = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): INotificationsResponse | null => cashdesk.notifications,
);

export const getNotificationsIsLoad = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): boolean => cashdesk.notificationsLoading,
);

export const getEditNotificationLoading = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): boolean => cashdesk.editNotificationLoading,
);

// ====================================================:
export const getSpreads = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): ISpread[] | null => cashdesk.spreads,
);

export const getSpreadsIsLoad = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): boolean => cashdesk.spreadsLoading,
);

export const getSpreadEditing = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): boolean => cashdesk.editOperatorLoader,
);

// ====================================================:
export const getCurrencies = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): ICurrency[] | null => cashdesk.currencies,
);

export const getCurrenciesIsLoad = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): boolean => cashdesk.currenciesLoading,
);

export const getCurrencyEditing = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): boolean => cashdesk.editCurrencyLoading,
);

export const getHistoryOperators = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): IHostoryOperatorsResponse | null => cashdesk.operatorsHistoryList,
);

export const getAvailableWalletCurrencies = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): IGetAvailableCurrenciesResponse | null =>
		cashdesk.availableWalletCurrency,
);

// ====================================================:
export const getOperartionsIsLoad = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): boolean => cashdesk.operationsLoading,
);

export const getOperartionsExportLoading = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): boolean => cashdesk.operationsExportLoading,
);

export const getOperations = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): IOperationsResponse | null => cashdesk.operationsList,
);

export const getOperationsUser = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): IOperationUser | null => cashdesk.operationUser,
);

export const getOperationsCurrenciesFrom = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): IAvailableOperationCurrenciesItem[] | null =>
		cashdesk.availableOpertaionCurrencyFrom,
);

export const getOperationsCurrenciesTo = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): IAvailableOperationCurrenciesItem[] | null =>
		cashdesk.availableOpertaionCurrencyTo,
);

export const getOperationsRate = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): IOperationRate | null => cashdesk.operationRate,
);

export const getOperationsSpred = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): IGetOperationSpreadResponse | null => cashdesk.operationSpred,
);

// ====================================================:
export const getRequestedRatesLoading = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): boolean => cashdesk.requestedRatesLoading,
);

export const getExportRequestedRatesLoading = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): boolean => cashdesk.exportRequestedRatesLoading,
);

export const getRequestedRates = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): IRequestedRatesResponse | null => cashdesk.requestedRates,
);

export const acceptRequestedRateLoading = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): boolean => cashdesk.acceptRateLoading,
);

export const rejectRequestedRateLoading = createSelector(
	[getCashdesk],
	(cashdesk: ICashdeskStore): boolean => cashdesk.rejectRateLoading,
);
