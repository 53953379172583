import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getMaintenance, getMaintenanceloader } from 'redux/reducers/settings/selectors';
import Loader from 'ui/Loader';
import { TableRow } from './TableRow';

export const Table: FC = () => {
	const maintenance = useSelector(getMaintenance);
	const convertsLoader = useSelector(getMaintenanceloader);

	return (
		<div className="table-block table-block--mt-24">
			<div className="table-wrapper">
				<div className="table table--maintenance-history">
					<div className="table-header">
						<div className="tr">
							<div className="td">
								<div className="td-name">
									<p> Date & Time</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Admin Id</p>
								</div>
							</div>
							<div className="td td--right">
								<div className="td-name">
									<p>Status</p>
								</div>
							</div>
						</div>
					</div>
					{convertsLoader ? (
						<div className="list-loader">
							<Loader />
						</div>
					) : (
						<div className="table-body">
							{maintenance?.data.map((item) => (
								<TableRow data={item} key={item.created_at} />
							))}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
