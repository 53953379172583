import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import BankAccount from 'components/BankAccount';

// ================================================:
const BankAccountPage: FC = () => {
	return (
		<Dashboard title="Token Management">
			<BankAccount />
		</Dashboard>
	);
};
export default BankAccountPage;
