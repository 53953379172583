import { FC } from 'react';
import { IPropsPopup } from './type';

// ================================================:
export const SuccessPopup: FC<IPropsPopup> = ({
	cashdesk,
	opertortId,
	currency,
	initialBalance,
	maxAlert,
	minAlert,
	onSubmit,
}) => {
	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup popup--medium">
					<div className="popup-header">
						<p className="popup-header__title">Wallet created succesfully</p>
					</div>
					<div className="popup-body popup-body--type2">
						<div className="withdrawal-options ">
							<div className="withdrawal-option">
								<span className="withdrawal-option__title">Cash desk</span>
								<span className="withdrawal-option__desc">{cashdesk?.name}</span>
							</div>
							<div className="withdrawal-option">
								<span className="withdrawal-option__title"> Operator name</span>
								<span className="withdrawal-option__desc">
									{opertortId?.first_name} {opertortId?.last_name}
								</span>
							</div>
							<div className="withdrawal-option">
								<span className="withdrawal-option__title">Currency</span>
								<span className="withdrawal-option__desc">
									{currency?.asset.code?.toUpperCase()}
								</span>
							</div>
							<div className="withdrawal-option">
								<span className="withdrawal-option__title">Initital balance</span>
								<span className="withdrawal-option__desc">{initialBalance}</span>
							</div>
							<div className="withdrawal-option">
								<span className="withdrawal-option__title">Min alerts</span>
								<span className="withdrawal-option__desc">{minAlert}</span>
							</div>
							<div className="withdrawal-option">
								<span className="withdrawal-option__title">Max alerts</span>
								<span className="withdrawal-option__desc">{maxAlert}</span>
							</div>
						</div>
					</div>
					<div className="popup-submit popup-submit--type2">
						<button
							onClick={onSubmit}
							type="button"
							className="button button--green-type  button--full-width "
						>
							Okay
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
