import { FC, useState } from 'react';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { notificationContainer } from 'utils/notificationContainer';
import CopyToClipboard from 'react-copy-to-clipboard';
import AcceptPopup from '../Popups/Accept';
import RejectPopup from '../Popups/Reject';
import { IProps } from './types';

const TableRow: FC<IProps> = ({ item }) => {
	const {
		id,
		cashdesk_name: cashdeskName,
		operator_id: operatorId,
		type,
		user_id: userId,
		created_at: createdAt,
		actual_rate: actualRate,
		requested_rate: requestedRate,
		from_asset_code: fromAssetCode,
		to_asset_code: toAssetCode,
		amount_from: amountFrom,
		amount_to: amountTo,
		address,
	} = item;

	const [openAccept, setOpenAccept] = useState(false);
	const [openReject, setOpenReject] = useState(false);

	const { checkReadable, checkEditable } = usePermission();

	const canEdit = checkEditable(permissions.CASHDESK_REQUESTED_RATES);

	const formattedType = type
		?.split('_')
		.map((_item) => capitalizeFirstLetter(_item))
		.join('/');

	const date = new Date(Number(createdAt) * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+Number(createdAt) + offset) * 1000);

	const handleAddressCopy = () => {
		notificationContainer('Address copied successfully!', 'info');
	};

	return (
		<>
			<div className="tr">
				<div className="td">
					<p className="td-hidden-name">ID</p>
					<p>{id}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Account</p>
					<p>{cashdeskName}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Operator ID</p>
					<p>
						{operatorId &&
						checkReadable(permissions.CASHDESK_PERMISSION_MANAGEMENT) &&
						checkEditable(permissions.CASHDESK_PERMISSION_MANAGEMENT) ? (
							<a href={`/cash-desk/operators/edit/${operatorId}`}>{operatorId}</a>
						) : (
							operatorId
						)}
					</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Operation type</p>
					<p>{formattedType}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">User ID</p>
					<p>
						{userId &&
						checkReadable(permissions.MANAGE_USERS) &&
						checkEditable(permissions.MANAGE_USERS) ? (
							<a href={`/user-management/${userId}`}>346</a>
						) : (
							userId
						)}
					</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Date</p>
					<p>
						{dateOffset.toLocaleDateString('en-GB')} &nbsp;
						{dateOffset.toLocaleTimeString(undefined, { hour12: false })}
					</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Actual Rate</p>
					<p>{actualRate}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Requested Rate</p>
					<p>{requestedRate}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">From</p>
					<p>{fromAssetCode?.toUpperCase()}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">To</p>
					<p>{toAssetCode?.toUpperCase()}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Amount from</p>
					<p>{amountFrom}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Amount To</p>
					<p>{amountTo}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Transfered</p>
					<div className="copy-button">
						<span className="copy-button__text">{address}</span>
						{address && (
							<CopyToClipboard text={address} onCopy={handleAddressCopy}>
								<button type="button" className="copy-button__item">
									<svg
										width="16"
										height="16"
										viewBox="0 0 16 16"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z"
											stroke="#777E91"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M3.33337 10.0007H2.66671C2.31309 10.0007 1.97395 9.86018 1.7239 9.61013C1.47385 9.36008 1.33337 9.02094 1.33337 8.66732V2.66732C1.33337 2.3137 1.47385 1.97456 1.7239 1.72451C1.97395 1.47446 2.31309 1.33398 2.66671 1.33398H8.66671C9.02033 1.33398 9.35947 1.47446 9.60952 1.72451C9.85956 1.97456 10 2.3137 10 2.66732V3.33398"
											stroke="#777E91"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</button>
							</CopyToClipboard>
						)}
					</div>
				</div>
				<div className="td td--right">
					<p className="td-hidden-name">Status</p>
					<div className="table-buttons">
						<button
							type="button"
							disabled={!canEdit}
							onClick={() => {
								if (!canEdit) return;
								setOpenReject(true);
							}}
						>
							<span className="edit-icon icon-cancel-icon" />
						</button>
						<button
							type="button"
							disabled={!canEdit}
							onClick={() => {
								if (!canEdit) return;
								setOpenAccept(true);
							}}
						>
							<span className="edit-icon icon-succes-icon" />
						</button>
					</div>
				</div>
			</div>
			<AcceptPopup
				openModal={openAccept}
				closeModal={() => setOpenAccept(false)}
				operationId={id}
				rate={actualRate ? +actualRate : 0}
			/>
			<RejectPopup
				openModal={openReject}
				closeModal={() => setOpenReject(false)}
				operationId={id}
			/>
		</>
	);
};

export default TableRow;
