import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getOperatorsIsLoad, getOperators } from 'redux/reducers/cashdesk/selectors';
import Loader from 'ui/Loader';
import OperatorItem from './OperatorItem';
// import { IAdminManagementListProps } from './types';

// ==========================================:
const PermissionManagementList = () => {
	const operatorsLoader = useSelector(getOperatorsIsLoad);
	const operators = useSelector(getOperators);

	return (
		<div className="table-wrapper">
			<div className="table table--cashdesk-permission-management">
				<div className="table-header">
					<div className="tr">
						<div className="td">
							<div className="td-name">
								<p>ID</p>
							</div>
						</div>
						<div className="td">
							<div className="td-name">
								<p>Full name</p>
							</div>
						</div>
						<div className="td">
							<div className="td-name">
								<p>Type</p>
							</div>
						</div>
						<div className="td">
							<div className="td-name">
								<p>Cash desk</p>
							</div>
						</div>
						<div className="td">
							<div className="td-name">
								<p>Email</p>
							</div>
						</div>
						<div className="td">
							<div className="td-name">
								<p>Permissions</p>
							</div>
						</div>
						<div className="td">
							<div className="td-name">
								<p>Registration date</p>
							</div>
						</div>
						<div className="td td--right">
							<div className="td-name">
								<p>Action</p>
							</div>
						</div>
					</div>
				</div>

				{!operators?.data.length && !operatorsLoader && (
					<div className="user-management-empty-data">
						<p className="user-management-empty__text">No users found...</p>
					</div>
				)}
				{operatorsLoader ? (
					<div className="list-loader">
						<Loader />
					</div>
				) : (
					<div className="table-body">
						{operators?.data.map((item) => (
							<OperatorItem key={item.id} data={item} />
						))}
					</div>
				)}
			</div>
		</div>
	);
};

export default PermissionManagementList;
