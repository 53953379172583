import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import Staking from 'components/Staking';

// ================================================:
const StakingPage: FC = () => {
	return (
		<Dashboard title="Staking">
			<Staking />
		</Dashboard>
	);
};

export default StakingPage;
