import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import { DisputeTub } from 'components/Dispute/DisputeTubs/DisputeTubs';

// ================================================:
const DisputeTubPage: FC = () => {
	return (
		<Dashboard title="Details">
			<DisputeTub />
		</Dashboard>
	);
};
export default DisputeTubPage;
