import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import { ExternalMakerBot } from 'components/ExternalMakerBot';

// ================================================:
const ExternalMakerRobotPage: FC = () => {
	return (
		<Dashboard title="External">
			<ExternalMakerBot />
		</Dashboard>
	);
};
export default ExternalMakerRobotPage;
