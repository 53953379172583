import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { ICashdeskApi } from './types';
/* eslint-disable */
// ==========================================:
export const cashdesk: ICashdeskApi = {
	getCashdesks: () => http.get(endpoint.cashdesk.LIST).then((response) => response.data),
	exportCashdesks: () => http.post(endpoint.cashdesk.EXPORT).then((response) => response.data),
	getActiveCurrency: () =>
		http.get(endpoint.cashdesk.ACTIVE_CURRENCY).then((response) => response.data),
	addCashdesk: (payload) =>
		http.post(endpoint.cashdesk.ADD, payload).then((response) => response.data),
	addCashdeskCurrency: (payload) =>
		http.post(endpoint.cashdesk.ADD_CURRENCY, payload).then((response) => response.data),
	deleteCashdesk: (payload) =>
		http.post(endpoint.cashdesk.DELETE, payload).then((response) => response.data),
	deleteCashdeskCurrency: (payload) =>
		http.post(endpoint.cashdesk.DELETE_CURRENCY, payload).then((response) => response.data),
	getOperators: (params) =>
		http.get(endpoint.cashdesk.GET_OPERATORS, { params }).then((response) => response.data),
	getOperatorById: (id) =>
		http.get(endpoint.cashdesk.GET_OPERATOR_BY_ID(id)).then((response) => response.data),
	getAvailableCashdesks: () =>
		http.get(endpoint.cashdesk.GET_AVAILABLE_CASHDESKS).then((response) => response.data),
	getOperatorsPermissions: () =>
		http.get(endpoint.cashdesk.GET_OPERATORS_PERMISSIONS).then((response) => response.data),
	addOperator: (payload) =>
		http.post(endpoint.cashdesk.ADD_OPERATOR, payload).then((response) => response.data),
	updateOperator: (payload) =>
		http.put(endpoint.cashdesk.UPDATE_OPERATOR, payload).then((response) => response.data),
	deleteOperator: (id) =>
		http.delete(endpoint.cashdesk.DELETE_OPERATOR(id)).then((response) => response.data),
	getNotifications: () =>
		http.get(endpoint.cashdesk.GET_NOTIFICATIONS).then((response) => response.data),
	updateNotification: (params) =>
		http.put(endpoint.cashdesk.GET_NOTIFICATIONS, params).then((response) => response.data),
	getSpreads: () => http.get(endpoint.cashdesk.GET_SPREADS).then((response) => response.data),
	updateSpread: (params) =>
		http.put(endpoint.cashdesk.GET_SPREADS, params).then((response) => response.data),
	getCurrencies: () => http.get(endpoint.cashdesk.GET_CURRENCIES).then((response) => response.data),
	updateCurrency: (params) =>
		http.put(endpoint.cashdesk.GET_CURRENCIES, params).then((response) => response.data),
	getWallets: (params) =>
		http.get(endpoint.cashdesk.GET_WALLETS, { params }).then((response) => response.data),
	getAvailableOperators: (params) =>
		http
			.get(endpoint.cashdesk.GET_AVAILABLE_OPERATORS, { params })
			.then((response) => response.data),
	addWallet: (params) =>
		http.post(endpoint.cashdesk.ADD_WALLET, params).then((response) => response.data),
	updateWallet: (payload) =>
		http.put(endpoint.cashdesk.UPDATE_WALLET, payload).then((response) => response.data),
	deletetWallet: ({ id }) =>
		http.delete(endpoint.cashdesk.DELETE_WALLET(Number(id))).then((response) => response.data),
	getWalletOperationList: () =>
		http.get(endpoint.cashdesk.GET_WALLET_OPERATION_LIST).then((response) => response.data),
	getWalletsHistory: (params) =>
		http.get(endpoint.cashdesk.GET_WALLET_HISTORY, { params }).then((response) => response.data),
	getAvailableCurrencies: (params) =>
		http
			.get(endpoint.cashdesk.GET_AVAILABLE_CURRENCIES, { params })
			.then((response) => response.data),
	getAvailableOperationCurrencies: (params) =>
		http
			.get(endpoint.cashdesk.GET_OPERATION_AVAILABLE_CURRENCIES, { params })
			.then((response) => response.data),
	getOperationSpread: (params) =>
		http.get(endpoint.cashdesk.GET_OPERATION_SPREAD, { params }).then((response) => response.data),
	getOperationUser: (params) =>
		http.get(endpoint.cashdesk.GET_OPERATION_USER, { params }).then((response) => response.data),
	getOperationRate: (params) =>
		http.get(endpoint.cashdesk.GET_OPERATION_RATE, { params }).then((response) => response.data),
	createOperation: (params) =>
		http.post(endpoint.cashdesk.CREATE_OPERATION, params).then((response) => response.data),
	checkWithdrawalAddress: (params) =>
		http
			.get(endpoint.cashdesk.CHECK_WITHDRAWAL_ADDRESS, { params })
			.then((response) => response.data),
	getOperations: (params) =>
		http.get(endpoint.cashdesk.GET_OPERATIONS, { params }).then((response) => response.data),
	getRequestedRates: (params) =>
		http.get(endpoint.cashdesk.GET_REQUESTED_RATES, { params }).then((response) => response.data),
	acceptRequestedRate: (payload) =>
		http.put(endpoint.cashdesk.ACCEPT_REQUESTED_RATE, payload).then((response) => response.data),
	rejectRequestedRate: (payload) =>
		http.put(endpoint.cashdesk.REJECT_REQUESTED_RATE, payload).then((response) => response.data),
	continueOperation: (params) =>
		http.put(endpoint.cashdesk.CONTINUE_OPERATIONS, params).then((response) => response.data),
};
