/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { useSelector } from 'react-redux';
import {
	getWithdrawalsTransactionsIsLoad,
	getWithdrawalsTransactionsList,
} from 'redux/reducers/transactions/selectors';
import Loader from 'ui/Loader';
import { Item } from './Item';

export const Crypto: FC = () => {
	const withdrawals = useSelector(getWithdrawalsTransactionsList);
	const withdrawalsLoader = useSelector(getWithdrawalsTransactionsIsLoad);

	return (
		<div className="content-block content-block--transaction">
			{(withdrawals?.data?.length ?? 0) < 1 && !withdrawalsLoader && (
				<div className="user-management-empty-data">
					<p className="user-management-empty__text">Withdrawals not found...</p>
				</div>
			)}
			{withdrawalsLoader && (
				<div className="list-loader">
					<Loader />
				</div>
			)}
			{(withdrawals?.data?.length ?? 0) > 0 && !withdrawalsLoader && (
				<div className="table-wrapper">
					<div className="table table--transaction table--with-scroll">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<p>ID</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>User ID</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p className="status-arrow status-arrow--flex-start">
											<span className="status-arrow__text">Time</span>
											<button type="button" className="status-arrow__button" />
										</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p className="status-arrow status-arrow--flex-start">
											<span className="status-arrow__text">Asset</span>
											<button type="button" className="status-arrow__button" />
										</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Network</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Network fee</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Amount</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Amount USD</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>MEMO code</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Destination</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p className="status-arrow">
											<span className="status-arrow__text">Status</span>
											<button type="button" className="status-arrow__button" />
										</p>
									</div>
								</div>
								<div className="td td--right">
									<div className="td-name">
										<p className="status-arrow">
											TxID
											<button type="button" className="status-arrow__button" />
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="table-body">
							{withdrawals?.data.map((item) => (
								<Item data={item} key={item.id} />
							))}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
