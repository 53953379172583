import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import { SelectUser } from 'components/UserManagement/SelectUser';

// ================================================:
const SelectUserPage: FC = () => {
	return (
		<Dashboard title="User management">
			<SelectUser />
		</Dashboard>
	);
};

export default SelectUserPage;
