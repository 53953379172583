/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'ui/Loader';

import { getWallets, getWalletsLoader } from 'redux/reducers/cashdesk/selectors';
import { IWalletManagementItem } from 'redux/reducers/cashdesk/types';
import { setFilterParams } from 'redux/reducers/orders/reducer';
import { WalletTableHeader } from './WalletTableHeader';
import WalletTableItem from './WalletTableItem';

export const Wallet: FC = () => {
	const walletList = useSelector(getWallets);
	const walletIsLoad = useSelector(getWalletsLoader);

	const [openWhitelisting, setOpenWhitelisting] = useState(false);

	return (
		<>
			{walletIsLoad ? (
				<>
					{' '}
					<div className="list-loader">
						<Loader />
					</div>
				</>
			) : (
				<div className="table-wrapper">
					<div className="table table--cashdesk-wallet-management">
						<WalletTableHeader />
						<div className="table-body">
							{walletList?.data.length
								? walletList?.data.map((item: IWalletManagementItem) => (
										<WalletTableItem item={item} key={item.id} />
								  ))
								: ''}
							{walletList?.data.length === 0 ? (
								<p className="user-management-empty-data" style={{ padding: '20px' }}>
									No wallets found
								</p>
							) : (
								''
							)}
						</div>
					</div>
				</div>
			)}
			{openWhitelisting && (
				<div className="popup-window" onClick={() => setOpenWhitelisting(false)} />
			)}
		</>
	);
};
