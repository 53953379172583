/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect, ChangeEvent } from 'react';
import Popup from 'reactjs-popup';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveCurrency } from 'redux/reducers/cashdesk/selectors';
import {
	createCashDeskRequest,
	addCurrencyCashDesktRequest,
} from 'redux/reducers/cashdesk/reducer';
import {
	IActiveCurrency,
	IAddCashDeskData,
	IAddCurrencyCashDeskData,
} from 'redux/reducers/cashdesk/types';
import { notificationContainer } from 'utils/notificationContainer';

import CashDeskDropdown from 'ui/CashDeskDropdown';
import { IRemoveWhitelistingIPModalProps } from './types';

// ================================================:
export const CreateCashdesk: FC<IRemoveWhitelistingIPModalProps> = ({
	openModal,
	closeModal,
	// onSuccessed,
	type,
	cashDeskId,
	currentCurrency,
}) => {
	const activeCurrency = useSelector(getActiveCurrency);
	const dispatch = useDispatch();
	const [name, setName] = useState('');
	const [listCurrency, setListCurrency] = useState<Array<number>>([]);
	const filterCurrency = activeCurrency?.filter(
		(item) => !currentCurrency?.some((t) => t.asset_id === item.id),
	);

	useEffect(() => {
		return () => {
			setName('');
		};
	}, [openModal]);

	const handleWalletSelect = (value: IActiveCurrency): void => {
		setListCurrency([...listCurrency, value.id]);
	};

	const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;
		setName(value);
	};

	/* eslint-disable */
	const handleCreate = () => {
		if (type === 'edit') {
			const payload: IAddCurrencyCashDeskData = {
				params: { cashdesk_id: Number(cashDeskId), asset_ids: listCurrency },
				onSuccess: () => {
					notificationContainer('Cashdesk updated', 'success');
				},
			};
			dispatch(addCurrencyCashDesktRequest(payload));
			closeModal();
			return;
		}
		const payload: IAddCashDeskData = {
			params: { name: String(name), asset_ids: listCurrency },
			onSuccess: () => {
				notificationContainer('Cashdesk created', 'success');
			},
		};
		dispatch(createCashDeskRequest(payload));
		closeModal();
	};

	const handleDelete = (id: number) => {
		const newCurrencies = listCurrency.filter((itemId) => itemId !== id);
		setListCurrency(newCurrencies);
	};

	const disable = type === 'edit' ? false : !name || listCurrency.length === 0;

	return (
		<Popup open={openModal} closeOnDocumentClick onClose={closeModal} closeOnEscape>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup ">
						<div className="popup-header">
							<p className="popup-header__title">
								{type === 'edit' ? 'Add currency' : 'Create Cash Desk'}
							</p>
						</div>
						<div className="popup-body popup-body--type2">
							{type === 'add' ? (
								<div className="input ">
									<p className="input__name">Name</p>
									<div className="input-wrapper">
										<input
											className="input-item"
											value={name}
											onChange={handleChangeName}
											type="text"
											placeholder="Enter name"
										/>
									</div>
								</div>
							) : (
								''
							)}
							<div className="input ">
								<p className="input__name">Currency</p>
								<CashDeskDropdown
									options={(type === 'edit' ? filterCurrency : activeCurrency) || []}
									value={listCurrency}
									onChange={handleWalletSelect}
									handleDelete={handleDelete}
								/>
							</div>
						</div>
						<div className="popup-submit popup-submit--type2">
							<button
								onClick={closeModal}
								type="button"
								className="button button--cancel button--full-width "
							>
								Cancel
							</button>
							<button
								type="button"
								onClick={() => handleCreate()}
								className="button button--green-type  button--full-width "
								disabled={disable}
							>
								{type === 'edit' ? 'Add currency' : 'Create'}
							</button>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};
