// Wallet management

export const TYPE_WALLET = 'wallet';
export const TYPE_HISTORY = 'history';

// ----------------

export const HISTORY_SEARCH_ARRAY = [
	'admin_id',
	'operation_type',
	'cashdesk_id',
	'operator_id',
	'currency',
];
export const HISTORY_SEARCH_TEXT_ARRAY = [
	'Admin ID',
	'Type',
	'Cash desk',
	'Operator ID',
	'Currency',
];

export const WALLET_SEARCH_ARRAY = ['cashdesk_id', 'operator_id', 'currency'];
export const WALLET_SEARCH_TEXT_ARRAY = ['Cash Desk', 'Operator ID', 'Currency'];
