import { FC } from 'react';
import { Link } from 'react-router-dom';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { IItemProps } from './types';

export const TableRow: FC<IItemProps> = ({ data }) => {
	const date = new Date(data.created_at * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+data.created_at + offset) * 1000);

	const textColorClass = (value: string) => {
		switch (value) {
			case 'enabled':
				return 'status--success';
			default:
				return 'status--disabled';
		}
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Date</p>
				{dateOffset.toLocaleDateString('en-GB')} <span className="custom-dot"> • </span>
				{dateOffset.toLocaleTimeString()}
			</div>
			<Link className="td" to={`/user-management/${String(data.id)}`}>
				<p className="td-hidden-name">User ID</p>
				<p>{data.admin_id}</p>
			</Link>
			<div className="td td--right">
				<p className="td-hidden-name">Status</p>
				<div>
					<span className={`status ${textColorClass(data.status)}`}>
						{capitalizeFirstLetter(data.status)}
					</span>
				</div>
			</div>
		</div>
	);
};
